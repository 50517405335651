import React, { useContext } from "react";
import SVG from 'react-inlinesvg';
import { addDate, compareDates, estDate, format_time_mm_ss, getAvatarUrl } from "../../Utils/utils";
import Moment from "react-moment";
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { ChatStatus, EDIT_REVIEW_DAYS_OUT, REVIEW_CHAT_DURAION, ReviewType, UserRole } from "../../Constants/constants";
import Advisor from '../../Components/Advisor/Advisor'
import { useNavigate } from "react-router-dom";
import ModalContext from "../../Context/ModalContext";
import { getPreviousUrl, popHistory } from "../../Store/Reducer/urlHistoryReducer";

const ChatMetaInfoSm = React.memo((props) => {
    const navigate = useNavigate();
    const dispath = useDispatch()
    const authUser = useSelector(memoizedGetAuthUser);
    const {chat} = props;
    const partner = (authUser._id === chat.cId._id) ? chat.aId : chat.cId;
    const modalContext = useContext(ModalContext);
    const previousUrl = useSelector(getPreviousUrl)

    const goBack = (e) => {
        if(previousUrl) {
            const url = previousUrl;
            dispath(popHistory())
            navigate(url)
        }
    }
    const onEditNoteBtnClicked = (chat) => {
        modalContext.setModalData({
            client: chat.cId,
            chat: chat
        });
        modalContext.setOpenAdvisorNoteModal(true);
    }
    const onEditReview = (chat) => {
        modalContext.setModalData({chat});
        modalContext.setOpenEditReviewModal(true);
    }

    return <div className="chat-metainfo-sm d-flex d-md-none py-1 px-2">
        <div className="mx-2 d-flex justify-content-center align-items-center cursor-pointer" 
            onClick={goBack}><MDBIcon fas icon="chevron-left" size="lg"/>
        </div>
        <div className="partner-avatar d-flex align-items-center">
            <img className="circle-avatar-50" src={getAvatarUrl(partner.avatar)} />
            <div className="mx-2 d-flex flex-column justify-content-center">
                <div className="partner-username">{partner.username}</div>
                {chat.status >= ChatStatus.ENDED && <div className="ad-earning px-2">$ {authUser.role === UserRole.CLIENT ? chat.amount : chat.ad_earning}</div>}
                {chat.status === ChatStatus.MISSED && <div className="comment">Missed Chat</div>}
            </div>
        </div>

        { (() => {
            if(authUser?.role === UserRole.CLIENT && chat.duration > REVIEW_CHAT_DURAION) {
                const expireDate = addDate(new Date(chat.startedAt), EDIT_REVIEW_DAYS_OUT);
                if(compareDates(expireDate, new Date()) === 1) {
                    return <div className="ms-2 d-flex align-items-center">
                        <SVG src='/img/icons/edit.svg'
                            className="cursor-pointer"
                            style={{width:25, height: 25}}
                            title="View Chat Content"
                            onClick={(e) => onEditReview(chat)}
                            fill="#cd730c"
                        />
                    </div>
                }
            } 

            if(chat.reviewId) {
                return <>
                    {chat.reviewId && <div className="d-flex align-items-center">
                    <img src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`} 
                        style={{width:25, height: 25}}
                        className="cursor-pointer"
                        onClick={e=>onEditReview(chat)}
                    />
                    </div>}
                </>
            }
        })()}        

        {authUser?.role === UserRole.ADVISOR && (
            <div className="d-flex align-items-center ms-2">
                <MDBBtn tag='a' color="none" onClick={(e)=>onEditNoteBtnClicked(chat)} title="Edit Note about the chat">
                    <SVG src='/img/icons/edit.svg'
                        className="cursor-pointer"
                        style={{width:25, height: 25}}
                        title="View Chat Content"
                        fill="#cd730c"
                    />
                </MDBBtn>
            </div>
        )}
        <div className="flex-fluid d-flex align-items-center justify-content-center">
        </div>
        <div className="d-flex flex-column justify-content-end align-items-center px-2">
            <Moment format='YYYY-MM-DD' className="chat-date">{chat.startedAt}</Moment>
            <Moment format='HH:mm' className="chat-date">{chat.startedAt}</Moment>
        </div>
    </div>
})

export default ChatMetaInfoSm;