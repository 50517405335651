import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRadio, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import TextAreaAutoHeight from "../TextAreaAutoHeight/TextAreaAutoHeight";
import ApiService from "../../Core/Service/ApiService";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { ReviewType, UserRole } from "../../Constants/constants";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { addDate, compareDates, getAvatarUrl, validateForm } from "../../Utils/utils";
import EventContext from "../../Context/EventContext";

const EditReviewModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [savingReview, setSavingReview] = useState(false);
    const contentInputRef = useRef(null);
    const open = modalContext.openEditReviewModal;
    const setOpen = modalContext.setOpenEditReviewModal;
    const modalData = modalContext.modalData;
    const [formData, setFormData] = useState({
        reviewContent: '',
        reviewType: ReviewType.HAPPY,
    });
    const formDataRules = {
        reviewContent: {
            required: 'string',
            label: 'Feedback'
        }
    };
    const [inputErrors, setInputErrors] = useState({});
    const [editable, setEditable] = useState(false);
    const eventContext = useContext(EventContext);

    useEffect(()=>{
        if(open) {
            contentInputRef.current?.focus();
            setInputErrors({});
        }
    }, [open]);

    useEffect(()=>{
        if(authUser && modalData && open) {
            if(authUser.role === UserRole.CLIENT) {
                const expireAt = addDate(new Date(modalData.chat.startedAt), 30);
                setEditable(compareDates(expireAt, new Date()) === 1);
            }

            if(modalData.chat.reviewId) {
                setFormData({
                    reviewContent: modalData.chat.reviewId.content,
                    reviewType: modalData.chat.reviewId.type
                });
            } else {
                setFormData({
                    reviewContent: '',
                    reviewType: ReviewType.HAPPY
                })
            }

        }
    }, [modalData, authUser, open]);

    const onReviewTypeChanged = (e) => {
        setFormData({
            ...formData,
            reviewType: e.target.value
        });
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const onSaveBtnClicked = (e) => {
        e.preventDefault();

        formData.reviewContent = formData.reviewContent.trim();
        const valid_ret = validateForm(formData, formDataRules);
        if(valid_ret.result == true) {
            setInputErrors({});
            setSavingReview(true);

            const data = {...formData};
            let url = '';
            if(modalData.chat.reviewId) {
                url = '/client/review/update';
                data._id = modalData.chat.reviewId;
            } else {
                url = '/client/review/create';
                data.chatId = modalData.chat._id;
            };
            ApiService.post(url, data).then((result)=>{
                eventContext.setEvtReviewChanged({
                    action: url === '/client/review/update' ? 'update' : 'create',
                    time: new Date(),
                    review: result.data.review
                })
            })
            .finally(()=>{
                setSavingReview(false);
                setOpen(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
            return;
        }
    }

    const closeModal = (e) => {
        e.preventDefault();
        setOpen(false);
    }

    if(open) {
        const partner = (authUser.role === UserRole.CLIENT) ? modalData.chat.aId : modalData.chat.cId;

        return <MDBModal open={open} setOpen={setOpen} tabIndex='-1' className="edit-review-modal" closeOnEsc={false} staticBackdrop >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <MDBModalTitle>Review</MDBModalTitle>
                        <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody className="d-flex p-4 flex-column justify-content-center align-items-center">
                        <div className="mb-2 w-100 d-flex align-items-center">
                            <label>{authUser?.role === UserRole.CLIENT ? 'Advisor' : 'Client'}:</label>
                            <div className="ms-2">
                                <img className="circle-avatar-40" src={getAvatarUrl(partner.avatar)} />
                                <span className="ms-2">{partner.username}</span>
                            </div>
                        </div>

                        <div className="w-100 mt-2">
                            <label>Feedback: </label>
                            <div>
                                <MDBTextArea 
                                    name="reviewContent" 
                                    className='w-100 p-2'
                                    rows={5}
                                    tabIndex={1}
                                    value={formData.reviewContent}
                                    onChange={handleInputChange}
                                    disabled={!editable}
                                    placeholder="Leave a review here"
                                />
                                {inputErrors.reviewContent && <div className='error'>{inputErrors.reviewContent}</div>}
                            </div>
                        </div>

                        <div className="d-flex mt-2 justify-content-begin align-items-center w-100">
                            <div className="me-4 d-flex align-items-center">
                                <img 
                                    src='/img/icons/png/sad.png' 
                                    style={{opacity : formData.reviewType==ReviewType.UNHAPPY? 1: 0.3, width:35, height: 35}} 
                                    className="me-2"
                                />
                                <MDBRadio 
                                    name='reviewTypeRadio' 
                                    id='unhappy_review' 
                                    value={ReviewType.UNHAPPY}
                                    onChange={onReviewTypeChanged}
                                    checked={formData.reviewType === ReviewType.UNHAPPY}
                                    disabled={authUser?.role != UserRole.CLIENT}
                                />
                            </div>
                            <div className="ms-4">
                                <div className="d-flex align-items-center">
                                    <img 
                                        className="me-2"
                                        src='/img/icons/png/smile.png' 
                                        style={{opacity : formData.reviewType==ReviewType.HAPPY? 1: 0.3, width:35, height: 35}} 
                                    />
                                    <MDBRadio 
                                        value={ReviewType.HAPPY}
                                        name='reviewTypeRadio' 
                                        checked={formData.reviewType === ReviewType.HAPPY}
                                        onChange={onReviewTypeChanged}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="action d-flex justify-content-end mt-4 w-100">
                            <MDBBtn color="second" onClick={closeModal}>Cancel</MDBBtn>
                            {editable &&
                                <MDBBtn 
                                    onClick={onSaveBtnClicked} 
                                    disabled={savingReview} 
                                    className="confideas-primary-btn ms-2"
                                    style={{textTransform:'none'}}
                                >
                                    {savingReview && <>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                        <span>Saving</span>
                                    </>}
                                    {!savingReview && <span>Save</span>}
                                </MDBBtn>
                            }
                        </div>

                        {loading && <LoadingIndicator />}
                    </MDBModalBody>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    } else {
        return <></>
    }
});

export default EditReviewModal;