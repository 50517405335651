import React, { useState, useEffect } from "react";
import { MDBContainer, MDBIcon, MDBSpinner, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from "mdb-react-ui-kit";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { floatV, v_url } from "../../../Utils/utils";
import './CheckOut.scss';
import { getAuthUser, updateAuth } from "../../../Store/Reducer/authReducer";
import JwtService from "../../../Core/Service/JwtService";
import { useDispatch, useSelector } from "react-redux";
import { getChatSocket } from "../../../Store/Reducer/socketReducer";
import { PaymentKind, TransactionStatus, SendMessages } from "../../../Constants/constants";
import PaypalCheckOut from "../../../Components/Paypal/PaypalCheckOut";
import CreditCardCheckOut from "../../../Components/CreditCard/CreditCardCheckOut";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../../Core/Service/ApiService";

const CheckOut = React.memo((props) => {
    const params = useParams();
    const navigate = useNavigate();
    const {amount} = params;
    const dispatch = useDispatch();
    const chatSocket = useSelector(getChatSocket);
    const authUser = useSelector(getAuthUser);
    const [activePayment, setActivePayment] = useState(PaymentKind.PAYPAL);
    const [paymentTokens, setPaymentTokens] = useState();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        type: 'info',
        icon: 'spinner',
        content: 'Initializing payment method...'
    });
    const [isPaying, setIsPaying] = useState(false);

    useEffect(()=>{
        setLoading(true);
        ApiService.get('/client/paymentTokens').then((response) => {
            setPaymentTokens(response.data);
        })
        .finally(()=>{
            setLoading(false);
        });
    }, []);

    const onPaymentSuccess = (balance) => {
        toast.success("Payment was processed successfully.");

        // To update authUser's balance
        dispatch(updateAuth({
            balance: floatV(parseFloat(balance))
        }));

        const paymentRequest = JwtService.getPaymentRequest();
        JwtService.destroyPaymentRequest();

        switch(paymentRequest?.purpose) {
            case 'request_chat':
                chatSocket.emit(SendMessages.REQUEST_CHAT, {
                    aId: paymentRequest.advisor._id,
                    paymentToken: paymentRequest.paymentToken
                });
                navigate(v_url('/advisor/' + paymentRequest.advisor._id));
                break;
            case 'charge_wallet':
                navigate(v_url('/client/mywallet'));
                break;
            case 'continue_chat':
                chatSocket.emit(SendMessages.RESUME_CHAT, {chatId: paymentRequest.chatId})
                break;
        }
    }

    const onPaymentPending = (orderId) => {
        setTimeout(() => {
            ApiService.get(`/client/paypal/check_payment/${orderId}`).then(response => {
                switch(response.data.payment_status) {
                    case TransactionStatus.COMPLETED:
                        setIsPaying(false);
                        onPaymentSuccess(floatV(response.data.balance));
                        break;
                    case TransactionStatus.PAYMENT_DECLINED:
                        setIsPaying(false);
                        setMessage({
                            type: 'error',
                            icon: 'exclamation-triangle',
                            content: response.data.message
                        });
                        break;
                    default:
                        onPaymentPending(orderId);
                }
            })
        }, 5000);
    }

    const onPaymentReturn = (data) => {
        const capture_status = data.capture_status.toLowerCase();
        switch(capture_status) {
            case TransactionStatus.COMPLETED:
                if(data.payment_status == TransactionStatus.COMPLETED) {
                    setMessage({
                        type: 'success',
                        icon: 'check-circle',
                        content: 'Payment was made successfully.'
                    })
                    setIsPaying(false);

                    // To use setTimeout for UI.
                    setTimeout(() => {
                        onPaymentSuccess(floatV(data.balance));
                    }, 500);
                } else if(data.payment_status == TransactionStatus.PAYMENT_DECLINED) {
                    setIsPaying(false);
                    setMessage({
                        type: 'error',
                        icon: 'exclamation-triangle',
                        content: 'Payment was declined. Please check your payment information.'
                    })
                }
                break;
            case TransactionStatus.PENDING:
                setMessage({
                    type: 'info',
                    icon: 'spinner',
                    content: 'Checking payment...'
                });
                onPaymentPending(data.orderId);
                break;
            default:
                setMessage({
                    type: 'error',
                    icon: 'exclamation-triangle',
                    content: 'Something went wrong. Please try again.'
                });
                break;
        }
    }

    const renderMessage = () => {
        if(!message.content) {
            return <></>
        } else {
            return (
                <div className={`mt-3 d-flex justify-content-center align-items-center message ${message.type}`}>
                    { message.icon == 'spinner' && (
                        <MDBSpinner size="sm" />
                    ) }
                    { message.icon && message.icon != 'spinner' && (
                        <MDBIcon fas icon={message.icon} />
                    )}
                    <span className="ms-2">{ message.content }</span>
                </div>
            )
        }
    }

    return (
        <MDBContainer breakpoint="lg" className="client-checkout-container d-flex flex-column justify-content-center align-items-center p-4">
            <div className="w-100 mt-4" style={{maxWidth: 500}}>
                <div className="you-will-be-chared d-flex flex-column align-items-center justify-content-center p-2">
                    <div>Additional Funds Required</div>
                    <div className="checkout-amount text-center mt-2"><strong>$ {floatV(amount)}</strong></div>
                </div>
                <div className="mt-4">
                    <MDBTabs>
                        <MDBTabsItem>
                            <MDBTabsLink
                                onClick={e => setActivePayment(PaymentKind.PAYPAL)}
                                active={activePayment===PaymentKind.PAYPAL}
                            >
                                <div className="d-flex align-items-center">
                                    <MDBIcon fab icon="paypal" size="2x" color="primary" />
                                    <span className="ms-2">PayPal</span>
                                </div>
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink
                                onClick={e => setActivePayment(PaymentKind.CREDIT_CARD)}
                                active={activePayment===PaymentKind.CREDIT_CARD}
                            >
                                <div className="d-flex align-items-center">
                                    <MDBIcon far icon="credit-card" color="success" size="2x"/>
                                    <span className="ms-2">Credit Card</span>
                                </div>
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    <PayPalScriptProvider options={{
                        'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        'components': ['card-fields', 'buttons'],
                        'currency': 'USD',
                        'data-usercentrics': `PayPal`,
                    }}>
                        <MDBTabsContent>
                            <MDBTabsPane open={activePayment === PaymentKind.PAYPAL} className="pt-4">
                                <PaypalCheckOut 
                                    setMessage={setMessage}
                                    amount={amount} 
                                    onPaymentReturn={onPaymentReturn}
                                    setIsPaying={setIsPaying}
                                    isPaying={isPaying}
                                />
                            </MDBTabsPane>
                            <MDBTabsPane open={activePayment === PaymentKind.CREDIT_CARD} className="pt-4">
                                <CreditCardCheckOut 
                                    setMessage={setMessage}
                                    amount={amount} 
                                    onPaymentReturn={onPaymentReturn} 
                                    paymentTokens={paymentTokens}
                                    setIsPaying={setIsPaying}
                                    isPaying={isPaying}
                                /> 
                            </MDBTabsPane>
                        </MDBTabsContent>
                    </PayPalScriptProvider>
                </div>

                { renderMessage() }
            </div>

            {loading && <LoadingIndicator />}
        </MDBContainer>
    )
})

export default CheckOut;