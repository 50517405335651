import { MDBBtn, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";

const PasswordInput = ({label, name, tabIndex, value, myRef, handleInputChange, error}) => {
    const [showPwd, setShowPwd] = useState(false);
    return (
        <div style={{position: "relative"}}>
            <MDBInput type={`${showPwd ? "text": "password"}`} 
                        label={label}
                        name={name}
                        ref={myRef}
                        tabIndex={tabIndex}
                        value={value}
                        autoComplete="off"
                        onChange={handleInputChange}
            />
            <MDBBtn
                type="button"
                style={{
                    position: "absolute",
                    top: 6,
                    right: 2,
                    background: "none",
                    boxShadow: "none",
                    padding: "0px"
                }}
                onClick={(e) => {
                    e.preventDefault();
                    setShowPwd(!showPwd);
                }}
            >
                {/* {showPwd ? <img src="/img/icons/png/purple_eye.png" alt="" width={28} height={28}/> : <img src="/img/icons/png/purple_eye.png" width={28} height={28} alt=""/>} */}
                <MDBIcon fas icon={showPwd ? 'eye-slash' : 'eye'} color="primary" style={{width:30, height: 15}} />
            </MDBBtn>
            {error && <div className='error'>{error}</div>}
        </div>
    )
}

export default PasswordInput;