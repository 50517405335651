import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import React, {useEffect} from "react";
import SVG from 'react-inlinesvg';
import MainInfo from "./MainInfo";
import AboutMe from "./AboutMe";
import Reviews from "./Reviews";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { useSelector } from "react-redux";
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../../Store/Reducer/changedAdvisorReducer";
import { getAvatarUrl, getBackendAssetUrl, v_url } from "../../../Utils/utils";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import FloatBar from "../../../Components/FloatBar/FloatBar";
import CommPanel from "../../../Components/CommPanel/CommPanel";
import {Helmet} from 'react-helmet';

const AdvisorShow = React.memo((props) => {
    const params = useParams();
    const aId = params._id;
    const uName = params.uName;
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);

    const navigate = useNavigate();
   
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        setLoading(true);

        let url = "";
        if(aId) url = '/client/advisor/' + aId;
        else if(uName) url = '/client/advisor/uname/' + uName.replaceAll('-', ' ');
        ApiService.get(url).then(result=>{
            if(result.data.advisor) {
                setAdvisor(result.data.advisor);
            } else {
                navigate(v_url('/all_advisors'));
            }
        })
        .finally(()=>{
            setLoading(false);
        });
    }, []);

    const goBack = () => {
        navigate(v_url('/all_advisors'));
    }

    const renderVideo = () => {
        return (
        <MDBCard className="w-100 h-100 mt-4">
            <MDBCardBody className="d-flex flex-column justify-content-center align-items-center">
                <div className="text-left w-100 d-flex align-items-center mb-2">
                    <SVG src='/img/icons/movie_videos.svg' style={{width:25, height: 25}} fill="#54b4d3" />
                    <h5 className="m-0 ms-2">Introduction Video:</h5>
                </div>
                <video 
                    controls 
                    className="w-90" 
                    src={getBackendAssetUrl(advisor.video)} 
                    style={{maxWidth:300, maxHeight:300}} 
                />
            </MDBCardBody>
        </MDBCard>
        )
    }

    const renderFloatBar = () => {
        return (
        <FloatBar triggerY={145} mobile_float_y={60} pc_float_y={80}>
            <MDBBtn 
                color='none' tag='a'
                className='p-1'
                onClick={goBack}
            >
                <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
            </MDBBtn>
            
            <img className="ms-1 circle-avatar-30" src={getAvatarUrl(advisor?.avatar)} />
            <span className="ms-2">{ advisor?.username }</span>

            <div className="ms-4">
                <section className="d-none d-md-block">
                    <CommPanel advisor={advisor} />
                </section>
                <section className="d-block d-md-none">
                    <CommPanel advisor={advisor} miniVersion={true} />
                </section>
            </div>
        </FloatBar>
        )
    }

    return (<>
        <Helmet>
            <link rel="canonical" href={`https://confideas.com/v0.28/psychic/${uName}`} />
            <meta name="keywords" content={`${uName}`}
            />
        </Helmet>

        <MDBContainer breakpoint="lg" className="advisor-show-container p-2 p-md-4 position-relative">
            {renderFloatBar()}

            <div className="go-back-bar">
                <MDBBtn 
                    color='none' tag='a'
                    className='p-1'
                    onClick={goBack}
                >
                    <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
                </MDBBtn>
            </div>

            { advisor && (
            <>
                <MainInfo advisor={advisor} />
                <AboutMe advisor={advisor} />
                {advisor?.video && renderVideo()}
                <Reviews advisor={advisor} />
            </>)
            }
            {loading && <LoadingIndicator fullScreen={true} />}
        </MDBContainer>
    </>)
}, (oldProps, newProps)=>{
    return oldProps.aId == newProps.aId;
})

export default AdvisorShow;