import React, {useEffect} from "react";
import Introduction from "./Introdution";
import Statistics from "./Statistics";
import TopAdvisors from "./TopAdvisors";
import AdvisorList from "./Advisors";
import WhyChooseUs from "./WhyChooseUs";
import NatalChart from "./NatalChart";
import ConsultAreas from "./ConsultAreas";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { purgeAuth } from "../../Store/Reducer/authReducer";
import {Helmet} from 'react-helmet';

function HomePage(props) {
    // console.log('calling Hompage...')
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, []);

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://confideas.com/v0.28" />
                <meta name="keyword" content="
                    Psychic chat
                    Chat with a psychic 
                    Found the one 
                    Live psychic reading 
                    Psychic Consulting
                    Real psychic 
                    Twin flame psychic 
                    Pay per minute text software
                    Confideas 
                    Confide ideas 
                    Online Psychic Network 
                    Psychic advice 
                    Energy healing advice
                    Tarot reading advice 
                    Love experts 
                    Love advice 
                    Relationship advice                
                " />
                <meta name="description" content="Consult one of the best advisors today! Gain clarity on your situation." />
            </Helmet>
            <section>
                <Introduction />
                {/*<Statistics /> */}
                {/* <TopAdvisors /> */}
                <AdvisorList />
                <WhyChooseUs />
                {/* <NatalChart /> */}
                {/* <ConsultAreas /> */}
            </section>
        </>
        
    )
}

export default HomePage;