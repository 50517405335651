import { Socket, io } from 'socket.io-client';
import { setNfSocket, setNfSocketConnected, setChatSocket, setChatSocketConnected } from '../../Store/Reducer/socketReducer';
import JwtService from './JwtService';
import { ChatStatus, RecvMessages, SendMessages, TimeInterval, UserRole } from '../../Constants/constants';
import { purgeAuth } from '../../Store/Reducer/authReducer';
import { hookTimer } from '../../Utils/timer';
import { removeChangedAdvisors } from '../../Store/Reducer/changedAdvisorReducer';
import { removeAlarms } from '../../Store/Reducer/alarmReducer';

let nfSocket = null;
let chatSocket = null;
let msg_callbacks = {};

export default class SocketEx {
    socktype = "";
    socket = null;
    isConnected = false;
    dispatch = null;
    authUser = null;

    constructor(dispatch, socktype) {
        this.dispatch = dispatch;
        this.socktype = socktype;
        this.authUser = JwtService.getAuthUser();
    }

    disconnect() {
        if(this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }

    connect() {
        const authToken = JwtService.getAuthToken();
        console.log("trying to connect to websocket...", this.socktype);

        if(this.socket?.connected) {
            console.log('socket has already been connected.');
            if(this.socktype === 'notification') {
                this.dispatch(setNfSocketConnected(true));
            } else if(this.socktype === 'chat') {
                this.dispatch(setChatSocketConnected(true));
            }
            return;
        }

        const theThis = this;

        if(!this.socket) {
            this.socket = io(`${process.env.REACT_APP_BACKEND_URL_SOCKET}`, {
                autoConnect: false,
                transports: ['websocket'],
                auth: {
                    token: authToken,
                    socktype: this.socktype
                }
            });
        }

        this.socket.connect();

        this.socket.on('connect', () => {
            console.log(`websocket has been connected. socktype=${theThis.socktype}`);
            theThis.isConnected = true;

            if(theThis.socktype === 'notification') {
                theThis.dispatch(setNfSocketConnected(true));
            } else if(theThis.socktype === 'chat') {
                theThis.dispatch(setChatSocketConnected(true));
            }
        });

        this.socket.on('connect_error', (error)=>{
            if(error.message === 'Authentication Error') {
                theThis.onAuthError();
            }
        })
        this.socket.on('auth_error', (error)=>{
            theThis.onAuthError();
        })

        this.socket.on(RecvMessages.UNAUTHORIZE, () => {
            console.log('unauthorized socket');
            theThis.dispatch(purgeAuth());
            theThis.dispatch(removeChangedAdvisors());
            theThis.dispatch(removeAlarms());
        })

        this.socket.on('disconnect', () => {
            console.log('socket disconnected. socktype=' + theThis.socktype);
            theThis.isConnected = false;
            if(theThis.socktype === 'notification') {
                theThis.dispatch(setNfSocketConnected(false));
            } else if(theThis.socktype === 'chat') {
                theThis.dispatch(setChatSocketConnected(false));
            }
        });

        // To register message_recv_handler if there are any registered
        Object.keys(msg_callbacks).forEach(message => {
            this.socket.on(message, (data, callback) => {
                console.log(data, '********** receive: ' + message);
                msg_callbacks[message].forEach(cb => {
                    cb.msgHandler(data);
                });
                if(data.needAck) {
                    callback && callback({ 
                        msg_id: data.msg_id,
                        status: 'success' 
                    });
                }
            });
        })
    }

    onAuthError(){
        console.log('Faild to connect socket: auth_failed');
        
        this.dispatch(purgeAuth());
        this.dispatch(removeChangedAdvisors());
        this.dispatch(removeAlarms());

        this.disconnect();

        chatSocket = null;
        this.dispatch(setChatSocket(null));
        this.dispatch(setChatSocketConnected(false));
    }

    on(message, key, msgHandler) {
        const theThis = this;

        if(this.socket) {
            this.socket.off(message);

            if(!msg_callbacks[message]) msg_callbacks[message] = [];

            const index = msg_callbacks[message].findIndex(cb=>cb.key == key);
            if(index >= 0) msg_callbacks[message].splice(index, 1);
    
            msg_callbacks[message].push({
                key,
                msgHandler
            });

            this.socket.on(message, (data, callback) => {
                console.log(data, '********** receive: ' + message);

                msg_callbacks[message].forEach(cb => {
                    cb.msgHandler(data);
                });

                if(data.needAck) {
                    callback && callback({ 
                        msg_id: data.msg_id,
                        status: 'success' 
                    });
                }
            });
        }
    }

    off(message, key) {
        if(this.socket && msg_callbacks[message]) {
            const index = msg_callbacks[message].findIndex(cb=>cb.key == key);
            if(index >= 0) {
                msg_callbacks[message].splice(index, 1);
            }
        }
    }

    emit(message, data) {
        if(!this.isConnected) {
            console.log(`Failed to emit message=${message}, sockType=${this.socktype}`, data);
            return;
        }

        console.log('socket emit: ' + message, new Date());

        this.socket.emit(message, data, (response) => {
            if(response.status == 'success') {
                // console.log('message sent ok:', data);
            } else {
                console.log('message sent failed:', message);
            }
        });
    }
}

hookTimer('socket_sendAlive_timer', TimeInterval.CHAT_SOCK_ALIVE_INTERVAL, ()=>{
    if(chatSocket && chatSocket.isConnected) {
        const chat = JwtService.getChat();
        if (chatSocket.authUser?.role == UserRole.CLIENT){
            chatSocket.emit(SendMessages.KEEP_ALIVE);
        } else if(chatSocket.authUser?.role === UserRole.ADVISOR){
            chatSocket.emit(SendMessages.GET_ADVISOR_SRV_STATUS);
        }
    }
});

hookTimer('check_socket_connection', 5000, () => {
    if(nfSocket && !nfSocket.isConnected) {
        nfSocket.connect();
    }
    if(chatSocket && !chatSocket.isConnected) {
        chatSocket.connect();
    }
})

export const createNfSocket = (dispatch) => {
    console.log("trying to create a notification socket.");
    if(nfSocket == null)  {
        nfSocket = new SocketEx(dispatch, "notification");
        dispatch(setNfSocket(nfSocket));
    }
    nfSocket.connect();
}
export const createChatSocket = (dispatch) => {
    console.log("trying to create a chat socket.");
    if(chatSocket == null)  {
        chatSocket = new SocketEx(dispatch, "chat");
        dispatch(setChatSocket(chatSocket));
    }
    chatSocket.connect();
}
export const closeNfSocket = (dispatch) => {
    console.log("trying to close nfSocket...");
    if(nfSocket != null) {
        nfSocket.disconnect();
        nfSocket = null;
        dispatch(setNfSocketConnected(false));
        dispatch(setNfSocket(null));
    }
}
export const closeChatSocket = (dispatch) => {
    console.log("trying to close chatsocket...");
    if(chatSocket != null) {
        chatSocket.disconnect();
        chatSocket = null;
        dispatch(setChatSocketConnected(false))
        dispatch(setChatSocket(null));
    }
}