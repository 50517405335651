import React, { useContext, useEffect, useRef, useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBIcon, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import LoadingIndicator from "../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../Core/Service/ApiService";
import { format_time_mm_ss, getFirstDateOfNextMonth, scrollUpTop } from "../../Utils/utils";
import { ChatStatus, SendMessages, TOPUP_WAIT_TIMEOUT, UserRole } from "../../Constants/constants";
import { IsChatCreated, memoizedGetChat } from "../../Store/Reducer/chatReducer";
import ChatContext from "./ChatContext";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";

const LackBalanceAdvisorModal = React.memo((props) => {
    const chatContext = useContext(ChatContext);
    const endChatSession = chatContext.endChatSession;
    const chat = useSelector(memoizedGetChat);
    const authUser = useSelector(memoizedGetAuthUser);
    const [remainTime, setRemainTime] = useState(TOPUP_WAIT_TIMEOUT);
    const setOpen = chatContext.setOpenLackBalanceModal;
    const timerRef = useRef(null);
    const openTimerRef = useRef(null);

    useEffect(()=>{
        const open = authUser.role === UserRole.ADVISOR && chat.status === ChatStatus.PAUSED;
        if(open) scrollUpTop();

        openTimerRef.current && clearTimeout(openTimerRef.current);
        openTimerRef.current = setTimeout(() => {
            setOpen(open);
            openTimerRef.current = null;
        }, 500);
    }, [chat.status])

    useEffect(()=>{
        if(chatContext.openLackBalanceModal && chat.status === ChatStatus.PAUSED) {
            setRemainTime(Math.max(TOPUP_WAIT_TIMEOUT - parseInt((new Date().getTime() - chat.current_session_pausedAt) / 1000), 0));

            timerRef.current = setInterval(() => {
                const now = new Date().getTime();
                const remainTime = Math.max(TOPUP_WAIT_TIMEOUT - parseInt((now - chat.current_session_pausedAt) / 1000), 0);
                setRemainTime(remainTime);

                if(remainTime == 0) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                }
            }, 1000);

            return () => {
                if(timerRef.current) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                }
            }
        }
    }, [chatContext.openLackBalanceModal]);

    const onEndChatBtnClicked = () => {
        endChatSession();
    }

    return <MDBModal className="lack-balance-advisor-modal" 
                    open={chatContext.openLackBalanceModal} 
                    setOpen={setOpen} 
                    closeOnEsc={false} 
                    staticBackdrop 
                    tabIndex='-1'>
        <MDBModalDialog>
            <MDBModalContent>
                <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center modal-body'>
                    <div className="d-flex justify-content-center align-items-center my-2 ">
                        Client is trying to top up. Please wait...
                    </div>

                    <div className="animation d-flex justify-content-center align-items-center">
                        <div><img src='/img/gifs/hourglass.gif' /></div>
                        <div className="spent-time ms-1">{format_time_mm_ss(remainTime)}</div>                        
                    </div>

                    <div className="action d-flex justify-content-center align-items-center mt-4 w-100">
                        <MDBBtn color="secondary" onClick={onEndChatBtnClicked} className="w-80" >End Chat</MDBBtn>
                    </div>

                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
});

export default LackBalanceAdvisorModal;