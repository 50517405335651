import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import Select from "react-select";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import AdminTrackWordEditModal from "./TrackWordEditModal";
import './TrackWord.scss';

const AdminTrackWordList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [trackWords, setTrackWords] = useState([]);
    const [formData, setFormData] = useState({
        perPage: 10,
        pageNum: 1,
    });
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'pattern', title: 'Pattern'},
        {name: 'comment', title: 'Comment'},
        {name: 'status', title: 'Status'},
        {name: 'action', title: 'Action'}
    ];
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [action, setAction] = useState(null);
    const [openEditModal, setOpenEditModal] = useState(false);

    useEffect(()=>{
        getTrackWords();
    }, [formData]);

    const getTrackWords = () => {
        setLoading(true);

        ApiService.post('/admin/trackword/index', formData).then(result=>{
            setTrackWords(result.data.trackWords);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
    }

    const onAddBtnClicked = () => {
        setAction({
            action: 'add',
            trackWord: null
        })
        setOpenEditModal(true);
    }

    const onEditBtnClicked = (e, trackWord) => {
        setAction({
            action: 'update',
            trackWord
        });
        setOpenEditModal(true);
    }

    const onDelBtnClicked = (e, trakWord) => {
        if(window.confirm("Are you sure to delete this word?") == false) return;
        ApiService.delete(`/admin/trackword/${trakWord._id}`).then(response=>{
            getTrackWords();
        })
        .finally(()=>{

        })
    }

    const onUpserted = () => {
        getTrackWords();
    }

    const breadcrumb=[{title: 'TrackWord'}, {title: 'List'}];
    return <>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="admin-trackword-table">
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item me-2">
                        <Select 
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            value={perPageOpts.find(opt=>opt.value==formData.perPage)} 
                        />
                    </div>
                </SearchBar>
                <div>
                    <MDBBtn className="confideas-primary-btn" onClick={onAddBtnClicked}>Add</MDBBtn>
                </div>
            </div>

            <ScrollHorizon>
                <AdminTable columns={columns} rows={trackWords}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'action':
                            return (<>
                                <MDBBtn tag='a' color="none" onClick={e=>onEditBtnClicked(e, row)}>
                                    <MDBIcon fas icon='edit' color="warning" size="lg" />
                                </MDBBtn>
                                <MDBBtn tag='a' color="none" className="ms-1" onClick={e=>onDelBtnClicked(e, row)}>
                                    <MDBIcon fas icon='trash-alt' color="danger" size="lg" />
                                </MDBBtn>
                            </>)
                        case 'status':
                            return <div className={`status ${row.status ? 'using' : 'unusing'}`}>{row.status ? 'Using' : 'Unusing'}</div>
                    }
                }}
                </AdminTable>
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>

            {loading && <LoadingIndicator />}
        </div>

        <AdminTrackWordEditModal 
            open={openEditModal} 
            setOpen={setOpenEditModal} 
            trackWord={action?.trackWord} 
            onUpserted={onUpserted}
        />
    </>
});

export default AdminTrackWordList;