import { MDBBtn, MDBProgress, MDBProgressBar, MDBSpinner } from "mdb-react-ui-kit";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { memoizedGetAuthUser, setAuth } from "../../../Store/Reducer/authReducer";
import ApiService from "../../../Core/Service/ApiService";
import { v_url } from "../../../Utils/utils";

const AdvisorSignupConfirm = React.memo((props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [signingup, setSigningup] = useState(false);
    const authUser = useSelector(memoizedGetAuthUser);

    const handleSubmit = () => {
        const formData = props.data;
        
        const data = new FormData();
        data.append('email', authUser.email);
        data.append('_id', authUser._id);

        Object.keys(formData.personalData).forEach(key => {
            data.append(key, formData.personalData[key]);
        });

        Object.keys(formData.skillServiceData).forEach(key => {
            if(key === 'langs' || key === 'skills') {
                data.append(key, formData.skillServiceData[key].map(d => d.value));
            } else {
                data.append(key, formData.skillServiceData[key]);
            }
        })

        Object.keys(formData.videoData).forEach(key=>{
            data.append(key, formData.videoData[key]);
        })

        setSigningup(true);

        const headers = {'Content-Type': 'multipart/form-data'};
        ApiService.post('/advisor/auth/signup', data, headers, onUploadProgress).then(result => {
            dispatch(setAuth({
                authUser: result.data.authUser,
                authToken: result.data.authToken
            }));

            navigate(v_url('/advisor_auth/thanks'));
        })
        .finally(() => {
            setSigningup(false);
        });
    }
    const onUploadProgress = (evt) => {
        setUploadProgress(parseInt(evt.loaded * 100 / evt.total))
    }

    return (
        <div className="d-flex justify-content-center w-100 px-2">
            <div className="advisor-signup-confirm d-flex flex-column align-items-center">
                <div className="text-purple fs-200 fw-bold text-center">You are almost there.</div>
                <div><img src='/img/icons/png/green_check.png' style={{width:200, height: 200}}/></div>
                <p className="text-center p-2">Would you like to go ahead and submit your application now?</p>
                <div className="mt-4 d-flex">
                    <MDBBtn color="second" onClick={props.onPrev}>Back</MDBBtn>
                    <MDBBtn 
                        className="confideas-primary-btn ms-3 d-flex align-items-center" 
                        onClick={handleSubmit}
                        disabled={signingup}
                    >
                        {signingup && <MDBSpinner size="sm" />}
                        <span className="ms-2">Submit</span>
                    </MDBBtn>
                </div>

                {signingup && (
                    <div className="w-100" style={{marginTop:50}}>
                        <MDBProgress height='20' style={{borderRadius:5}}>
                            <MDBProgressBar bgColor='success' width={uploadProgress} valuemin={0} valuemax={100}>
                                {uploadProgress}%
                            </MDBProgressBar>
                        </MDBProgress>
                    </div>
                )}
            </div>
        </div>        
    )
});

export default AdvisorSignupConfirm;