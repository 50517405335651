import React from "react";
import styles from './Chat.scss';
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import { MDBIcon, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import { useNavigate, useParams } from "react-router-dom";
import Paginator from "../../../Components/Paginator/Paginator";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Select from "react-select";
import { floatV, format_time_mm_ss, getTimeDiff, v_url } from "../../../Utils/utils";
import { ChatStatus, ChatStatusStr, UserRole } from "../../../Constants/constants";
import Moment from "react-moment";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";

const AdminChatList = React.memo((props) => {
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const chatStatusOpts = [
        {value: '', label: 'All'},
        {value: ChatStatus.CONNECTED, label: 'In Chatting'},
        {value: ChatStatus.ENDED, label: 'In Billing'},
        {value: ChatStatus.BILLED, label: 'Billed'}
    ];
    const [curChatStatusOpt, setCurChatStatusOpt] = useState(chatStatusOpts[0]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [chats, setChats] = useState([]);
    const [total, setTotal] = useState(0);
    const [searchClient, setSearchClient] = useState('');
    const [searchAdvisor, setSearchAdvisor] = useState('');
    const [formData, setFormData] = useState({
        searchClient: '',
        searchAdvisor: '',
        chatstatus: '',
        pageNum: 1,
        perPage: 10
    })
    const navigate = useNavigate();
    const params = useParams();
    const userId = params.userId;
    const [editTarget, setEditTarget] = useState(null);

    useEffect(()=>{
        if(userId) {
            ApiService.get('/admin/user/' + userId).then((result)=>{
                if(result.data.user) {
                    if(result.data.user.role === UserRole.CLIENT) {
                        setSearchClient(result.data.user.username);
                        setFormData({
                            ...formData,
                            searchClient: result.data.user.username
                        })
                    }
                    else if(result.data.user.role === UserRole.ADVISOR) {
                        setSearchAdvisor(result.data.user.username);
                        setFormData({
                            ...formData,
                            searchAdvisor: result.data.user.username
                        })
                    }
                }
            })
            .catch((error)=>{

            })
            .finally(()=>{

            })
        }
    }, [userId])

    useEffect(()=>{
        getChats();
    }, [formData])

    const getChats = () => {
        setLoading(true);
        ApiService.post('/admin/chat/index', formData).then(result=>{
            setChats(result.data.chats);
            setTotal(result.data.total);   
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchClientKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                searchClient
            });
        }
    }
    const onSearchAdvisorKeyDown = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                searchAdvisor
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onChatStatusChanged = (opt) => {
        setFormData({
            ...formData,
            chatstatus: opt.value,
            pageNum: 1
        })
        setCurChatStatusOpt(opt);
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onSearchBtnClicked = (e) => {
        getChats();
    }

    const showDetail = (e, row) => {
        e.preventDefault();
        navigate(v_url(`/chat/${row._id}/detail`));
    }

    const onChgCommissionClicked = (chat, rowIndex, colname) => {
        setEditTarget({
            rowIndex,
            colname,
            chat: {...chat}
        })
    }
    const handleInputChange = (e) => {
        const chat = {
            ...editTarget.chat,
            [e.target.name]: e.target.value
        };
        if(e.target.name === 'commission') {
            chat.ad_earning = floatV(chat.amount * e.target.value / 100)
        }
        setEditTarget({
            ...editTarget,
            chat: chat
        })
    }
    const handleKeyDown = (e) => {
        if(!editTarget) return;

        if(e.key == 'Enter') {
            if(e.target.name === 'commission' && !editTarget.chat.commission){
                e.preventDefault();
                return;
            }

            handleUpdateChat();
        }
    }
    const handleCancelEdit = (e) => {
        setEditTarget(null)
    }
    const handleUpdateChat = () => {
        setSaving(true);

        const data = {
            chat: editTarget.chat
        }
        ApiService.post('/admin/chat/update', data).then(async (result) => {
            await setChats(chats.map((chat, index)=>{
                if(index === editTarget.rowIndex) return editTarget.chat;
                else return chat;
            }));
            await setEditTarget(null);
        })
        .finally(()=>{
            setSaving(false);
        })
    }

    const columns = [
        {name: 'createdAt', title: 'Creation Time'},
        {name: 'cId', title: 'Client'},
        {name: 'aId', title: 'Advisor'},
        {name: 'rate_per_min_chat', title: 'Rate'},
        {name: 'status', title: 'Status'},
        {name: 'duration', title: 'Duration'},
        {name: 'amount', title: 'Amount'},
        {name: 'commission', title: 'Commission'},
        {name: 'ad_earning', title: 'AdEarning'},
        {name: 'bill', title: 'Bill'},
        {name: 'action', title: 'Action'}
    ]

    const breadcrumb=[{title: 'Chat'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="admin-chat-list">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span>{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end align-items-center">
                    <div>
                        <Select className="per-page"
                                    options={perPageOpts} 
                                    onChange={(opt) => onPerPageChanged(opt)} 
                                    defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div className="ms-1" style={{width:150}}>
                        <Select className="chat-status w-100"
                                    options={chatStatusOpts} 
                                    onChange={(opt) => onChatStatusChanged(opt)} 
                                    defaultValue={curChatStatusOpt}
                        />
                    </div>
                    <div className="ms-1">
                        <MDBInput   label='Search client' 
                                    value={searchClient} 
                                    onKeyDown={onSearchClientKeyDown}
                                    onChange={(e)=>setSearchClient(e.target.value)} 
                                    />
                    </div>
                    <div className="ms-1">
                        <MDBInput label='Search advisor' 
                                    value={searchAdvisor} 
                                    onKeyDown={onSearchAdvisorKeyDown}
                                    onChange={(e)=>setSearchAdvisor(e.target.value)} 
                                    />
                    </div>
                    <div className="ms-1 cursor-pointer" onClick={onSearchBtnClicked}>
                        <MDBIcon fas icon='search' />
                    </div>
                </div>
            </div>

            <div className="chats-table mt-2">
                <AdminTable columns={columns} rows={chats}>
                    {(row, colname, rowIndex, colIndex) => {
                        switch(colname) {
                            case 'cId':
                                return <div>{row.cId?.username}</div>;
                            case 'aId':
                                return <div>{ row.aId?.username }</div>;
                            case 'amount':
                                return <div>$ {row.amount}</div>;
                            case 'status':
                                return <div className={`chat-status ${ChatStatusStr(row.status).toLowerCase()} text-left`}>
                                    {ChatStatusStr(row.status)}
                                </div>;
                            case 'duration':
                                return <div>{ format_time_mm_ss(row.duration) }</div>;
                            case 'commission':
                                return <div className="d-flex align-items-center">
                                {(()=>{
                                    if(editTarget && editTarget.colname === colname && editTarget.rowIndex === rowIndex) {
                                        return <>
                                            <div style={{width:80}}>
                                                <MDBInput type="number" 
                                                    name='commission' 
                                                    value={editTarget.chat.commission}
                                                    onChange={handleInputChange}
                                                    className="text-center"
                                                    onKeyDown={handleKeyDown}
                                                />
                                            </div>
                                            {saving && <MDBSpinner role='status' size="sm" />}
                                            {!saving && <>
                                                <MDBIcon fas icon='save' 
                                                    size="lg" 
                                                    color="success" 
                                                    className="ms-1 cursor-pointer" 
                                                    title="Save" 
                                                    onClick={handleUpdateChat}
                                                />
                                                <MDBIcon fas icon='ban' 
                                                    size="lg" 
                                                    color="warning" 
                                                    className="ms-1 cursor-pointer" 
                                                    title="Cancel" 
                                                    onClick={handleCancelEdit}
                                                />
                                            </>}
                                        </>
                                    } else {
                                        return <div className="d-flex align-items-center">
                                            <span className="me-1">{row.commission}%</span>
                                            {row.status == ChatStatus.ENDED &&
                                                <MDBIcon fas icon="pencil-alt" 
                                                    size="lg" 
                                                    color="warning" 
                                                    className="cursor-pointer"
                                                    title='Change Commission' 
                                                    onClick={(e)=>onChgCommissionClicked(row, rowIndex, colname)} 
                                                />
                                            }
                                        </div>
                                    }
                                })()}
                                </div>
                            case 'ad_earning':
                                return <>
                                {(()=>{
                                    if(editTarget?.rowIndex === rowIndex) {
                                        return <div>$ {editTarget.chat.ad_earning}</div>
                                    } else {
                                        return <div>{row.status == ChatStatus.ENDED ? `$ ${row.ad_earning}` : ''}</div>
                                    }
                                })()}
                                </>
                            case 'createdAt':
                                return <div><Moment format='YYYY-MM-DD hh:mm'>{row.createdAt}</Moment></div>
                            case 'bill':
                                return <>
                                    {row.status == ChatStatus.ENDED && <div className="billing-time" title="Pending">{getTimeDiff(new Date(row.bill_time))}</div>}
                                    {row.status == ChatStatus.BILLED && <Moment format="YYYY-MM-DD">{row.bill_time}</Moment>}
                                </>
                            case 'action':
                                return <div className="action d-flex">
                                    <div className="cursor-pointer" title='Show Detail' onClick={(e)=>showDetail(e, row)}>
                                        <MDBIcon fas icon="eye" size='lg' color="info" />
                                    </div>
                                </div>
                        }
                    }}
                </AdminTable>

                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
                </div>
                {loading && <LoadingIndicator />}
            </div>
        </div>
    </>)
});

export default AdminChatList;
