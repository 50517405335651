import React, {useEffect, useRef, useState} from "react";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import VideoSelector from "../../../Components/VideoSelector/VideoSelector";
import ReactHowler from 'react-howler';
import { scrollToElement, validateForm } from "../../../Utils/utils";

const AdvisorSignupStep4 = React.memo((props) => {
    const [formData, setFormData] = useState(props.data)
    const [audioPlaying, setAudioPlaying] = useState(false);
    const [inputErrors, setInputErrors] = useState({});

    const onVideoSelected = (file) => {
        setFormData({
            ...formData,
            video: file
        })
    }
    const rules = {
        video: {required: 'video'}
    }

    const onPrev = (e) => {
        e.preventDefault();
        props.onPrev && props.onPrev(formData);
    }
    const onNext = (e) => {
        e.preventDefault();

        const valid_ret = validateForm(formData, rules);
        if(!valid_ret.result) {
            setInputErrors(valid_ret.errors);
            scrollToElement(document.getElementById(Object.keys(valid_ret.errors)[0]))
            return;
        } else {
            setInputErrors({});
            props.onNext && props.onNext(formData);
        }
    }
    const handlePlayAudio = () => {
        setAudioPlaying(true);
    }
    const handleStopAudio = () => {
        setAudioPlaying(false);
    }

    return (
    <MDBContainer breakpoint="lg p-2 p-md-4">
        <MDBCard className="signup-step4-card">
            <MDBCardHeader className="text-center">
                <div className="mb-2">STEP 4 OF 4</div>
                <h4 style={{color:'purple'}}>Upload a video for your clients to know more about you.</h4>
            </MDBCardHeader>
            <MDBCardBody className="d-flex flex-column align-items-center p-2 py-4">
                <div className="video-guide mb-4">
                    <h6 className="text-center my-2 fw-bold">Video Guidelines</h6>
                    <ul>
                        <li>
                            <div>Please greet your clients by saying: <span className="fw-bold">"Welcome to Confideas."</span></div>
                            <div className="d-flex justify-content-end align-items-center pe-4">
                                (Hear the pronunciation
                                {!audioPlaying && (
                                    <MDBBtn tag='a' color="none" onClick={handlePlayAudio}>
                                        <MDBIcon className="mx-1" fas icon="volume-off" color="primary" size="lg" />
                                    </MDBBtn>
                                )}
                                {audioPlaying && (
                                    <MDBBtn tag='a' color="none" onClick={handleStopAudio}>
                                        <MDBIcon className="mx-1" fas icon="volume-up" color="primary" size="lg" />
                                    </MDBBtn>
                                )}
                                )
                            </div>
                            <ReactHowler src='/audio/welcome_to_confideas.mp3' playing={audioPlaying} loop={false} onStop={handleStopAudio} />
                        </li>
                        <li>
                            Position yourself in a professional setting. Do not film yourself in a car.
                        </li>
                        <li>
                            Do not mention personal contact information.
                        </li>
                        <li>
                            Ensure the background shows no words or signs that could be traced back to you.
                        </li>
                    </ul>
                </div>
                <VideoSelector 
                    defaultSrc={formData.new_video ? URL.createObjectURL(formData.new_video) : null} 
                    onVideoSelected={onVideoSelected} 
                    inputErrors={inputErrors}
                />
            </MDBCardBody>
            <MDBCardFooter className="text-center p-4">
                <MDBBtn color="second" tabIndex={-1} onClick={onPrev}>Prev</MDBBtn>
                <MDBBtn className="ms-4 confideas-primary-btn" tabIndex={-1} color="primary" onClick={onNext}>Next</MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    </MDBContainer>
    )
}, (prevProps, nextProps) => {
    return !nextProps.refresh;
});

export default AdvisorSignupStep4;