import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import React, {useEffect, useState} from "react";
import ChatOffMessage from "./ChatOffMessage";
import JwtService from "../../Core/Service/JwtService";
import { getAvatarUrl, v_url } from "../../Utils/utils";
import FloatBar from "../FloatBar/FloatBar";
import SVG from 'react-inlinesvg';
import { useNavigate } from "react-router-dom";

const ChatOffMessagePage = React.memo((props) => {
    const thread = JwtService.getChatOffThread();
    const partner = JwtService.getChatOffPartner();
    const [availCoupon, setAvailCoupon] = useState();
    const navigate = useNavigate();

    const goBack = () => {
        navigate(v_url('/chatoff_threads'));
    }

    const renderFloatBar = () => {
        return (
            <FloatBar triggerY={50} mobile_float_y={60} pc_float_y={80}>
                <div className="d-flex align-items-center justify-content-center w-100">
                    <MDBBtn 
                        color='none' tag='a'
                        className='p-1'
                        onClick={goBack}
                    >
                        <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
                    </MDBBtn>
                    <img className="ms-1 circle-avatar-30" src={getAvatarUrl(partner?.avatar)} />
                    <span className="ms-2">{ partner?.username }</span>
                </div>
            </FloatBar>
        )
    }

    return (
        <MDBContainer breakpoint="lg" className="p-2 p-md-4">
            {renderFloatBar()}

            <div className="d-flex align-items-center justify-content-center p-2 pt-0" style={{borderBottom:'1px solid #eee'}}>
                <MDBBtn 
                    color='none' tag='a'
                    className='p-1'
                    onClick={goBack}
                >
                    <SVG src='/img/icons/angle-left.svg' width={15} height={15} fill='#d566fd' />
                </MDBBtn>
                
                <img src={getAvatarUrl(partner.avatar)} className="circle-avatar-40 ms-2" />
                <span className="ms-2 fw-bold" style={{color:'purple'}}>{partner.username}</span>
                {availCoupon && <div className="position-relative ms-4" style={{width:30, height: 30}}>
                    <span className="coupon">{availCoupon.availMinutes}</span>
                </div>}
            </div>

            <ChatOffMessage 
                partner={partner} 
                thread={thread} 
                setAvailCoupon={setAvailCoupon} 
            />
        </MDBContainer>
    )
});

export default ChatOffMessagePage;