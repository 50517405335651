import { MDBBtn, MDBIcon, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React, {useContext, useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { IsChatCreated, memoizedGetChat, removeChat } from "../../Store/Reducer/chatReducer";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { format_time_mm_ss, getAvatarUrl } from "../../Utils/utils";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import { ChatStatus, UserRole } from "../../Constants/constants";
import SVG from 'react-inlinesvg';
import ModalContext from "../../Context/ModalContext";
import ChatContext from "./ChatContext";
import { confirm_alert } from "../../Components/ConfirmAlert/ConfirmAlert";

function ChatPartner(props) {
  const dispatch = useDispatch();
  const chatContext = useContext(ChatContext);
  const [remainTime, setRemainTime] = useState(0);
  const isChatCreated = useSelector(IsChatCreated)
  const chat = useSelector(memoizedGetChat);
  const authUser = useSelector(memoizedGetAuthUser);
  const [partner, setPartner] = useState({});
  const {endChatSession} = chatContext;
  const modalContext = useContext(ModalContext);
  const timerRef = useRef(null);
  const isChatSockConnected = useSelector(IsChatSocketConnected);
  const isChatSockConnected_ref = useRef(false);

  useEffect(()=>{
    isChatSockConnected_ref.current = isChatSockConnected;
  }, [isChatSockConnected])


  useEffect(() => {
    if(isChatCreated) {
      // To first clear timer if any
      if(timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      } 

      if(chat.isChatting && chat.status != ChatStatus.FREE_SETUP_SESSION) {       // in the case of free_setup_session, coupon_session, pay_seesion
        setRemainTime(Math.max(0, chat.sessionTime - Math.floor((new Date().getTime() - chat.current_session_startedAt) / 1000)));

        timerRef.current = setInterval(()=>{
          const remainTime = Math.max(0, chat.sessionTime - Math.floor((new Date().getTime() - chat.current_session_startedAt) / 1000));
          setRemainTime(remainTime);
  
          if(remainTime == 0) {
            clearInterval(timerRef.current);
            timerRef.current = null;
          }
        }, 1000);
      }
    }
  }, [isChatCreated, chat?.status]);

  useEffect(()=>{
    if(authUser && isChatCreated) {
      setPartner(authUser.role === 'client' ? chat.advisor : chat.client);
    }
  }, [authUser, isChatCreated])

  const onEndChatBtnClicked = () => {
    chatContext.setChatInputFocus(false);
    confirm_alert({
      message: 'Do you want to quit the chat?',
      ok: () => {
          endChatSession();
      },
    })
  }
  const viewClientDetail = () => {
    if(authUser?.role === UserRole.ADVISOR) {
      modalContext.setModalData({
        client: partner
      })
      modalContext.setOpenClientDetailModal(true);
    }
  }


  const renderNormal = () => {
    return <div className="page-normal d-flex flex-column pt-3">
          <div className="text-center chat-session-name">
            {chat?.status === ChatStatus.COUPON_SESSION && <span>Free Minutes</span>}
            {chat?.status === ChatStatus.PAY_SESSION && <span>Paid Chat</span>}
          </div>

          {chat.status > ChatStatus.CONNECTED && <>
          <div className="timer d-flex justify-content-center align-items-center mt-1 mb-4">
              <img src='/img/icons/png/timer.png' className="me-3" alt='' />
              {chat?.status === ChatStatus.FREE_SETUP_SESSION && <span>Free Setup Time</span>}
              {chat?.status != ChatStatus.FREE_SETUP_SESSION && 
              <div className={`d-flex align-items-center spent-time`} translate="no">
                { format_time_mm_ss(remainTime) }
              </div>}
          </div>
          </>}

          <div className="flex-fluid d-flex flex-column justify-content-end">
            <div className="photo">
              {chat?.status === ChatStatus.CONNECTED && <div className="position-relative">
                <img className="waiting-partner" src={getAvatarUrl()} />
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <MDBSpinner size='sm' />
                  <span className="ms-2">Connecting...</span>
                </div>
              </div>}
              {chat?.status > ChatStatus.CONNECTED && <>
                <img src={getAvatarUrl(partner?.avatar)} alt=''/>
                <div className="name mt-2 text-center">{partner?.username}</div>
              </>}
            </div>
          </div>

          {/* don't remove the below div element, it is for positioning the above photo element */}
          <div className="flex-fluid">
          </div>

          <div className="d-flex flex-column justify-content-end action">
            {chat?.freeMinutes > 0 && <div className="free-minutes d-flex justify-content-center align-items-center mb-4">
              <label>Free</label> 
              <span className="mx-1">:</span>
              <div className="value d-flex align-items-center">
                <div className="icon mx-2 d-flex justify-content-center align-items-center" title="Free service">
                    {chat?.freeMinutes}
                </div>min
              </div>
            </div>
            }

            <div className="text-center">
              { authUser.role === UserRole.ADVISOR &&
                <MDBBtn className="page-primary-btn edit-note-btn mb-4" onClick={viewClientDetail} title="Edit the note about client">
                  <MDBIcon far icon="address-book" size="lg" className="me-4" />
                  View Note
                </MDBBtn>
              }
                <MDBBtn className="page-primary-btn end-chat-btn" onClick={onEndChatBtnClicked} title="End this chat session">
                  <MDBIcon fas icon="sign-out-alt" size="lg" className="me-4" />
                  Hang Up
                </MDBBtn>
            </div>
          </div>
      </div>
  }

  return <div className="chat-partner-container d-none d-lg-flex">
    { renderNormal() }
  </div>
}

export default ChatPartner;