import { MDBInput } from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { useState } from "react";
import { formatSSN } from "../../Utils/utils";

const SSNInput = React.memo((props) => {
    const [value, setValue] = useState({
        ssn: props.value ?? '',
        ssnUndo: props.value ?? ''
    });

    useEffect(()=>{
        _asterisk();
    }, [])

    const handleInputChange = (e) => {
        const newValue = formatSSN(e.target.value, value.ssn);
        setValue({
            ssn: newValue,
            ssnUndo: newValue
        });
        props.onChange && props.onChange(newValue);
    }

    const _asterisk = (e) => {
        var r = new RegExp("(?:d{3})-(?:d{2})-(d{4})");
        var str = value.ssn;
        var result = str.replace(r, "###-##-");

        let reg = /.{1,6}/;
        let string = result;
        var uo = string.replace(reg, m => "*".repeat(m.length));

        setValue({
            ...value,
            ssn: uo,
        })
    }

    const _unasterisk = (e) => {
        setValue({
            ...value,
            ssn: value.ssnUndo
        })
    }

    return <MDBInput 
            className="form-control"
            tabIndex={props.tabIndex ?? -1}
            value={value.ssn}
            onChange={handleInputChange}
            onBlur={_asterisk}
            onFocus={_unasterisk}
            placeholder="###-##-####"
        />
})

export default SSNInput;