import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { IsAuthenticated, memoizedGetAuthUser, updateAuth } from "../../../Store/Reducer/authReducer";
import { useEffect } from "react";
import { getAvatarUrl, isValidDate } from "../../../Utils/utils";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBContainer, MDBIcon, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import ImageSelector from "../../../Components/ImageSelector/ImageSelector";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { useNavigate } from "react-router-dom";
import { getPreviousUrl, popHistory } from "../../../Store/Reducer/urlHistoryReducer";

const MainInfo = React.memo((props) => {
    const dispatch = useDispatch();
    const previousUrl = useSelector(getPreviousUrl);
    const [loading, setLoading] = useState(false);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [savingMainInfo, setSavingMainInfo] = useState(false);
    const [formData, setFormData] = useState({
        avatar: '',
        new_avatar: null,
        mobile: '',
        address: '',
        birth: new Date()
    })
    const navigate = useNavigate();

    useEffect(()=>{
        if(isAuthenticated) {
            setLoading(true);

            ApiService.get('/client/auth').then(result=>{
                setFormData(result.data.client);
            })
            .catch(error=>{

            })
            .finally(()=>{
                setLoading(false);    
            })
        }
    }, [isAuthenticated]);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }
    const onPhoneNumberChanged = (pn) => {
        setFormData({
            ...formData,
            mobile: pn
        })
    }
    const onBirthChanged = (date) => {
        console.log(date);
        setFormData({
            ...formData,
            birth: date
        })
    }

    const onAvatarSelected = (image, file) => {
        setFormData({
            ...formData,
            new_avatar: file
        });
        return true;
    }

    const onSave = () => {
        setSavingMainInfo(true);

        const data = new FormData();
        Object.keys(formData).forEach(key=>{
            data.append(key, formData[key]);
        })

        const headers = {'Content-Type': 'multipart/form-data'};

        ApiService.post('/client/auth', data, headers).then(result => {
            dispatch(updateAuth(result.data.user));
            toast.success("Your account details have been updated")
        })
        .finally(()=>{
            setSavingMainInfo(false);
        })
    }
    const goBack = (e) => {
        if(previousUrl) {
            const url = previousUrl;
            dispatch(popHistory())
            navigate(url);
        }
    }

    const renderAvatar = () => {
        return (
            <MDBCard className="share-with-advisor-public-info-card">
                <MDBCardHeader className="d-flex align-items-center">
                    <MDBIcon far icon="user-circle" color="info" size='lg'/>
                    <span className="px-2"><strong>Public Information</strong></span>
                </MDBCardHeader>
                <MDBCardBody >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="position-relative">
                            <ImageSelector 
                                cropImage = {true}
                                onImageSelected = {onAvatarSelected}
                                defaultSrc={getAvatarUrl(authUser.avatar)} 
                            />
                            <div className="edit-avatar">
                                <MDBBtn tag='a' color="none"><MDBIcon fas icon="upload" color="" size="lg" /></MDBBtn>
                            </div>
                        </div>
                        <div className="mt-2"><strong>{authUser?.username}</strong></div>
                    </div>
                </MDBCardBody>
            </MDBCard>
        )
    }
    const renderShareOptions = () => {
        return (
            <MDBCard className="share-with-advisor-share-options-card mt-4">
                <MDBCardHeader className="d-flex align-items-center">
                    <MDBIcon fas icon='info-circle' color="info" size='lg'/> 
                    <span className="px-2"><strong>Share with Advisors (Optional)</strong></span>
                </MDBCardHeader>
                <MDBCardBody >
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="w-100">
                            <label>Birthday</label>
                            <DatePicker 
                                selected={isValidDate(formData?.birth) ? new Date(formData?.birth) : new Date()} 
                                tabIndex={1}
                                className="form-control"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholder="Your birthday"
                                dateFormat="yyyy-MM-dd"
                                onChange={(date) => onBirthChanged(date)} 
                            />
                        </div>
                    </div>
                </MDBCardBody>
            </MDBCard>
        )
    }
    const renderNotPublic = () => {
        return (
            <MDBCard className="not-public-info-card mt-4">
                <MDBCardHeader className="d-flex align-items-center">
                    <MDBIcon fas icon='address-card' color="info" size='lg'/> 
                    <span className="px-2"><strong>My Contact (Not Public)</strong></span>
                </MDBCardHeader>
                <MDBCardBody>
                        <div className="">{authUser?.email}</div>
                        <div className="mt-3 w-100">
                            <label>Phone</label>
                            <PhoneInput placeholder='Enter your phone number'
                                    value={formData.mobile}
                                    tabIndex={1}
                                    className='form-control bg-light-dark h-12 rounded-2' 
                                    onChange={onPhoneNumberChanged} />
                        </div>
                        <div className="mt-3 w-100">
                            <label>Address</label>
                            <MDBTextArea label="" rows={2}
                                    className=""
                                    tabIndex={2}
                                    value={formData.address}
                                    name='address'
                                    onChange={handleInputChange}
                            />
                        </div>
                </MDBCardBody>
            </MDBCard>
        )
    }
    const renderActionBar = () => {
        return (
            <div className="fixed-action-bar w-100 d-flex justify-content-center align-items-end mt-4">
                <MDBBtn className="" onClick={goBack} color="defaut" style={{background: '#fefefe'}}>Back</MDBBtn>
                <MDBBtn onClick={onSave} disabled={savingMainInfo} className="ms-4 confideas-primary-btn">
                    {savingMainInfo && <div className="d-flex align-items-center">
                        <MDBSpinner role='status' size="sm" />
                        <span className="ms-1">Saving</span>
                    </div>}
                    {!savingMainInfo && <span>Save</span>}
                </MDBBtn>
            </div>
        )
    }

    return (<section className="d-flex flex-column align-items-center">
        <div className="w-100" style={{maxWidth: 600, paddingBottom:40}}>
            {renderAvatar()}
            {renderShareOptions()}
            {renderNotPublic()}
            {renderActionBar()}
        </div>
        {loading && <LoadingIndicator />}
    </section>)
});

export default MainInfo;