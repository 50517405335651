import React, {useContext, useEffect, useState} from 'react';
import { 
    MDBContainer, 
    MDBIcon, 
    MDBFooter,
    MDBRow,
    MDBCol,
    MDBBtn
 } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IsAuthenticated, memoizedGetAuthUser } from '../../Store/Reducer/authReducer';
import ModalContext from '../../Context/ModalContext';
import { UserRole } from '../../Constants/constants';
import { v_url } from '../../Utils/utils';
import SVG from 'react-inlinesvg';

 const Footer = React.memo(() => {
    const isLogged = useSelector(IsAuthenticated);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const [expanded, setExpanded] = useState(false);

    const handleContactUs = (e) => {
        if(!isAuthenticated) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser.role === UserRole.ADMIN) return;

        modalContext.setOpenSendOpinionModal(true);
    }

    const renderBecomeAdvisor = () => {
        return <div className='become-advisor'>
            <div className='d-flex align-items-center'>
                <Link 
                    to={v_url('/advisor_auth/signup')}
                    className='d-flex header align-items-center fw-bold'
                    style={{color: '#cb17c5'}}
                >
                    <MDBIcon fas icon="user-graduate" className='me-2' />
                    <span className='underline-bar'>Become an Advisor</span>
                </Link>
                <MDBIcon className='ms-2 hand-point-left' far icon="hand-point-left" size='lg' color='black-50' />
            </div>
            <p className='text-left'>
                Are you a gifted spiritual advisor with exceptional insights? 
                Join Confideas and leverage your unique talents to connect with clients
                worldwide in a supportive environment.
            </p>
        </div>
    }

    const expandFooter = (expand) => {
        setExpanded(expand);
        if(expand) {
            setTimeout(()=>{
                let scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
                window.scroll({
                    top: scrollableHeight,
                    behavior: 'smooth'
                })
            }, 200);
        }
    }

    const renderPCVersion = () => {
        return (
            <div className='d-none d-md-block' style={{position: 'relative'}}>
                {expanded && (
                    <>
                        {!isLogged && renderBecomeAdvisor() }
                        <MDBRow className='text-left fw-bold mb-2'>
                            <MDBCol><Link to={v_url('/about_us')}><nobr>About Confideas</nobr></Link></MDBCol>
                            <MDBCol><Link to={v_url('/privacy_policy')}><nobr>Privacy Policy</nobr></Link></MDBCol>
                            <MDBCol><Link to={v_url('/terms_of_use')}><nobr>Terms of Use</nobr></Link></MDBCol>
                            { isLogged && authUser?.role === UserRole.ADVISOR && 
                                <MDBCol><Link to={v_url('/terms_of_use')}><nobr>Advisor Terms & Conditions</nobr></Link></MDBCol>
                            }
                            <MDBCol><Link onClick={handleContactUs}><nobr>Contact Us</nobr></Link></MDBCol>
                        </MDBRow>
                    </>
                )}
                <div className='d-flex justify-content-center align-items-center' style={{marginRight: 50}}>
                    <div>©2024 Confideas. All Rights Reserved.</div>
                    <div className='ms-4 flex-fluid d-flex justify-content-end align-items-center'>
                        <div className='d-flex align-items-center'>
                            <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                            <span className='contact-email'>contact@confideas.com</span>
                        </div>
                        <div className='d-flex align-items-center ms-4'>
                            <MDBBtn color='none' tag='a' className='twitter social-link'>
                                <SVG src="/img/icons/twitter.svg" style={{marginTop:-3}} width={15} height={15} fill='#02378b' />
                            </MDBBtn>
                            <MDBBtn color='none' tag='a' className='social-link facebook ms-2'>
                                <MDBIcon fab icon="facebook-f" />
                            </MDBBtn>
                            <MDBBtn color='none' tag='a' className='social-link youtube ms-2'>
                                <MDBIcon fab icon="youtube" />
                            </MDBBtn>
                            <MDBBtn color='none' tag='a' className='social-link instagram ms-2'>
                                <MDBIcon fab icon="instagram" />
                            </MDBBtn>
                            {/* <MDBBtn color='none' tag='a' className='social-link telegram-plane ms-2'>
                                <MDBIcon fab icon="telegram-plane" />
                            </MDBBtn>
                            <MDBBtn color='none' tag='a' className='social-link discord ms-2'>
                                <MDBIcon fab icon="discord" />
                            </MDBBtn>
                            <MDBBtn color='none' tag='a' className='social-link whatsapp ms-2'>
                                <MDBIcon fab icon="whatsapp" />
                            </MDBBtn> */}
                        </div>
                    </div>
                </div>

                <div className='footer-expand'>
                    <MDBBtn tag='a' color='none' onClick={e=>expandFooter(!expanded)}>
                        <MDBIcon fas icon={expanded ? "chevron-down" : "chevron-up"} size='' />
                    </MDBBtn>
                </div>
            </div>
        )
    }
    const renderMobileVersion = () => {
        return (
            <div className='d-md-none text-left p-2' style={{position: 'relative'}}>
                {expanded && (
                    <>
                        {!isLogged && renderBecomeAdvisor() }
                        <div><Link to={v_url('/about_us')}>About Confideas</Link></div>
                        <div><Link to={v_url('/privacy_policy')}>Privacy Policy</Link></div>
                        <div><Link to={v_url('/terms_of_use')}><nobr>Terms of Use</nobr></Link></div>
                        <div><Link onClick={handleContactUs}><nobr>Contact Us</nobr></Link></div>
                        <div className='d-flex align-items-center mt-2'>
                            <div>©2024 Confideas</div>
                            <div className='flex-fluid d-flex justify-content-end align-items-center me-4'>
                                <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                                <span>contact@confideas.com</span>
                            </div>
                        </div>

                        <div className='footer-expand'>
                            <MDBBtn tag='a' color='none' onClick={e=>expandFooter(false)}>
                                <MDBIcon fas icon="chevron-down" size='' />
                            </MDBBtn>
                        </div>
                    </>
                )}
                {!expanded && (
                    <>
                        <div className='fw-400 text-center'>
                            <div className='d-flex align-items-center'>
                                <div>©2024 Confideas</div>
                                <div className='flex-fluid d-flex justify-content-end align-items-center me-4'>
                                    <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                                    <span>contact@confideas.com</span>
                                </div>
                            </div>
                            <div className='footer-expand'>
                                <MDBBtn tag='a' color='none' onClick={e=>expandFooter(true)}>
                                    <MDBIcon fas icon="chevron-up" size='' />
                                </MDBBtn>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    return (
        <MDBFooter className='d-none d-md-block text-center text-lg-start footer'>
            <MDBContainer breakpoint="lg" className='p-3'>
                {renderPCVersion()}
                {/* {renderMobileVersion()} */}
            </MDBContainer>
        </MDBFooter>
    )
 });

 export default Footer;