import { configureStore } from "@reduxjs/toolkit";
import devToolsEnhancer from 'remote-redux-devtools';
import authReducer from "./Reducer/authReducer";
import socketReducer from "./Reducer/socketReducer";
import chatReducer from "./Reducer/chatReducer";
import changedAdvisorReducer from './Reducer/changedAdvisorReducer';
import alarmReducer from "./Reducer/alarmReducer";
import chatoffMsgReducer from "./Reducer/chatoffMsgReducer";
import urlHistoryReducer from "./Reducer/urlHistoryReducer";

const store = configureStore({
    reducer: {
        auth: authReducer,
        socket: socketReducer,
        chat: chatReducer,
        changedAdvisor: changedAdvisorReducer,
        alarm: alarmReducer,
        chatoff_message:chatoffMsgReducer,
        urlHistory: urlHistoryReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false,
    }),
    // enhancers: [devToolsEnhancer()]
});

export default store;