import React from "react";
import classname from 'classnames';
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import styles from './home.scss';

function WhyChooseUs(props) {
    return (
        <div className="why-choose-us-container">
            <MDBContainer breakpoint="lg" className="why-choose-us-container2">
                <img className="flower1" src='/img/backgrounds/plumeria.svg' />
                <img className="flower2" src='/img/backgrounds/plumeria.svg' />
                <div>
                    <MDBRow>
                        <MDBCol md='6' className="px-4">
                            <h4>Psychic Reading:</h4>
                            <p>
                                Have you ever wished to have a GPS to know your paths? Our advisors can help you with love, twin flames, and many more.
                            </p>
                        </MDBCol>
                        <MDBCol md='6' className="px-4">
                            <h4>Real Person Consulting:</h4>
                            <p>
                                Discover powerful insights from experts and psychics with live chat confidential support 24/7.
                            </p>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        </div>
    )
}

export default WhyChooseUs;