import React, { useEffect, useState, useRef } from "react";
import './ChatDetail.scss';
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer } from "mdb-react-ui-kit";
import ChatMetaInfo from "./ChatMetaInfo";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ApiService from "../../Core/Service/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { useNavigate, useParams } from "react-router-dom";
import { getPreviousUrl, popHistory } from "../../Store/Reducer/urlHistoryReducer";
import { getAvatarUrl, v_url } from "../../Utils/utils";
import { UserRole } from "../../Constants/constants";
import SVG from 'react-inlinesvg';
import FloatBar from "../FloatBar/FloatBar";
import { IsChatSocketConnected } from "../../Store/Reducer/socketReducer";

const ChatDetail = React.memo((props) => {
    const dispatch = useDispatch();
    const previousUrl = useSelector(getPreviousUrl)
    const params = useParams();
    const chatId = params._id;
    const [loading, setLoading] = useState(false);
    const [chat, setChat] = useState(null);
    const [partner, setPartner] = useState();
    const authUser = useSelector(memoizedGetAuthUser);
    const navigate = useNavigate();
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    
    useEffect(()=>{
        if(authUser) {
            setLoading(true);

            ApiService.get('/chat/' + chatId).then(result => {
                setChat(result.data.chat);
                if(authUser.role === UserRole.CLIENT) setPartner(result.data.chat.aId);
                if(authUser.role === UserRole.ADVISOR) setPartner(result.data.chat.cId);
            })
            .finally(()=>{
                setLoading(false);
            });
        }
    }, [authUser]);

    const goBack = (e) => {
        e.preventDefault();
        const url = authUser?.role === UserRole.ADVISOR ? v_url(`/advisor/client/detail/${chat.cId._id}`) : v_url(`/client/advisor/detail/${chat.aId._id}`);
        navigate(url);
    }

    const renderFloatBar = () => {
        return (
        <FloatBar triggerY={145} mobile_float_y={60} pc_float_y={80}>
            <MDBBtn 
                color='none' tag='a'
                className='p-1'
                onClick={goBack}
            >
                <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
            </MDBBtn>
            <img className="ms-1 circle-avatar-30" src={getAvatarUrl(partner?.avatar)} />
            <span className="ms-2">{ partner?.username }</span>
        </FloatBar>
        )
    }

    return (
        <MDBContainer breakpoint="lg" className="chat-detail-container d-flex flex-column align-items-center position-relative p-2 p-md-4">
            {renderFloatBar()}

            <div className="go-back-bar">
                <MDBBtn 
                    color='none' tag='a'
                    className='p-1'
                    onClick={goBack}
                >
                    <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
                </MDBBtn>
            </div>

            {chat && (<div className="w-100" style={{maxWidth: 700}}>
                <div className="d-none d-md-block w-100">
                    <MDBCard>
                        <MDBCardBody className="p-4">
                            <ChatMetaInfo chat={chat} />
                        </MDBCardBody>
                    </MDBCard>
                </div>

                <div className="d-block d-md-none w-100">
                    <ChatMetaInfo chat={chat} />
                </div>
            </div>)}
            {loading && isChatSockConnected && <LoadingIndicator fullScreen={true} />}
        </MDBContainer>
    )
});

export default ChatDetail;