import { createSlice } from '@reduxjs/toolkit';

const urlHistorySlice = createSlice({
    name: 'url_history',
    initialState: {
        urlHistories: [],
        previousUrl: null,
        is_back: false
    },
    reducers: {
        addHistory: (state, action) => {
            if(!state.is_back) {
                state.urlHistories.push(action.payload.url);
                state.previousUrl = action.payload.url
            }
            state.is_back = false;
        },
        popHistory: (state, action) => {
            if(state.urlHistories.length > 0) {
                state.urlHistories.pop();
                if(state.urlHistories.length > 0) {
                    state.previousUrl = state.urlHistories[state.urlHistories.length - 1]
                } else {
                    state.previousUrl = null;
                }
            }
            state.is_back = true;
        }
    }
})

export const {addHistory, popHistory} = urlHistorySlice.actions;

export const getPreviousUrl = (state) => state.urlHistory.previousUrl;

export default urlHistorySlice.reducer;