import { MDBCarousel, MDBCarouselItem, MDBContainer, MDBCarouselCaption } from "mdb-react-ui-kit";
import React from "react";
import styles from './home.scss';

function TopAdvisors(props) {
    return (
        <div className="top-advisor-container py-3">
            <MDBContainer breakpoint="lg">
                <div>
                    <h4>Top advisor</h4>
                    <p>
                        Make a connection with our advisors in a way that is tailored just for you, and you'll receive compassionate and caring support.
                    </p>
                </div>

                <MDBCarousel showControls showIndicators>
                    <MDBCarouselItem itemId={1}>
                        <div className="photo-container">
                            <div className="photo d-flex justify-content-center">
                                <img src='/img/advisors/photos/advisor1.png' alt=''/>
                            </div>
                        </div>
                        <MDBCarouselCaption>
                            <section className="intro-container">
                                <h5>Linda</h5>
                                <p>
                                    Hey there, I am Linda, and I'm completely fascinated by tarot. 
                                    The way those cards speak to the soul is just incredible!
                                    I've found so much wisdom and guidance within those beautifully illustrated decks.
                                    It's like diving into a pool of insight and emerging with a clearer understanding of life's twists and turns.
                                </p>
                            </section>
                        </MDBCarouselCaption>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId={2}>
                        <div className="photo-container">
                            <div className="photo d-flex justify-content-center">
                                <img src='/img/advisors/photos/advisor2.png' alt=''/>
                            </div>
                        </div>
                        <MDBCarouselCaption>
                            <section className="intro-container">
                                <h5>Linda</h5>
                                <p>
                                    Hey there, I am Linda, and I'm completely fascinated by tarot. 
                                    The way those cards speak to the soul is just incredible!
                                    I've found so much wisdom and guidance within those beautifully illustrated decks.
                                    It's like diving into a pool of insight and emerging with a clearer understanding of life's twists and turns.
                                </p>
                            </section>
                        </MDBCarouselCaption>
                    </MDBCarouselItem>

                </MDBCarousel>
            </MDBContainer>   
        </div>
    )
}

export default TopAdvisors;