import { confirmAlert } from "react-confirm-alert";
import './ConfirmAlert.scss';
import { MDBBtn } from "mdb-react-ui-kit";

export const confirm_alert = (options) => {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({onClose }) => {
          return (
            <div className='confirm-alert'>
              <div className="message p-4 d-flex justify-content-center align-items-center">
                  {options.message}
              </div>
              <div className="d-flex align-items-center justify-content-center action-bar">
                <MDBBtn color="default no" className="p-1 px-3" onClick={onClose}>No</MDBBtn>
                <MDBBtn onClick={()=>{ options.ok(); onClose(); }} color="info" className="p-1 px-3 ms-4">Yes</MDBBtn>
              </div>
            </div>
          );
        }
    });
}