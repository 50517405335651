import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import LoginModal from '../Components/Modals/LoginModal';
import SignupModal from '../Components/Modals/SignupModal';
import WaitChatModal from '../Components/Modals/WaitChatModal';
import AcceptChatModal from '../Components/Modals/AcceptChatModal';
import PayAmountModal from '../Components/Modals/PayAmountModal';
import AllowNotifyModal from '../Components/Modals/AllowNotifiyModal';
import ChangePasswdModal from '../Components/Modals/ChangePasswdModal';
import SendCouponModal from "../Components/Modals/SendCouponModal";
import AdvisorNoteModal from "../Components/Modals/AdvisorNoteModal";
import SendBuzzModal from "../Components/Modals/SendBuzzModal";
import ActivateListingModal from "../Components/Modals/ActivateListingModal";

import MainLayout from "../Layout/MainLayout";
import HomePage from "../Pages/Home";

import ClientProtectedRoutes from "../Components/Auth/ClientProtectedRoutes";
import ClientMyPage from "../Pages/Client/MyPage";
import ClientMyWallet from "../Pages/Client/MyWallet";
import ClientMessage from "../Pages/Client/Messages";

import Chat from "../Pages/Chat";
import ChatHistory from "../Components/ChatHistory/ChatHistory";
import ChatView from '../Components/ChatView';

import AdvisorAuthLayout from "../Layout/AdvisorAuthLayout";
import AdvisorSignup from "../Pages/Advisor/Auth/AdvisorSignup";
import AdvisorDashboard from "../Pages/Advisor/Dashboard";
import AdvisorMessage from "../Pages/Advisor/Messages";

import AdvisorProtectedRoutes from "../Components/Auth/AdvisorProtectedRoutes";
import AuthProtectedRoutes from "../Components/Auth/AuthProtectedRoutes";
import AdvisorSignupStep from "../Pages/Advisor/Auth/AdvisorSignupStep";
import AdvisorShow from "../Pages/Advisor/Show";
import AdvisorContactEdit from "../Pages/Advisor/Edit/MyContact";
import AdvisorListingEdit from "../Pages/Advisor/Edit/MyListing";
import AdvisorClientDetail from "../Pages/Advisor/ClientDetail";

import AdminProtectedRoutes from "../Components/Auth/AdminProtectedRoutes";
import AdminLayout from "../Layout/AdminLayout";
import AdminDashboard from "../Pages/Admin/Dashboard";
import AdminAdvisorList from "../Pages/Admin/Advisor/AdvisorList";
import AdminClientList from "../Pages/Admin/Client/ClientList";
import AdminAdvisorEdit from "../Pages/Admin/Advisor/AdvisorEdit";
import AdminChatList from "../Pages/Admin/Chat/ChatList";
import AdminAdvisorPendingList from "../Pages/Admin/Advisor/AdvisorPendingList";
import AdminAdvisorCheckProfile from "../Pages/Admin/Advisor/AdvisorCheckProfile";
import AdminAdvisorCheckSignUp from "../Pages/Admin/Advisor/AdvisorCheckSignup";
import AdminTransactionList from "../Pages/Admin/Transaction/TransactionList";
import AdminTrackWordList from "../Pages/Admin/TrackWord/TrackWordList";
import AdminBannedChatList from "../Pages/Admin/TrackWord/BannedChatList";

import ServiceCreator from "../Components/ServiceCreator";
import CouponChat from "../Pages/Client/CouponChat/CouponChat";
import AllAdvisors from "../Pages/AllAdvisors";
import AdvisorPayout from "../Pages/Advisor/Payout";
import AdminSetting from "../Pages/Admin/Setting/Setting";
import AdminSettingPayment from "../Pages/Admin/Setting/Payment";
import AdminPaymentList from "../Pages/Admin/Transaction/PaymentList";
import AdminPayoutList from "../Pages/Admin/Transaction/PayoutList";
import Vitality from "../Pages/HelpCenter/Articles/Vitality";
import Commerce from "../Pages/HelpCenter/Articles/Commerce";
import ConflictResolve from "../Pages/HelpCenter/Articles/ConflictResolve";
import PsychicReading from "../Pages/HelpCenter/Articles/PSychicReading";
import AdminReviewList from "../Pages/Admin/Review/ReviewList";
import AdminEditReview from "../Pages/Admin/Review/EditReview";
import Logout from "../Components/Logout";
import SendOpinionModal from "../Components/Modals/SendOpinionModal";
import AdminOpinionList from "../Pages/Admin/Opinion/OpinionList";
import AdminOpinionEdit from "../Pages/Admin/Opinion/OpinionEdit";
import AdminActionList from "../Pages/Admin/AdminAction/AdminActionList";
import CreditBackModal from "../Components/Modals/CreditBackModal";
import PrivacyPolicy from "../Pages/HelpCenter/PrivacyPolicy/PrivacyPolicy";
import TermsOfuse from "../Pages/HelpCenter/TermsOfUse/TermsOfUse";
import EnoughBalanceModal from "../Components/Modals/EnoughBalanceModal";
import EditReviewModal from "../Components/Modals/EditReviewModal";
import CropImageModal from "../Components/Modals/CropImageModal";
import AdvisorTerms from "../Pages/HelpCenter/AdvisorTerms/AdvisorTerms";
import AboutUs from "../Pages/HelpCenter/AboutUs/AboutUs";
import DeclineAdvisorModal from "../Components/Modals/DeclineAdvisorModal";
import AlarmModal from "../Components/Modals/AlarmModal";
import ReLogin from "../Components/ReLogin";
import AdminVerifyItemList from "../Pages/Admin/VerifyItem/VerifyItemList";

import Test1 from "../Pages/Test/Test1";
import Test2 from "../Pages/Test/Test2";
import Test3 from "../Pages/Test/Test3";
import Test4 from "../Pages/Test/Test4";
import AdvisorUnderReview from "../Pages/Advisor/Auth/AdvisorUnderReview";
import AdvisorSignupThanks from "../Pages/Advisor/Auth/AdvisorSignupThanks";
import AdvisorSignupConfirm from "../Pages/Advisor/Auth/AdvisorSignupConfirm";
import AdvisorActivateListing from "../Pages/Advisor/Edit/ActivateListing";
import Info from "../Pages/HelpCenter/NavBar/NavBar";
import CheckOut from "../Pages/Client/CheckOut";
import AdvisorRateModal from "../Components/Modals/AdvisorRateModal";
import ChatDetail from "../Components/ChatDetail";
import ChatOffThreadPage from "../Components/ChatOffThread/ChatOffThreadPage";
import ChatOffMessageModal from "../Components/Modals/ChatOffMsgModal";
import ChatOffMessagePage from "../Components/ChatOffMessage/ChatOffMessagePage";
import ClientDetailModal from "../Components/Modals/ClientDetailModal";
import MyAdvisors from "../Pages/Client/MyAdvisors/MyAdvisors";
import ClientAdvisorDetail from "../Pages/Client/AdvisorDetail";
import AdminPendingPayoutList from "../Pages/Admin/Transaction/PendingPayoutList";
import AdminPendingPayoutDetail from "../Pages/Admin/Transaction/PendingPayoutDetail";
import ServiceMaintain from "../Pages/Error/ServiceMaintain";
import ChatEndModal from "../Components/Modals/ChatEndModal";
import { v_url } from "../Utils/utils";
import AdminNewClientList from "../Pages/Admin/Client/NewClientList";

function RoutesIndex() {
    return (<>
        <Router>
            <Routes>
                <Route element={<ServiceCreator />}>
                    <Route path={v_url('')} element={<MainLayout />}>
                        <Route index element={<HomePage />} />
                        <Route path='service_maintain' element={<ServiceMaintain />} />
                        <Route path='info' element={<Info /> } />
                        <Route path='about_us' element={<AboutUs /> } />
                        <Route path='privacy_policy' element={<PrivacyPolicy /> } />
                        <Route path='terms_of_use' element={<TermsOfuse /> } />
                        <Route path='advisor_terms' element={<AdvisorTerms /> } />
                        <Route path="all_advisors" element={<AllAdvisors />} />
                        <Route path='relogin' element={<ReLogin />} />
                        <Route path='login' element={<HomePage />} />
                        <Route path='logout' element={<Logout/> } />
                        <Route path='client/coupon_chat' element={<CouponChat />} />
                        <Route path='advisor/:_id' element={<AdvisorShow />} />
                        <Route path='psychic/:uName' element={<AdvisorShow />} />
                        <Route path='blog/vitality_and_wellbeing' element={<Vitality />} />
                        <Route path='blog/commerce_and_career' element={<Commerce />} />
                        <Route path='blog/conflict_resolution' element={<ConflictResolve />} />
                        <Route path='blog/psychic_readings' element={<PsychicReading />} />
                        <Route path='advisor/auth/undereview' element={<AdvisorUnderReview />} />

                        <Route element={<AuthProtectedRoutes />}>
                            <Route path='chat/history/:partner?' element={<ChatHistory />} />
                            <Route exact='true' path='chat/:_id/detail' element={<ChatDetail />} />
                            <Route path='chatoff_threads' element={<ChatOffThreadPage />} />
                            <Route path='chatoff_message' element={<ChatOffMessagePage />} />
                        </Route>

                        <Route path='client' element={<ClientProtectedRoutes />} >
                            <Route path='mypage' element={<ClientMyPage />} />
                            <Route path='chat/last_by_advisor' element={<MyAdvisors />} />
                            <Route path='mywallet' element={<ClientMyWallet />} />
                            <Route path='checkout/:amount' element={<CheckOut />} />
                            <Route path='message' element={<ClientMessage />} />
                            <Route path='advisor/detail/:aId' element={<ClientAdvisorDetail /> } />
                        </Route>

                        <Route path='advisor' element={<AdvisorProtectedRoutes /> }>
                            <Route path='dashboard' element={<AdvisorDashboard />} />
                            <Route path='edit/service' element={<AdvisorListingEdit />} />
                            <Route path='edit/contacts' element={<AdvisorContactEdit />} />
                            <Route path="message" element={<AdvisorMessage />} />
                            <Route path='payout' element={<AdvisorPayout /> } />
                            <Route path='client/detail/:cId' element={<AdvisorClientDetail />} />
                            <Route path='activatelisting' element={<AdvisorActivateListing />} />
                        </Route>
                    </Route>

                    <Route path={v_url('')} element={<AuthProtectedRoutes />}>
                        <Route exact='true' path='chat' element={<Chat />} />
                        <Route exact='true' path='chat/:_id/messages' element={<ChatView />} />
                    </Route>

                    <Route path={`${v_url('/advisor_auth')}`} element={<AdvisorAuthLayout />}>
                        <Route path='signup' element={<AdvisorSignup />} />
                        <Route path='signup-step' element={<AdvisorSignupStep />} />
                        <Route path='confirm' element={<AdvisorSignupConfirm />} />
                        <Route path='thanks' element={<AdvisorSignupThanks />} />
                    </Route>

                    <Route path={`${v_url('/admin')}`} element={<AdminLayout />}>
                        <Route element={<AdminProtectedRoutes />}>
                            <Route path='dashboard' element={<AdminDashboard />} />
                            <Route path='client/list' element={<AdminClientList />} />
                            <Route path='client/new/list' element={<AdminNewClientList />} />
                            <Route path='advisor/list' element={<AdminAdvisorList />} />
                            <Route path='advisor/:pending_type/list' element={<AdminAdvisorPendingList />} />
                            <Route path='advisor/edit/service/:aId' element={<AdminAdvisorEdit />} />
                            <Route path='advisor/check/profileupdate/:aId' element={<AdminAdvisorCheckProfile />} />
                            <Route path='advisor/check/signup/:aId' element={<AdminAdvisorCheckSignUp />} />
                            <Route path='chat/list/:userId?' element={<AdminChatList />} />
                            <Route path='transaction/all' element={<AdminTransactionList />} />
                            <Route path='transaction/payment' element={<AdminPaymentList />} />
                            <Route path='transaction/payout' element={<AdminPayoutList />} />
                            <Route path='transaction/pending_payout' element={<AdminPendingPayoutList />} />
                            <Route path='transaction/pending_payout/detail/:aId/:endDate' element={<AdminPendingPayoutDetail />} />
                            <Route path='setting/setting' element={<AdminSetting />} />
                            <Route path='setting/payment' element={<AdminSettingPayment />} />
                            <Route path='review/list' element={<AdminReviewList />} />
                            <Route path='review/edit/:_id' element={<AdminEditReview />} />
                            <Route path='opinion/list' element={<AdminOpinionList />} />
                            <Route path='opinion/edit/:_id' element={<AdminOpinionEdit />} />
                            <Route path='action/list' element={<AdminActionList />} />
                            <Route path='verifyitem/list' element={<AdminVerifyItemList />} />
                            <Route path='trackword/list' element={<AdminTrackWordList />} />
                            <Route path='bannedchat/list' element={<AdminBannedChatList />} />
                        </Route>
                    </Route>
                </Route>

                <Route path='/test1' element={<Test1 />} />
                <Route path='/test2' element={<Test2 />} />
                <Route path='/test3' element={<Test3 />} />
                <Route path='/test4' element={<Test4 />} />
                <Route path='*' element={<Navigate to={`/v${process.env.REACT_APP_VERSION}`} />} />
            </Routes>

            <LoginModal />
            <SignupModal  />
            <WaitChatModal />
            <AcceptChatModal />
            <PayAmountModal />
            <AllowNotifyModal />
            <ChangePasswdModal />
            <AdvisorNoteModal />
            <SendCouponModal />
            <SendBuzzModal />
            <SendOpinionModal />
            <CreditBackModal />
            <EnoughBalanceModal />
            <EditReviewModal />
            <CropImageModal />
            <DeclineAdvisorModal />
            <AlarmModal />
            <ActivateListingModal />
            <AdvisorRateModal />
            <ChatOffMessageModal />
            <ClientDetailModal />
            <ChatEndModal />

        </Router>
    </>
    )
}

export default RoutesIndex;