import React from "react";
import classname from 'classnames';
import styles from './Advisor.scss'
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { getAvatarUrl, v_url } from "../../Utils/utils";
import { IsAuthenticated, IsFavoriteAdvisor, addFavoriteAdvisor, memoizedGetAuthUser, removeFavoriteAdvisor } from "../../Store/Reducer/authReducer";
import { useDispatch, useSelector } from "react-redux";
import SVG from 'react-inlinesvg';
import { toast } from "react-toastify";
import { UserRole } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import { memoizedGetChat } from "../../Store/Reducer/chatReducer";
import ApiService from "../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator'
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { useEffect } from "react";
import CommPanel from "../CommPanel/CommPanel";

function Advisor(props) {
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { advisor } = props;
    const aId = advisor._id;
    const [addingFavorite, setAddingFavorite] = useState(false);
    const isFavoriteAdvisor = useSelector((state) => IsFavoriteAdvisor(state, aId))
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const [availCoupon, setAvailCoupon] = useState(null);

    useEffect(()=>{
        if(isAuthenticated) {
            if(props.availCoupon !== undefined) {   // if props.availCoupon is not undefined, it indicates parent component alreay searched coupons for this advisor
                setAvailCoupon(props.availCoupon);
            } else {
                ApiService.get('/client/coupon/available/' + aId).then(result=>{
                    setAvailCoupon(result.data.coupon);
                })
            }
        }
    }, [props.availCoupon, isAuthenticated])

    const onMainInfoBlockClicked = () => {
        navigate(v_url('/psychic/' + advisor.username.replaceAll(' ', '-')));
    }

    const onAddFavorite = (e) => {
        e.preventDefault();

        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/add', {aId}).then(result => {
            dispatch(addFavoriteAdvisor(aId));
        })
        .catch(error => {

        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const onRemoveFavorite = (e) => {
        e.preventDefault();

        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/remove', {aId}).then(result => {
            dispatch(removeFavoriteAdvisor(aId));
        })
        .catch(error => {

        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const renderNormal = () => {
        return (
            <>
                <div className="main-info d-flex flex-column justify-content-center align-items-begin" onClick={onMainInfoBlockClicked} >
                    <div className="avatar-container position-relative">
                        <div 
                            className="avatar"
                            style={{backgroundImage: `url(${(getAvatarUrl(advisor?.avatar))})`}}
                        />
                        {availCoupon &&
                        <div className="d-flex justify-content-center align-items-center coupon" title={`Free ${availCoupon.availMinutes} mins`}>
                            {availCoupon.availMinutes}
                        </div>
                        }

                        {isAuthenticated && authUser?.role === UserRole.CLIENT &&
                            <div className="advisor-action">
                                { isFavoriteAdvisor && 
                                    <div onClick={onRemoveFavorite} 
                                        className="trash-favorite-btn d-flex justify-content-center align-items-center"
                                        title="Remove from favorite advisor"
                                    >
                                        <MDBIcon fas icon='heart' color="danger" size="lg" />
                                    </div> 
                                }
                                { !isFavoriteAdvisor && 
                                    <div onClick={onAddFavorite} 
                                        className="add-favorite-btn d-flex justify-content-center align-items-center"
                                        title="Add to favorite advisor"
                                    >
                                        <MDBIcon far icon='heart' color='danger' size="lg" />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <div className='px-2 mt-2'>
                        <div className="advisor-name">{advisor?.username}</div>
                        <div className="service-name mt-1">{advisor.service_name}</div>
                    </div>
                </div>
    
                <div className='px-2 mt-2'>
                    <CommPanel advisor={advisor} />
                </div>
    
                <div className="text-gray1 ps-2 fs-90 mt-2">
                    {advisor?.total_chats ?? 0} Readings
                </div>
            </>
        )
    }

    return <>
        <div className="flex-column advisor-preview normal p-1">
            { renderNormal() }
        </div>
    </>
}

export default Advisor;