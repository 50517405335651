import { MDBBtn } from "mdb-react-ui-kit";
import React from "react";
import { useNavigate } from "react-router-dom";
import { v_url } from "../../../Utils/utils";

const AdvisorUnderReview = React.memo(props=>{
    const navigate = useNavigate();

    return (
        <div className="d-flex justify-content-center w-100">
            <div className="advisor-under-review w-90 d-flex flex-column align-items-center">
                <div className="message">
                    <div>
                        Your application is currently under review.
                    </div>
                </div>
                <div>
                    <MDBBtn className="confideas-primary-btn" onClick={(e)=>{navigate(v_url('/'))}}>Go to Home Page</MDBBtn>
                </div>
            </div>
        </div>
    )
});

export default AdvisorUnderReview;