import React, {useEffect, useRef, useState} from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import TextAreaAutoHeight from "../TextAreaAutoHeight/TextAreaAutoHeight";
import ApiService from "../../Core/Service/ApiService";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { memoizedGetChat } from "../../Store/Reducer/chatReducer";
import { ChatStatus } from "../../Constants/constants";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { format_time_mm_ss, getAvatarUrl, validateForm } from "../../Utils/utils";
import Moment from "react-moment";
import EventContext from "../../Context/EventContext";

const AdvisorNoteModal = React.memo((props) => {
    const modalContext = useContext(ModalContext);
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [writingNote, setWritingNote] = useState(false);
    const noteInputRef = useRef(null);
    const open = modalContext.openAdvisorNoteModal;
    const setOpen = modalContext.setOpenAdvisorNoteModal;
    const modalData = modalContext.modalData;
    const [formData, setFormData] = useState({
        cId: '',
        chatId: '',
        note: ''
    })
    const formDataRules = {
        note: {
            required: 'string',
            label: 'Note'
        }
    }
    const [inputErrors, setInputErrors] = useState({});
    const chat = useSelector(memoizedGetChat);
    const eventContext = useContext(EventContext);

    useEffect(()=>{
        if(modalData && open) {
            setLoading(true);
            const data = {
                cId: modalData.client._id,
                chatId: modalData.chat?._id ?? null
            };
            ApiService.post('/advisor/note/show', data).then(result=>{
                setFormData({
                    ...formData,
                    cId: modalData.client._id,
                    chatId: modalData.chat?._id ?? '',
                    note: result.data.note
                })
            })
            .finally(()=>{
                setLoading(false);
            })
        }
    }, [modalData, open])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const closeModal = (e) => {
        e.preventDefault();
        setOpen(false);
    }

    const writeNote = () => {
        const valid_ret = validateForm(formData, formDataRules);
        if(valid_ret.result == true) {
            setInputErrors({});
            setWritingNote(true);
            ApiService.post('/advisor/note', formData).then(result=>{
                eventContext.setEvtAdvisorNoteChanged({
                    time: new Date(),
                    action: 'write'
                })
                setOpen(false);
            })
            .finally(()=>{
                setWritingNote(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
            return;
        }
    }

    if(open) {
        return <MDBModal open={open} setOpen={setOpen} tabIndex='-1' className="edit-note-modal" closeOnEsc={false} staticBackdrop >
        <MDBModalDialog>
            <MDBModalContent>
                <MDBModalHeader>
                    <MDBModalTitle>
                        {modalData.chat ? 'Chat Note' : 'Key Note about Client'}
                    </MDBModalTitle>
                    <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody className="d-flex p-4 flex-column justify-content-center">
                    <div className="w-100 d-flex align-items-center">
                        <label>Client:</label>
                        <div className="ms-2 d-flex align-items-center">
                            <img className="circle-avatar-40" src={getAvatarUrl(modalData.client.avatar)} />
                            <span className="ms-2">{modalData.client.username}</span>
                        </div>
                    </div>

                    <div className="w-100 mt-2">
                        <label>Note: </label>
                        <div>
                            <MDBTextArea
                                name="note" 
                                className='w-100 p-2 note-textarea'
                                rows={5}
                                tabIndex={1}
                                value={formData.note}
                                onChange={handleInputChange}
                                disabled={chat?.isChatting === true}
                            />
                            {inputErrors.note && <div className='error'>{inputErrors.note}</div>}
                        </div>
                    </div>

                    <div className="action d-flex justify-content-end mt-4 w-100">
                        <MDBBtn color="second" onClick={closeModal}>Cancel</MDBBtn>
                        <MDBBtn onClick={writeNote} disabled={writingNote} className="confideas-primary-btn ms-2">
                        {writingNote && <>
                            <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                            <span className="ms-2">Saving</span>
                        </>}
                        {!writingNote && <span>Save</span>}
                        </MDBBtn>
                    </div>

                    {loading && <LoadingIndicator />}
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
        </MDBModal>
    } else {
        return <></>
    }

});

export default AdvisorNoteModal;