import React, { useEffect, useRef, useState } from "react";
import SVG from 'react-inlinesvg';
import { useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { ChatStatus, EDIT_REVIEW_DAYS_OUT, REVIEW_CHAT_DURAION, ReviewType, UserRole } from "../../Constants/constants";
import { addDate, compareDates, estDate, formatSeconds, getAvatarUrl, text2html, v_url, validateForm } from "../../Utils/utils";
import Moment from "react-moment";
import { MDBBtn,MDBIcon, MDBRadio, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import ApiService from "../../Core/Service/ApiService";
import { useNavigate } from "react-router-dom";
import { confirm_alert } from "../ConfirmAlert/ConfirmAlert";
import { setChat } from "../../Core/Service/JwtService";

const ChatMetaInfo = React.memo((props) => {
    const navigate = useNavigate();
    const authUser = useSelector(memoizedGetAuthUser);
    const [partner, setPartner] = useState();
    const [chat, setChat] = useState(props.chat);
    const [reviewFormData, setReviewFormData] = useState({
        reviewContent: props.chat.reviewId?.content,
        reviewType: props.chat.reviewId ? props.chat.reviewId.type : ReviewType.HAPPY
    })
    const reviewFormDataRules = {
        reviewContent: {
            required: 'string',
            label: 'Feedback'
        }
    };
    const [noteFormData, setNoteFormData] = useState({
        note: ''
    })
    const [inputErrors, setInputErrors] = useState({});
    const [savingReview, setSavingReview] = useState(false);
    const [savingNote, setSavingNote] = useState(false);
    const [removingReview, setRemovingReview] = useState(false);
    const [editReview, setEditReview] = useState(false);
    const [editNote, setEditNote] = useState(false);

    useEffect(()=>{
        if(authUser && chat) {
            if(authUser.role === UserRole.CLIENT) setPartner(chat.aId);
            if(authUser.role === UserRole.ADVISOR) setPartner(chat.cId);

            if(chat.advisorNoteId) {
                setNoteFormData({
                    note: chat.advisorNoteId.note
                })
            }
        }
    }, [authUser, chat]);

    const onReviewContentChanged = (e) => {
        setReviewFormData({
            ...reviewFormData,
            reviewContent: e.target.value
        })
    }
    const onReviewTypeChanged = (e) => {
        setReviewFormData({
            ...reviewFormData,
            reviewType: e.target.value
        });
    }
    const handleSaveReview = (e) => {
        const valid_ret = validateForm(reviewFormData, reviewFormDataRules);
        if(valid_ret.result == true) {
            setInputErrors({});
            setSavingReview(true);

            const data = {...reviewFormData};
            let url = '';
            if(chat.reviewId) {
                url = '/client/review/update';
                data._id = chat.reviewId._id;
            } else {
                url = '/client/review/create';
                data.chatId = chat._id;
            };
            ApiService.post(url, data).then((response)=>{
                setChat({
                    ...chat,
                    reviewId: response.data.review
                })
                setEditReview(false);
            })
            .finally(()=>{
                setSavingReview(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    const handleSaveNote = (e) => {
        if(!noteFormData.note) return;
        const data = {
            cId: chat.cId._id,
            chatId: chat._id,
            note: noteFormData.note
        }

        setSavingNote(true);
        ApiService.post('/advisor/note', data).then(result=>{
            setEditNote(false);
        })
        .finally(()=>{
            setSavingNote(false);
        })
    }

    const handleRemoveReview = (e) => {
        confirm_alert({
            message: 'Do you want to remove this review?',
            ok: () => {
                ApiService.delete(`/client/review/${chat.reviewId._id}`).then((response) => {
                    setChat({
                        ...chat,
                        reviewId: null
                    });
                    setReviewFormData({
                        reviewContent: '',
                        reviewType: ReviewType.HAPPY
                    })
                });
            },
        })
    }

    const onNoteChanged = (e) => {
        setNoteFormData({
            ...noteFormData,
            note: e.target.value
        });
    }

    const viewTranscript = (e) => {
        navigate(v_url(`/chat/${chat._id}/messages`));
    }

    const goBack = () => {
        const url = authUser?.role === UserRole.ADVISOR ? 
            v_url(`/advisor/client/detail/${partner?._id}`) : 
            v_url(`/client/advisor/detail/${partner?._id}`);
        navigate(url);
    }

    const renderChatMainInfo = () => {
        return (
        <div className="chat-maininfo d-flex align-items-center px-4 py-3 position-relative">
            <img className="partner-avatar" src={getAvatarUrl(partner?.avatar)} />
            <div className="chat-other-info ms-3">
                <div className="fw-bold" style={{fontSize: '120%'}}>{partner?.username}</div>
                {chat.status >= ChatStatus.ENDED && <>
                    <div>
                        <span style={{color: "green"}}>Live Chat</span>
                        <span className="ms-3"><Moment format="YYYY-MM-DD">{chat.startedAt}</Moment></span>
                    </div>
                    <div className="d-flex flex-column flex-sm-row justify-content-start mt-2">
                        <div className="earning px-4 text-center">$ {authUser.role === UserRole.CLIENT ? chat.amount : chat.ad_earning}</div>
                        <div className="duration ms-sm-2 mt-2 mt-sm-0 px-3 text-center">
                            { formatSeconds(chat.duration, true) }
                        </div>
                    </div>
                </>}
                {chat.status === ChatStatus.MISSED && <div className="comment">
                    Missed Chat
                </div>}
            </div>
        </div>
        )
    }

    const renderReview = () => {
        if(authUser.role === UserRole.CLIENT && editReview) {
            return (
                <div className="review mt-4">
                    <h5>Review:</h5>
                    <div>
                        <MDBTextArea rows={5} 
                            name='reviewContent' 
                            value={reviewFormData.reviewContent}
                            onChange={onReviewContentChanged} 
                        />
                        {inputErrors.reviewContent && <div className='error'>{inputErrors.reviewContent}</div>}
                    </div>
                    <div className="d-flex mt-3 justify-content-begin align-items-center w-100">
                        <div className="d-flex align-items-center">
                            <img 
                                src='/img/icons/png/sad.png' 
                                style={{opacity : reviewFormData.reviewType==ReviewType.UNHAPPY? 1: 0.3, width:30, height: 30}} 
                                className="me-2"
                            />
                            <MDBRadio 
                                name='reviewTypeRadio' 
                                id='unhappy_review' 
                                value={ReviewType.UNHAPPY}
                                onChange={onReviewTypeChanged}
                                checked={reviewFormData.reviewType === ReviewType.UNHAPPY}
                                disabled={authUser?.role != UserRole.CLIENT}
                            />
                        </div>
                        <div className="ms-3">
                            <div className="d-flex align-items-center">
                                <img 
                                    className="me-2"
                                    src='/img/icons/png/smile.png' 
                                    style={{opacity : reviewFormData.reviewType==ReviewType.HAPPY? 1: 0.3, width:30, height: 30}} 
                                />
                                <MDBRadio 
                                    value={ReviewType.HAPPY}
                                    name='reviewTypeRadio' 
                                    checked={reviewFormData.reviewType === ReviewType.HAPPY}
                                    onChange={onReviewTypeChanged}
                                />
                            </div>
                        </div>
                        <div className="flex-fluid d-flex justify-content-end">
                            <MDBBtn className="py-1 px-2" color="default" onClick={() => {setEditReview(false)}}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn className="confideas-primary-btn px-3 py-1 ms-2" onClick={handleSaveReview}>
                                {savingReview && <>
                                    <MDBSpinner size='sm' />
                                    <span className="ms-2">Saving</span>
                                </>}
                                {!savingReview && <span>Save</span>}
                            </MDBBtn>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="review mt-4">
                <h5>
                    <span>Review:</span>
                    { chat.reviewId && 
                        <span>
                            <img 
                                src={`/img/icons/${reviewFormData.reviewType === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`}
                                style={{width:30, height: 30}} 
                                className="ms-3"
                            />
                        </span>
                    }
                </h5>
                <div 
                    className="p-3 py-2" 
                    style={{borderRadius: 5, background: '#eee', minHeight: 100}} 
                    dangerouslySetInnerHTML={{ __html: text2html(reviewFormData.reviewContent)}} 
                />
                
                {authUser.role === UserRole.CLIENT && chat.duration < REVIEW_CHAT_DURAION && <>
                    <div className="comment mt-1">
                        To ensure a meaningful review, chats must be at least 5 minute long before you can leave feedback.
                    </div>
                </>}

                {authUser.role === UserRole.ADVISOR && chat.reviewId?.type === ReviewType.UNHAPPY && (
                    <div className="mt-2">
                        <div className="text-red">Tips for Handling Negative Reviews!!</div>
                        <p className="comment">
                            Please clam down and respond to your client politely. A negative review won't affect your future orders. Even the best advisors receive negative reviews.
                        </p>
                        <p className="comment">
                            As a platform with integrity, Confideas ensures transparency and does not intervene in reviews. However, if you see derogatory language or complaints about technical glitches in client reviews, please contact our support team for assistance.
                        </p>
                    </div>
                )}

                {(()=>{
                    if(authUser.role === UserRole.CLIENT && chat.duration >= REVIEW_CHAT_DURAION) {
                        const expireDate = addDate(new Date(chat.startedAt), EDIT_REVIEW_DAYS_OUT);
                        if(compareDates(expireDate, new Date()) === 1) {
                            return (
                            <div className="mt-2 d-flex align-items-center">
                                <div className="flex-fluid">
                                    <span className="me-1 comment">(You can edit review till</span> 
                                    <Moment format="YYYY-MM-DD" className="fw-bold" style={{fontSize: '80%'}}>{expireDate}</Moment>.
                                    <span className="comment">)</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    {chat?.reviewId && <MDBBtn className="p-1 px-3 me-2" color="danger" onClick={handleRemoveReview}>
                                        {removingReview && (<>
                                            <MDBSpinner size="sm" />
                                            <span className="ms-2">Removing</span>
                                        </>)}
                                        {!removingReview && <span>Remove</span>}
                                    </MDBBtn>}
                                    <MDBBtn className="p-1 px-3" color="warning" onClick={()=>setEditReview(true)}>
                                        Edit
                                    </MDBBtn>
                                </div>
                            </div>
                            )
                        }
                    }
                })()}

            </div>
        )
    }

    const renderChatNote = () => {
        if(authUser?.role === UserRole.ADVISOR) {
            if(editNote) {
                return (
                    <div className="chat-note mt-4">
                        <h5>Chat Note:</h5>
                        <MDBTextArea
                            rows={5}
                            name='note'
                            value={noteFormData.note}
                            onChange={onNoteChanged}
                        />
                        <div className="d-flex justify-content-end mt-2">
                            <MDBBtn className="p-1 px-3" color="default" onClick={()=>setEditNote(false)}>
                                Cancel
                            </MDBBtn>
                            <MDBBtn className="confideas-primary-btn px-3 py-1 ms-2" onClick={handleSaveNote}>
                                {savingNote && <>
                                    <MDBSpinner size='sm' />
                                    <span className="ms-2">Saving</span>
                                </>}
                                {!savingNote && <span>Save</span>}
                            </MDBBtn>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="chat-note mt-4">
                        <h5>Chat Note:</h5>
                        <div 
                            className="p-3 py-2" 
                            style={{borderRadius: 5, background: '#fff2d5', minHeight: 100}} 
                            dangerouslySetInnerHTML={{ __html: text2html(noteFormData.note)}} 
                        />
                        <div className="mt-2 d-flex justify-content-end">
                            <MDBBtn className="p-1 px-3" color="warning" onClick={() => {setEditNote(true)}}>
                                Edit
                            </MDBBtn>
                        </div>
                    </div>                    
                )
            }
        } else {
            return <></>
        }
    }

    if(partner) {
        return (
            <>
                {renderChatMainInfo()}

                <div className="mt-3">
                    <MDBBtn className="w-100" onClick={viewTranscript} style={{background: '#F2F7B7', color: '#333'}}>
                        <span className="me-4">View Transcript</span>
                        <MDBIcon fas icon="chevron-right" />
                    </MDBBtn>
                </div>

                {renderReview()}
                {renderChatNote()}
            </>
        )
    } else {
        return (<></>)
    }

});

export default ChatMetaInfo;