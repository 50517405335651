import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { IsChatSocketConnected, getChatSocket } from "../../Store/Reducer/socketReducer";
import React, { useRef, useEffect, useContext } from "react";
import { ChatStatus, RecvMessages, SendMessages, UserRole } from "../../Constants/constants";
import { MDBBtn, MDBIcon, MDBTextArea } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import { IsChatCreated, memoizedGetChat } from "../../Store/Reducer/chatReducer";
import { useState } from "react";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { getAvatarUrl, text2html } from "../../Utils/utils";
import ChatInput from "./ChatInput";
import ChatContext from "./ChatContext";
import ModalContext from "../../Context/ModalContext";
import DeviceContext from "../../Context/DeviceContext";

function PartnerMessage(props) {
    let {content, time} = props.chat;
    const { sender, showAvatar } = props;

    return (
        <div className="mb-1">
            <div className="one-message partner d-flex px-2">
                <div className="avatar d-none d-lg-block">
                {showAvatar && <img src={getAvatarUrl(sender.avatar)} />}
                </div>

                <div className="content mx-2">
                    <div dangerouslySetInnerHTML={{ __html: text2html(content)}}></div>
                    <div className="d-flex justify-content-end px-2 msg-time">
                        <Moment format='HH:mm'>{time}</Moment>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MyMessage(props) {
    const {content, time, ack} = props.chat;
    const {showAvatar} = props;
    const authUser = useSelector(memoizedGetAuthUser);

    return (
        <div className="d-flex mb-1 justify-content-end">
            <div className="one-message mine d-flex px-2">
                    <div className="content mx-2">
                        <div dangerouslySetInnerHTML={{ __html: text2html(content)}}></div>
                        <div className="d-flex justify-content-end px-2 msg-time">
                            <Moment format='HH:mm' className="mx-1">{time}</Moment>
                            {/* {ack && <span><MDBIcon icon='check'></MDBIcon> </span>} */}
                        </div>
                    </div>
                <div className="avatar d-none d-lg-block">
                {showAvatar && <img src={getAvatarUrl(authUser.avatar)} />}
                </div>
            </div>
        </div>
    )
}

let prevScrollTop = 0;

const ChatInputHistory = React.memo((props) => {
    const chatContext = useContext(ChatContext);
    const deviceContext = useContext(DeviceContext);
    const {sendMessage, onTyping, chatHistory, partnerIsTyping, partnerIsAvailable} = chatContext;
    const [partner, setPartner] = useState({});
    const chatHistoryContainer2Ref = useRef(null);
    const authUser = useSelector(memoizedGetAuthUser);
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const [errorMsg, setErrorMsg] = useState('');
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const [showNewMessageNotify, setShowNewMessageNotify] = useState(false);
    const [lookingUp, setLookingUp] = useState(false);
    const [showScrollUpBtn, setShowScrollUpBtn] = useState(false);
    const [showScrollDownBtn, setShowScrollDownBtn] = useState(false);
    const scrollBottomTimerRef = useRef(null);

    useEffect(()=>{
        const handleResize = () => {
            if(chatHistoryContainer2Ref.current) {
                if(scrollBottomTimerRef.current) {
                    clearTimeout(scrollBottomTimerRef.current);
                    scrollBottomTimerRef.current = null;
                }
    
                scrollBottomTimerRef.current = setTimeout(()=>{
                    scrollDownToBottom();
                    scrollBottomTimerRef.current = null;
                }, 200);
            }
        }

        const resizeObserver = new ResizeObserver(handleResize);
        if(chatHistoryContainer2Ref.current) {
            resizeObserver.observe(chatHistoryContainer2Ref.current);
        }

        return () => {
            if(chatHistoryContainer2Ref.current) {
                resizeObserver.unobserve(chatHistoryContainer2Ref.current);
            }
            resizeObserver.disconnect();
        }
    })

    useEffect(()=>{
        if(authUser && isChatCreated) {
            setPartner(authUser.role === 'client' ? chat.advisor : chat.client);
        }
    }, [authUser, isChatCreated]);

    useEffect(()=>{
        if(chat?.status >= ChatStatus.CONNECTED) {
            if(!isChatSockConnected) {
                setErrorMsg('waiting for network...');
            } else {
                setErrorMsg(partnerIsAvailable ? '' : 'The chatting mate is not active now.');
            }
        } else {
            setErrorMsg('');
        }
    }, [isChatSockConnected, partnerIsAvailable]);

    const onMsgContentKeyDown = (e) => {
        onTyping && onTyping(e);
    }

    const scrollUp = () => {
        setLookingUp(true);
        chatHistoryContainer2Ref.current?.scroll(0, chatHistoryContainer2Ref.current.scrollTop - chatHistoryContainer2Ref.current.clientHeight / 2)
    }
    const scrollDown = () => {
        chatHistoryContainer2Ref.current?.scroll(0, chatHistoryContainer2Ref.current.scrollTop + chatHistoryContainer2Ref.current.clientHeight / 2)
    }
    const scrollDownToBottom = () => {
        chatHistoryContainer2Ref.current?.scroll(0, chatHistoryContainer2Ref.current.scrollHeight);
    }

    const renderScrollUpButton = () => {
        if(!chatHistoryContainer2Ref.current || chatContext.openClientNote) {
            return <></>
        } else {
            if(showScrollDownBtn) {
                return (
                    <div className="scroll-up-btn d-flex justify-content-center align-items-center"> 
                        <MDBBtn tag='a' color="none" onClick={scrollUp}>
                            <MDBIcon fas icon="arrow-up" size='' style={{color: '#367e34'}} />
                        </MDBBtn>
                    </div>
                )
            } else {
                return <></>
            }
        }
    }
    const renderScrollDownButton = () => {
        if(!chatHistoryContainer2Ref.current || chatContext.openClientNote) {
            return <></>;
        } else {
            if(showScrollUpBtn) {
                return (
                    <div className="scroll-down-btn d-flex justify-content-center align-items-center">
                        <MDBBtn tag='a' color="none" onClick={scrollDown}>
                            <MDBIcon fas icon="arrow-down"  size='' style={{color: '#367e34'}} />
                        </MDBBtn>
                    </div>
                )
            } else {
                return <></>
            }
        }
    }

    // useEffect(()=>{
    //     if(chatHistoryContainer2Ref.current) {
    //         if(scrollBottomTimerRef.current) {
    //             clearTimeout(scrollBottomTimerRef.current);
    //             scrollBottomTimerRef.current = null;
    //         }

    //         scrollBottomTimerRef.current = setTimeout(()=>{
    //             scrollDownToBottom();
    //             scrollBottomTimerRef.current = null;
    //         }, 200);
    //     }
    // }, [chatHistory.length])


    let prev_sender = '';
    return (<>
        <div className="message-history-container" 
            style={{top: deviceContext.isMobile ? chatContext.partnerSmTop + 60 : 0, bottom: chatContext.inputBarBottom + 80}}
        >
            <div className="message-history-container2 d-flex flex-column" ref={chatHistoryContainer2Ref}>
                { chatHistory.map((chat, index) => {
                    const showAvatar = chat.sender != prev_sender;
                    prev_sender = chat.sender;
                    if(chat.sender === 'me') {
                        return <MyMessage 
                                    chat={chat} 
                                    key={index} 
                                    showAvatar={showAvatar}/>
                    } else {
                        return <PartnerMessage 
                                    chat={chat} 
                                    sender={partner} 
                                    key={index} 
                                    showAvatar={showAvatar}/>
                    }
                }) }
            </div>
            {partnerIsTyping && <div className="partner-is-typing">{partner.username} is typing</div>}
            {showNewMessageNotify && (
                <MDBBtn tag='a' color="none" className="show-new-message-notify" onClick={scrollDownToBottom}>
                    <span className="me-2">New Message</span>
                    <MDBIcon fas icon='arrow-down' />
                </MDBBtn>
            )}
            {/* {errorMsg != '' && <div className="error-msg text-center w-100">{errorMsg}</div>} */}
        </div>

        <ChatInput  onKeyDown={onMsgContentKeyDown} />
    </>)
});

export default ChatInputHistory;