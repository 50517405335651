import React, { useContext, useState } from 'react';
import SVG from 'react-inlinesvg';
import classname from 'classnames';
import { 
    MDBBtn,
    MDBContainer, 
    MDBIcon, 
    MDBNavbar, 
    MDBNavbarBrand, 
 } from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import ModalContext from '../../Context/ModalContext';
import { v_url } from '../../Utils/utils';

export default function Header() {
    const menuItems = {
        allAdvisors: {
            title: 'Explore Advisors',
            svg_icon: 'house.svg',
            name: 'all_advisors',
            href: v_url('/all_advisors')
        },
        becomeAdvisor: {
            title: 'Become an Advisor', 
            svg_icon: 'user.svg',
            name: 'become_advisor',
            href: v_url('/advisor_auth/signup')
        },
        login: {
            title: 'Log in', 
            svg_icon: 'login.svg', 
            name: 'login',
            onClick: (e) => {
                toggleOpenSignInModal();
            }
        },
        signup: {
            title: 'Register', 
            svg_icon: 'user-add.svg', 
            name: 'signup',
            onClick: (e) => {
                toggleOpenSignUpModal();
            }
        },
        customerSupport: {
            title: 'Customer Support',
            svg_icon: 'customer-service.svg',
            name: 'customer_support',
            onClick: (e) => {
                modalContext.setOpenSendOpinionModal(true);
            }
        },
        info: {
            title: 'About Confideas',
            svg_icon: 'info-circle.svg',
            name: 'info',
            href: v_url('/about_us')
        },
        splitterBar: {
            splitterBar: true
        }
    }
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const location = useLocation();
    const {pathname} = location;
    const [isVerifyEmailPage, setIsVerifyEmailPage] = useState(false);
    const [openDrawerNavbar, setOpenDrawerNavbar] = useState(false);
    const [mobileMenuItems, setMobileMenuItems] = useState([]);
    const [currentMenu, setCurrentMenu] = useState('become_advisor');

    const isCurrentMenu = (menu) => {
        return currentMenu === menu;
    }

    useEffect(()=>{
        if(pathname === v_url('/advisor_auth/signup')) {
            setIsVerifyEmailPage(true);

            const moMenuItems = [];
            moMenuItems.push(menuItems.allAdvisors);
            moMenuItems.push(menuItems.splitterBar);

            moMenuItems.push(menuItems.becomeAdvisor);
            moMenuItems.push(menuItems.info);
            moMenuItems.push(menuItems.splitterBar);

            moMenuItems.push(menuItems.login);
            moMenuItems.push(menuItems.signup);

            setMobileMenuItems(moMenuItems);
        } else {
            setIsVerifyEmailPage(false);
            setMobileMenuItems([]);
        }
    }, [pathname]);

    useEffect(()=>{
        if(openDrawerNavbar) {
            const handleOutsideClick = (e) => {
                if(openDrawerNavbar && !e.target.closest('.drawer-navbar')) {
                    setOpenDrawerNavbar(false);
                }
            }

            document.addEventListener('mousedown', handleOutsideClick);
            return () => document.removeEventListener('mousedown', handleOutsideClick);
        }
    },[openDrawerNavbar])

    const gotoHome = () => {
        if(isVerifyEmailPage) navigate(v_url('/'));
    }
    const gotoAllAdvisors = () => {
        navigate(v_url('/all_advisors'));
    }

    const toggleOpenSignInModal = () => {
        modalContext.setOpenSignUpModal(false);
        modalContext.setOpenSignInModal(true);
    }
    const toggleOpenSignUpModal = () => {
        modalContext.setOpenSignUpModal(true);
        modalContext.setOpenSignInModal(false);
    }

    const renderTopLeftToolbar = () => {
        return ( <div className='d-md-none top-left-toolbar'>
            <div className='d-flex align-items-center to-all-advisors' onClick={gotoAllAdvisors}>
                <MDBIcon fas icon="chevron-left" className='me-1' size='lg' style={{color:'#3a94cb'}} />
                <SVG className="bell-icon" 
                    src='/img/icons/house.svg' 
                    width={25} 
                    height={25} 
                    fill="#3a94cb"
                    title=""
                />
            </div>
        </div>)
    }

    const renderMobileToggleMenu = () => {
        return (
        <div className='d-flex d-md-none justify-content-end align-items-center mobile-top-menu'>
            <MDBBtn 
                style={{backgroundColor:'#e7832b'}} 
                className='toggle-drawmenu' 
                onClick={(e)=>setOpenDrawerNavbar(true)}
            >
                <MDBIcon fas icon="bars" size='lg'/>
            </MDBBtn>
        </div>
    )}

    const renderMobileNavbar = () => {
        return <div className={`d-block d-md-none drawer-navbar ${openDrawerNavbar ? 'open' : ''}`} >
            <ul>
                {mobileMenuItems.map((menu, index)=> {
                    if(menu.splitterBar) {
                        return (
                            <li className='splitter-bar' key={index}></li>
                        )
                    } else {
                        return (
                            <li className={classname({
                                    'active': isCurrentMenu(menu.name),
                                    'cursor-pointer': true, 
                                })} 
                                key={index}
                            >
                                <div onClick={e=>{onMenuClick(e, menu); setOpenDrawerNavbar(false); }} className='d-flex align-items-center'>
                                    <SVG className="bell-icon" 
                                        src={`/img/icons/${menu.svg_icon}`} 
                                        width={25} 
                                        height={25} 
                                        title=""
                                    />
                                    <span className='ms-3'>{menu.title}</span>
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
            <div>
                <p className='contact-info'>
                    <MDBIcon fas icon="envelope" className='me-2' size='md' color='secondary' /> 
                    contact@confideas.com
                </p>
            </div>
        </div>
    }

    const onMenuClick = (e, menu) => {
        if(menu.href) navigate(menu.href);
        if(menu.onClick) menu.onClick(e);
    }

    const renderLogo = () => {
        return (
            <div className={`logo-brand ${isVerifyEmailPage ? 'cursor-pointer' : ''}`} onClick={gotoHome}>
                <img src='/img/logo/logo.svg' className="logo-img" /> 
                <img src='/img/logo/brand.svg' className="logo-brand-img" /> 
            </div>
        )
    }

    return (
        <section className='header-container'>
            <MDBNavbar expand='lg' dark>
                <MDBContainer breakpoint="lg" style={{position: 'relative'}} className='px-3'>
                    {renderLogo()}
                    {/* {isVerifyEmailPage && renderTopLeftToolbar() } */}
                    {isVerifyEmailPage && renderMobileToggleMenu()}
                    {isVerifyEmailPage && renderMobileNavbar()}
                </MDBContainer>
            </MDBNavbar>
        </section>
    )
}

