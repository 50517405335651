import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { 
    MDBSpinner,
    MDBBtn,
    MDBInput,
    MDBContainer
} from "mdb-react-ui-kit";
import styles from './Auth.scss';
import { toast } from 'react-toastify';
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { v_url, validateForm } from "../../../Utils/utils";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../Store/Reducer/authReducer";
import { UserStatus } from "../../../Constants/constants";
import PasswordInput from "../../../Components/PasswordInput/PasswordInput";
import { Helmet } from "react-helmet";

function AdvisorSignup(props) {
    const dispatch = useDispatch()
    const emailInputRef = useRef(null);
    const navigate = useNavigate();
    const [loadingSignupInfo, setLoadingSignupInfo] = useState(false);
    const [signupInfos, setSignupInfos] = useState([]);
    const [signingup, setSigningup] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        checkEmail: '',
        password: '',
        checkPassword: ''
    });
    const rules = {
        email: {
            required: 'email'
        },
        checkEmail: {
            required: 'email',
            equal: 'email',
            label: 'Confirming email',
            messages: {
                'equal': 'Inconsistent email'
            }
        },
        password: {
            required: 'string',
            minlength: 6
        },
        checkPassword: {
            required: 'string',
            minlength: 6,
            label: 'Confirming password',
            equal: 'password',
            messages: {
                'equal': 'Inconsistent Password'
            }
        }
    }
    const [inputErrors, setInputErrors] = useState({});

    useEffect(()=>{
        emailInputRef.current?.focus();
        setLoadingSignupInfo(true);

        ApiService.get('/advisor/auth/signupinfo').then(result => {
            setSignupInfos(result.data.signupInfos);
        })
        .catch(error => {

        })
        .finally(()=>{
            setLoadingSignupInfo(false);
        })

    }, []);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const handleSignup = (e) => {
        e.preventDefault();

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});
            setSigningup(true);

            const data = {
                email: formData.email,
                password: formData.password
            }
            ApiService.post('/advisor/auth/verifyemail', data).then(result => {
                dispatch(setAuth({
                    authUser: result.data.authUser
                }));
                toast.success("Email registered with success");
                navigate(v_url('/advisor_auth/signup-step'));
            })
            .catch(err => {

            })
            .finally(()=>{
                setSigningup(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    return <>
        <Helmet>
            <link rel="canonical" href={`https://confideas.com/v0.28/advisor_auth/signup`} />
            <title>
                Sign up today to become an advisor!
            </title>
            <meta name="keywords" content="online psychic job,
                online tarot reading job,
                online energy healing job,
                online consultant job,
                become an advisor,
                pay per minute job," 
            />
            <meta name="description" content="Sign up today to become an advisor! Get paid per minute for live chat consultations in your areas of expertise. Work from anywhere and start your online business!" />
        </Helmet>
        <MDBContainer breakpoint="lg" className="d-flex justify-content-center align-items-center ad-auth-signup">
            <div className="unlock-opportunity p-4">
                {signupInfos.map((signupInfo, index) => <div key={index} >
                    <div className="d-flex flex-column align-items-center">
                        <h2>Become an Advisor</h2>
                        <p className="" style={{color: "#a13346"}}>Do you want to achieve a work-life balance and travel around the world? Get paid per minute by offering online live chat services in your area of expertise!</p>
                    </div>
                    <ul>
                        {signupInfo.titles.map((title, index) => (
                            <li className="d-flex mt-4" key={index}>
                                <div className="check-img">
                                    <img src='/img/icons/png/check.png' alt=''/>
                                </div>
                                { title }
                            </li>
                        ))}
                    </ul>
                </div>
                )}
            </div>
            
            <div className="signup-form m-4">
                <form className='d-flex flex-column' onSubmit={handleSignup} >
                    <div className=''>
                        <h5 className="text-center">Welcome to Confideas</h5>
                        <p className="comment">
                            It will take a few minutes to fill out your advisor profile listing and contact information.
                            Once selected as an advisor, we will ask you to provide your government-issued photo ID.
                        </p>
                    </div>
                    <div className='mt-4'>
                        <MDBInput   type='email' 
                                    ref={emailInputRef}
                                    label="Email" 
                                    name='email' 
                                    tabIndex='1'
                                    value={formData.email} 
                                    onChange={handleInputChange} />
                        {inputErrors.email && <div className="error">{ inputErrors.email }</div> }
                    </div>
                    <div className='mt-4'>
                        <MDBInput   type='email' 
                                    label="Confirm Email" 
                                    name='checkEmail' 
                                    tabIndex='2'
                                    value={formData.checkEmail} 
                                    onChange={handleInputChange} />
                        {inputErrors.checkEmail && <div className="error">{ inputErrors.checkEmail }</div> }
                    </div>
                    <div className='mt-4'>
                        <PasswordInput
                                    label="Password" 
                                    name='password' 
                                    tabIndex='3'
                                    value={formData.password} 
                                    handleInputChange={handleInputChange}
                                    error={inputErrors.password}
                        />
                    </div>
                    <div className='mt-4'>
                        <PasswordInput 
                                    label="Confirm Password" 
                                    name='checkPassword' 
                                    tabIndex='4'
                                    value={formData.checkPassword} 
                                    handleInputChange={handleInputChange}
                                    error={inputErrors.checkPassword}
                        />
                    </div>
                    <div className='mt-4'>
                        <MDBBtn 
                            tabIndex='4'
                            style={{
                                backgroundColor: 'rgb(251, 233, 83)',
                                color: '#111',
                                width: '100%'
                            }}
                            type='submit'
                            disabled={signingup}
                        >
                            {(() => {
                                if(signingup) {
                                    return <>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                        Submitting
                                    </>
                                } else  {
                                    return <>
                                        Submit
                                    </>
                                }
                            })()}
                        </MDBBtn>
                    </div>
                </form>
            </div>
            
            {loadingSignupInfo && <LoadingIndicator />}
        </MDBContainer>
    </>
}

export default AdvisorSignup;