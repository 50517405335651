import React, { useState, useEffect} from "react";
import { toast } from 'react-toastify';
import { validateForm } from '../../../Utils/utils';
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
    MDBSpinner,
    MDBModalFooter,
    MDBModalHeader,
    MDBInput,
    MDBCheckbox,
} from "mdb-react-ui-kit";
import ApiService from "../../../Core/Service/ApiService";

const AdminTrackWordEditModal = React.memo((props)=>{
    const [formData, setFormData] = useState({
        _id: '',
        pattern: '',
        comment: '',
        status: false
    });
    const rules = {
        pattern:{ required: 'string'}
    };
    const [inputErrors, setInputErrors] = useState({});
    const [saving, setSaving] = useState(false);

    useEffect(()=>{
        if(props.open) {
            if(props.trackWord) {
                setFormData({...props.trackWord});
            } else {
                    setFormData({
                    _id: '',
                    pattern: '',
                    comment: ''
                });
            }
        }
    }, [props.trackWord, props.open]);

    const closeModal = () => {
        props.setOpen(false);
    }

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleCheckbox = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked
        })
    }

    const handleSave = () => {
        const valid_ret = validateForm(formData, rules);
        if(!valid_ret.result) {
            setInputErrors(valid_ret.errors);
            return;
        } else {
            setInputErrors({});
            setSaving(true);

            const url = `/admin/trackword/${formData._id ? 'update' : 'create'}`;
            ApiService.post(url, formData).then(response=>{
                closeModal();
                props.onUpserted && props.onUpserted();
            })
            .finally(()=>{
                setSaving(false);
            });
        }
    }

    return (
        <MDBModal
            open={props.open} 
            setOpen={props.setOpen} 
            closeOnEsc={false} 
            staticBackdrop 
            tabIndex='-1'
            appendToBody={true}
        >
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <div className="text-center w-100">{props._id ? 'Edit ' : 'Add '} Track Word</div>
                    </MDBModalHeader>
                    <MDBModalBody>
                        <div>
                            <label className="required">Pattern:</label>
                            <MDBInput name='pattern' value={formData.pattern} onChange={handleInput} />
                            {inputErrors?.pattern && <div className="error">{inputErrors.pattern}</div>}
                        </div>
                        <div className="mt-2">
                            <label>Comment11: </label>
                            <MDBInput name='comment' value={formData.comment} onChange={handleInput} />
                            {inputErrors?.comment && <div className="error">{inputErrors.comment}</div>}
                        </div>
                        <div className="mt-3 d-flex align-items-center">
                            <MDBCheckbox name='status' checked={formData.status} onChange={handleCheckbox} />
                            <label>Detect this pattern</label>
                        </div>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <div className="d-flex justify-content-center">
                            <MDBBtn color="default" onClick={closeModal}>Close</MDBBtn>
                            <MDBBtn className="confideas-primary-btn ms-2" onClick={handleSave} disabled={saving}>
                                {saving && <MDBSpinner role='status' size='sm me-2' />}
                                <span>Save</span>
                            </MDBBtn>
                        </div>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
});

export default AdminTrackWordEditModal;