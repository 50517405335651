import React from "react";
import styles from './Setting.scss'
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBCardTitle, MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { useState } from "react";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { toast } from "react-toastify";
import { RateLimit, SettingFields } from "../../../Constants/constants";

const AdminSetting = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        [SettingFields.CHAT_RATE_MIN]: RateLimit.CHAT_MIN,
        [SettingFields.CHAT_RATE_MAX]: RateLimit.CHAT_MAX,
        [SettingFields.BASIC_COMMISSION]: 60,
        [SettingFields.ADVISOR_TOP_nTH]: 20,
        [SettingFields.ADVISOR_TOP_nTH_COMMISSION]: 65,
        [SettingFields.CHAT_MIN_TIME]: 5
    })

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const saveSetting = (e) => {
        e.preventDefault();

        setLoading(true);

        ApiService.post('/admin/setting/save', {setting: formData}).then(resutl=>{
            toast.success('Saved successfully');
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    useEffect(()=>{
        setLoading(true);

        ApiService.get('/admin/setting/index').then(result=>{
            setFormData({
                [SettingFields.CHAT_RATE_MIN]: result.data[SettingFields.CHAT_RATE_MIN] ?? formData[[SettingFields.CHAT_RATE_MIN]],
                [SettingFields.CHAT_RATE_MAX]: result.data[SettingFields.CHAT_RATE_MAX] ?? formData[[SettingFields.CHAT_RATE_MAX]],
                [SettingFields.BASIC_COMMISSION]: result.data[SettingFields.BASIC_COMMISSION] ?? formData[SettingFields.BASIC_COMMISSION],
                [SettingFields.ADVISOR_TOP_nTH]: result.data[SettingFields.ADVISOR_TOP_nTH] ?? formData[SettingFields.ADVISOR_TOP_nTH],
                [SettingFields.ADVISOR_TOP_nTH_COMMISSION]: result.data[SettingFields.ADVISOR_TOP_nTH_COMMISSION] ?? formData[SettingFields.ADVISOR_TOP_nTH_COMMISSION],
                [SettingFields.CHAT_MIN_TIME]: result.data[SettingFields.CHAT_MIN_TIME] ?? formData[SettingFields.CHAT_MIN_TIME],
            });
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const renderAdvisorSetting = () => {
        return (
            <div className="advisor-setting mb-4">
            <MDBCard>
                <MDBCardHeader>
                    <MDBCardTitle>Advisor Setting</MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                    <div className="d-flex align-items-center">
                        <label className="me-2">Chat Rate Limit : </label>
                        <div className="d-flex align-items-center">
                            <div style={{width: 150}}>
                                <MDBInput   type="number" 
                                            label='minimum value'
                                            className="text-center" 
                                            name="chat_rate_min" 
                                            value={formData.chat_rate_min} 
                                            onChange={ handleInputChange } />
                            </div>
                            <span className="mx-2">~</span>
                            <div style={{width: 150}} className="d-flex align-items-center">
                                <MDBInput   type="number" 
                                            label='maximum value' 
                                            name="chat_rate_max" 
                                            className="text-center" 
                                            value={formData.chat_rate_max} 
                                            onChange={ handleInputChange } />
                                <span className="ps-1">($)</span>
                            </div>
                        </div>
                    </div>

                    <div className="basic-commission d-flex align-items-center mt-4">
                        <label className="me-2">Basic Commission : </label>
                        <div className="d-flex align-items-center" style={{width:150}}>
                            <MDBInput   type='number' 
                                        name='basic_commission' 
                                        className="text-center" 
                                        value={formData.basic_commission} 
                                        onChange={handleInputChange} />
                        </div>
                        <span className="ps-1">(%)</span>
                    </div>

                    <div className="first-nth-advisor-commission mt-4">
                        <div className=" d-flex align-items-center">
                            <label className="me-2">First </label>
                            <div style={{width: 100}}>
                                <MDBInput   type='number'
                                            name='top_nth' 
                                            className="text-center" 
                                            value={formData.top_nth} 
                                            onChange={handleInputChange} />
                            </div>
                            <span className="mx-2">th Advisor{`'`} Commission : </span>

                            <div style={{width: 100}} className="d-flex align-items-center">
                                <MDBInput type='number'
                                            name='top_nth_commission'
                                            className="text-center" 
                                            value={formData.top_nth_commission}
                                            onChange={handleInputChange} />
                            </div>
                            <span className="ms-2">(%)</span>
                        </div>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </div>
        )
    }

    const renderChatSetting = () => {
        return (
        <div className="chat-setting mb-4">
            <MDBCard>
                <MDBCardHeader>
                    <MDBCardTitle>Chat Setting</MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody style={{minHeight: 200}}>
                    <div className="d-flex align-items-center">
                        <label className="me-2">Chat Minimum Time : </label>
                        <div className="d-flex align-items-center" style={{width:150}}>
                            <MDBInput   type='number' 
                                        name='chat_min_time' 
                                        className="text-center" 
                                        value={formData.chat_min_time ?? 5} 
                                        onChange={handleInputChange} />
                        </div>
                        <span className="ps-1">min</span>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </div>
        )
    }

    const breadcrumb=[{title: 'Setting'}, {title: 'Price'}];
    return (<section className="setting">
        <Breadcrumb breadcrumb={breadcrumb} />
        <MDBRow>
            <MDBCol col={12} md={6}>
                { renderAdvisorSetting() }
            </MDBCol>
            <MDBCol col={12} md={6}>
                { renderChatSetting() }
            </MDBCol>
        </MDBRow>

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={saveSetting}>Save</MDBBtn>
        </div>

        {loading && <LoadingIndicator />}
    </section>)
});

export default AdminSetting;