import { Outlet } from "react-router";
import { Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { UserStatus } from "../../Constants/constants";
import { v_url } from "../../Utils/utils";

const useAdvisorAuth = () => {
  const isAuthenticated = useSelector(IsAuthenticated);
  const authUser = useSelector(memoizedGetAuthUser);

  return isAuthenticated && authUser?.role === 'advisor';
}

const AdvisorProtectedRoutes = () => {
  const isAuth = useAdvisorAuth();
  const authUser = useSelector(memoizedGetAuthUser);
  
  if(!isAuth) return <Navigate replace to={v_url("/login")} />;
  if(authUser.status === UserStatus.EMAIL_VERIFIED) return <Navigate replace to={v_url("/advisor_auth/signup-step")} />;
  if(authUser.status === UserStatus.WAITING) return <Navigate replace to={v_url("/advisor/auth/undereview")} />
  return <Outlet />;
}

export default AdvisorProtectedRoutes

