import React, { useContext } from "react";
import { estDate, format_time_mm_ss, getAvatarUrl } from "../../Utils/utils";
import Moment from "react-moment";
import { MDBBtn, MDBCard, MDBCardBody, MDBIcon } from "mdb-react-ui-kit";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { ChatStatus, ReviewType, UserRole } from "../../Constants/constants";
import Advisor from '../../Components/Advisor/Advisor'
import { useNavigate } from "react-router-dom";
import ModalContext from "../../Context/ModalContext";
import { getPreviousUrl, popHistory } from "../../Store/Reducer/urlHistoryReducer";

const ChatMetaInfo = React.memo((props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector(memoizedGetAuthUser);
    const {chat} = props;
    const partner = (authUser._id === chat.cId._id) ? chat.aId : chat.cId;
    const modalContext = useContext(ModalContext);
    const previousUrl = useSelector(getPreviousUrl);

    const goBack = () => {
        if(previousUrl) {
            const url = previousUrl;
            dispatch(popHistory());
            navigate(url)
        }
    }

    const onShowReview = () => {
        modalContext.setModalData({chat});
        modalContext.setOpenEditReviewModal(true);
    }

    const onEditNoteClicked = () => {
        modalContext.setModalData({
            client: chat.cId,
            chat: chat
        });
        modalContext.setOpenAdvisorNoteModal(true);
    }

    return <div className="chat-metainfo d-none d-md-flex flex-column align-items-center justify-content-center ">
        <div style={{color: "purple"}} className="m-2">
            <h5><Moment format='YYYY-MM-DD HH:mm'>{chat.startedAt}</Moment></h5>
        </div>

        {partner.role === UserRole.ADVISOR && <div className="w-100"><Advisor advisor={partner}/></div> }
        {partner.role === UserRole.CLIENT &&
            <div className="chat-partner d-flex flex-column align-items-center py-3">
                <img src={getAvatarUrl(partner.avatar)} />
                <span>{ partner.username }</span>
            </div>
        }

        {chat.status >= ChatStatus.ENDED && <>
            <div className="other-infos w-100 mt-4 d-flex justify-content-center">
                <div>
                    <div className="chat-duration d-flex">
                        <div className="label">Duration</div>
                        <div className="mx-2">:</div>
                        <div className="other-info-value">{format_time_mm_ss(chat.duration)}</div>
                    </div>

                    <div className="rate mt-2 d-flex">
                        <div className="label">Rate</div>
                        <div className="mx-2">:</div>
                        <div className="other-info-value">$ {chat.rate_per_min_chat} / min</div>
                    </div>

                    <div className="rate mt-2 d-flex">
                        <div className="label">Amount</div>
                        <div className="mx-2">:</div>
                        <div className="other-info-value">$ {chat.amount}</div>
                    </div>

                    {authUser.role === UserRole.ADVISOR && <>
                    <div className="amount mt-2 d-flex">
                        <div className="label">My Share</div>
                        <div className="mx-2">:</div>
                        <div className="other-info-value">{chat.commission}%</div>
                    </div>
                    <div className="amount mt-2 d-flex">
                        <div className="label">My Earning</div>
                        <div className="mx-2">:</div>
                        <div className="other-info-value">$ {chat.ad_earning}</div>
                    </div>
                    {chat.reviewId && (
                        <div className="review mt-2 d-flex">
                            <div className="label">Review</div>
                            <div className="mx-2">:</div>
                            <div className="other-info-value">
                                <MDBBtn tag="a" color="none" onClick={e=>onShowReview()}>
                                    <img src={`/img/icons/${chat.reviewId.type === ReviewType.HAPPY ? 'png/smile.png' : 'png/sad.png'}`} 
                                        style={{width:30}} 
                                        className="cursor-pointer"
                                    />
                                </MDBBtn>
                            </div>
                        </div>
                    )}
                    </>}
                </div>
            </div>
        </>}
        {chat.status === ChatStatus.MISSED && <>
            <div className="comment mt-2">( Missed Chat )</div>
        </>}

        {authUser?.role === UserRole.ADVISOR && (
            <div className="mt-4 w-100">
                <MDBBtn className="w-100 d-flex align-items-center justify-content-center" color="default" onClick={onEditNoteClicked}>
                    <MDBIcon far icon="edit" size="lg" />
                    <span className="ms-2">Edit Note</span>
                </MDBBtn>
            </div>
        )}

        <div className="w-100 mt-4">
            <MDBBtn className="w-100" color="default" onClick={goBack}>Go Back</MDBBtn>
        </div>
    </div>
});

export default ChatMetaInfo;