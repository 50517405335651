import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import React, { useEffect, useState, useRef } from "react";
import { getAvatarUrl, v_url } from "../../../Utils/utils";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";

const ClientAdvisorStatistics = React.memo(props => {
    const { adStatistics } = props;
    const navigate = useNavigate();

    const viewAdvisorDetail = () => {
        navigate(v_url(`/advisor/${adStatistics?.advisor._id}`));
    }

    return (
        <MDBCard>
            <MDBCardBody>
                {adStatistics && (
                <div className="d-flex align-items-center p-1">
                    <img src={getAvatarUrl(adStatistics?.advisor.avatar)} 
                        className="circle-avatar-100 cursor-pointer" 
                        style={{border: '3px solid gold'}}
                        onClick={viewAdvisorDetail}
                    />
                    <div className="ms-2">
                        <div className="text-purple fw-bold cursor-pointer" onClick={viewAdvisorDetail}>
                            {adStatistics.advisor.username}
                        </div>
                        <div>
                            <label>Total Chats: </label>
                            <span className="ms-2 fw-bold text-gray555">{adStatistics.chat_count}</span>
                        </div>
                        <div>
                            <label>Last Chat:</label>
                            <span className="ms-2 fw-bold text-gray555"><Moment format="YYYY-MM-DD">{adStatistics.last_chat.createdAt}</Moment></span>
                        </div>
                    </div>
                </div>
                )}
            </MDBCardBody>
        </MDBCard>
    )
});

export default ClientAdvisorStatistics;