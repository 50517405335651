import { createSelector, createSlice } from '@reduxjs/toolkit'
import JwtService from '../../Core/Service/JwtService';
import ApiService from '../../Core/Service/ApiService';
import {jwtDecode} from 'jwt-decode';
import { floatV } from '../../Utils/utils';

const now = new Date().getTime();
let advisorServiceStatus = {
    isAvailableChat: false,
    isAvailableCall: false,
    isAvailableVideo: false,
};

let authToken = JwtService.getAuthToken();
let authUser = JwtService.getAuthUser();
advisorServiceStatus = JwtService.getAdvisorServiceStatus()

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuthenticated: !!authToken,
        authUser: authUser,
        authToken: authToken,
        advisorServiceStatus: advisorServiceStatus
    },
    reducers: {
        setAuth: (state, action) => {
            console.log('setAuth: authUser=', action.payload.authUser);

            state.authUser = {
                ...action.payload.authUser,
                balance: floatV(action.payload.authUser.balance)
            };
            JwtService.setAuthUser(action.payload.authUser);

            if(action.payload.authToken) {
                state.authToken = action.payload.authToken;
                JwtService.setAuthToken(action.payload.authToken);
    
                state.isAuthenticated = state.authToken ? true : false;
            }
        },
        updateAuth: (state, action) => {
            console.log('updateAuth', action.payload);

            state.authUser = {
                ...state.authUser,
                ...action.payload
            }
            state.authUser = {
                ...state.authUser,
                balance: floatV(state.authUser.balance)
            }
            JwtService.setAuthUser(state.authUser);
        },
        updateAuthToken: (state, action) => {
            console.log('updateAuthToken', action.payload);

            state.authToken = action.payload;
            JwtService.setAuthToken(action.payload);
            // ApiService.setHeader(action.payload);
        },
        purgeAuth: (state) => {
            console.log('purgeAuth:');

            state.isAuthenticated = false;
            state.authUser = null;
            state.authToken = '';
            state.advisorServiceStatus = {
                isAvailableChat: false,
                isAvailableCall: false,
                isAvailableVideo: false,
            };
            JwtService.destroyAuthToken();
            JwtService.destroyAuthUser();
            JwtService.destroyChat();
            JwtService.destroyAdvisorServiceStatus();
            JwtService.destroyPaymentRequest();
            JwtService.destroyAlarms();
            // ApiService.setHeader('');
        },
        setAdvisorServiceStatus: (state, action) => {
            state.advisorServiceStatus = action.payload;
            JwtService.setAdvisorServiceStatus(action.payload);
        },
        addFavoriteAdvisor: (state, action) => {
            console.log('addFavoriteAdvisor');
            const index = state.authUser.favorite_advisors.findIndex(faId=>faId === action.payload);
            if(index < 0) {
                state.authUser.favorite_advisors.push(action.payload);
                JwtService.setAuthUser(state.authUser);
            }
        },
        removeFavoriteAdvisor: (state, action) => {
            console.log('removeFavoriteAdvisor');
            const index = state.authUser.favorite_advisors.findIndex(faId=>faId === action.payload);
            if(index >= 0) {
                state.authUser.favorite_advisors.splice(index, 1);
                JwtService.setAuthUser(state.authUser);
            }
        }
    }
})

export const { 
    setAuth, 
    updateAuth,
    updateAuthToken,
    purgeAuth, 
    setAdvisorServiceStatus, 
    addFavoriteAdvisor, 
    removeFavoriteAdvisor,
} = authSlice.actions

export const IsAuthenticated = (state) => state.auth.isAuthenticated;
export const getAuthUser = (state) => state.auth.authUser;
export const getAuthToken = (state) => state.auth.authToken;
export const getAdvisorServiceStatus = (state) => state.auth.advisorServiceStatus;
export const IsFavoriteAdvisor = (state, aId) => {
    const index = state.auth.authUser?.favorite_advisors?.findIndex(f_aId => f_aId=== aId);
    return (index >= 0);
}

// Create a memoized selector using reselect
export const memoizedGetAuthUser = createSelector(
    getAuthUser,
    (authUser) => authUser
);

export default authSlice.reducer;