import React, {useState, useEffect, useContext, useMemo} from "react";
import ReactHowler from 'react-howler';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addDate, getAvatarUrl, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import Select from "react-select";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBInput,
    MDBBtn,
    MDBModalHeader,
    MDBModalTitle,
    MDBSpinner,
    MDBRange,
    MDBIcon,
} from "mdb-react-ui-kit";
import ModalContext from "../../Context/ModalContext";
import TextAreaAutoHeight from "../TextAreaAutoHeight/TextAreaAutoHeight";
import Moment from "react-moment";
import { memoizedGetAuthUser, updateAuth } from "../../Store/Reducer/authReducer";

const SendBuzzModal = React.memo((props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const open = modalContext.openSendBuzzModal;
    const setOpen = modalContext.setOpenSendBuzzModal;
    const [sending, setSending] = useState(false);
    const modalData = modalContext.modalData;
    const [inputErrors, setInputErrors] = useState({});
    const [formData, setFormData] = useState({
        aId: '',
        message: ''
    });
    const rules = {};

    useEffect(()=>{
        if(modalData && open) {
            setFormData({
                aId: modalData.advisor._id,
                message: ''
            })
            setInputErrors({});
        }
    }, [modalData, open])

    const closeModal = () => {
        setOpen(false);
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const sendMessage = (e) => {
        e.preventDefault();
        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});
            setSending(true);
            
            ApiService.post('/client/buzz/create', formData).then(result => {
                toast.success("The message has been sent successfully");

                // To add the advisor to the favorite list
                const aIndex = authUser.favorite_advisors.findIndex(fa_id => fa_id === formData.aId);
                if(aIndex < 0) {
                    dispatch(updateAuth({
                        favorite_advisors: [
                            ...authUser.favorite_advisors,
                            formData.aId
                        ]
                    }))
                }

                closeModal();
            })
            .finally(()=>{
                setSending(false);
            });
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    if(open) {
        return (
            <MDBModal   className="buzz-modal" 
                        open={open} 
                        setOpen={setOpen} 
                        closeOnEsc={false}
                        staticBackdrop 
                        tabIndex='-1' >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader className="d-flex w-100">
                            <MDBModalTitle className="flex-fluid d-flex justify-content-center align-items-center">Request Chat</MDBModalTitle>
                            <MDBBtn tabIndex='-1' className='btn-close' color='none' onClick={closeModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody className='d-flex p-4 flex-column justify-content-center align-items-center'>
                            <div className="advisor-name w-100 d-flex align-items-center">
                                <label>Advisor: </label>
                                <div className="ms-2">
                                    <img className="circle-avatar-40" src={getAvatarUrl(modalData?.advisor.avatar)} />
                                    <span className="ms-2">{modalData?.advisor.username}</span>
                                </div>
                            </div>
                            <div className="message mt-3 w-100">
                                <label>Message: </label>
                                <div className="value">
                                    <TextAreaAutoHeight 
                                            className='w-100 p-2'
                                            name='message'
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            tabIndex={2}
                                    />
                                    {inputErrors.message && <div className="error">{ inputErrors.message }</div>}
                                </div>
                            </div>
                            <div className="action d-flex justify-content-end align-items-center mt-4 ">
                                <MDBBtn className="me-2" color="second" onClick={closeModal} disabled={sending}>Cancel</MDBBtn>
                                <MDBBtn className="confideas-primary-btn" onClick={sendMessage} disabled={sending}>
                                    {sending && <>
                                        <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                                        Sending
                                    </>}
                                    {!sending && <span>Send</span>}
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        )
    } else {
        return <></>
    }
});

export default SendBuzzModal;