import React, {useContext, useEffect, useRef} from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCheckbox, MDBIcon, MDBInput, MDBSpinner } from 'mdb-react-ui-kit';
import { useState } from 'react';
import ApiService from '../../../Core/Service/ApiService';
import DeviceContext from '../../../Context/DeviceContext';

const firebaseConfig = {
    apiKey: "AIzaSyCHbw1RSF4aKEFYCZAcZjXHkjLYXcLsn6o",
    authDomain: "confideas-2ee27.firebaseapp.com",
    databaseURL: "https://confideas-2ee27-default-rtdb.firebaseio.com",
    projectId: "confideas-2ee27",
    storageBucket: "confideas-2ee27.appspot.com",
    messagingSenderId: "723847871474",
    appId: "1:723847871474:web:2d2419f642f2a3193bc96b",
    measurementId: "G-SJ0GBLV0FW"
};

const FCMService = React.memo(({name}) => {
    const [switchingService, setSwitchingService] = useState(false);
    const [loading, setLoading] = useState(false);
    const [serviceStatus, setServiceStatus] = useState(false);
    const [fcmToken, setFcmToken] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const deviceContext = useContext(DeviceContext);

    const firebaseApp = initializeApp(firebaseConfig);
    const messaging = getMessaging(firebaseApp);

    console.log('calling fcm_service: name=' + name);

    onMessage(messaging, (payload) => {
        console.log('Message Received from FCM', payload);
    })

    useEffect(() => {
        checkFcmStatus();
    }, []);

    const getFcmToken = async () => {
        try {
            // To ask user's permission
            const permission = await Notification.requestPermission();
            if(permission == 'denied') {
                throw new Error('The User explicitly denied the permission for notification.')
            } else if(permission == 'granted') {
                console.log('user permitted.')
            
                // To get FCM token.
                console.log('VAPIDKEY=' + process.env.REACT_APP_FCM_VAPIDKEY);
                const token = await getToken(messaging, {vapidKey: process.env.REACT_APP_FCM_VAPIDKEY});
                setFcmToken(token);
                console.log('current token = ' + token);
    
                // To check fcm status
                const data = {
                    isMobile: deviceContext.isMobile,
                    fcmToken: token
                }
                const result = await ApiService.post('/advisor/fcm/show', data);
                setServiceStatus(result.data.fcmStatus);
            }
        }catch(error) {
            if(error.message.indexOf("Failed to execute 'subscribe' on 'PushManager'") >= 0) {
                window.location.reload();
                return;
            }
            throw error;
        }
    }

    const registerSW = async (callback) => {
        try {
            // To register serviceWorker
            const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
            console.log('ServiceWorker registered: ', registration);
            
            let serviceWorker = null;
            if(registration.installing) serviceWorker = registration.installing;
            if(registration.waiting) serviceWorker = registration.waiting;
            if(registration.active) serviceWorker = registration.active;
            if(serviceWorker) {
                console.log("sw current state", serviceWorker.state);
                if(serviceWorker.state === 'activated') {
                    callback();
                } else {
                    serviceWorker.addEventListener("statechange", function(e) {
                        console.log("sw statechange : ", e.target.state);
                        if (e.target.state == "activated") {
                            console.log("Just now activated. now we can subscribe for push notification")
                            callback();
                        }
                    });
                }
            }
        } catch(error) {
            throw error;
        }
    }

    const checkFcmStatus = async () => {
        if ('serviceWorker' in navigator) {
            try {
                setLoading(true);
                await registerSW(async () => {
                    await getFcmToken();
                });
            } catch(error) {
                console.error(error);
                setErrorMsg(error.message);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        } else {
            setErrorMsg("This browser does not support ServiceWorker!")
        }
    }

    const onServiceSwitched = (e) => {
        setSwitchingService(true);

        const data = {
            status: e.target.checked,
            fcmToken
        }
        ApiService.post('/advisor/fcm/update', data).then(result=>{
            setServiceStatus(e.target.checked);
        })
        .catch(error=>{
            setServiceStatus(!e.target.checked);
        })
        .finally(()=>{
            setSwitchingService(false);
        })
    }

    return <MDBCard className='fcm-service-card mb-4'>
        <MDBCardHeader>
            <div className='d-flex align-items-center'>
                <MDBIcon fas icon="bell" size='2x' color='info'/>
                <span className='ms-2'><strong>Notification Service</strong></span>
            </div>
        </MDBCardHeader>
        <MDBCardBody>
            <p className='service-description'>
                Using this service, you can get notification upon receiving call from clients, even though you are away from this site or even close web browser.
            </p>

            {fcmToken && 
            <div>
                {switchingService && <MDBSpinner size='sm' status='role' />}
                {!switchingService && <>
                    <MDBCheckbox name='service_status' 
                        checked={serviceStatus} 
                        label='Enable Service' 
                        onChange={onServiceSwitched} 
                    />
                </>}
            </div>}

            {/* {errorMsg && <div>
                <p className='error'>( {errorMsg} )</p>
            </div>} */}
        </MDBCardBody>
    </MDBCard>
    
});

export default FCMService;

