import React, {useState , useEffect} from "react";
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';
import styels from './show.scss';
import { MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBSpinner } from "mdb-react-ui-kit";
import { getAvatarUrl, isMobileScreen, v_url } from "../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { IsAuthenticated, IsFavoriteAdvisor, addFavoriteAdvisor, memoizedGetAuthUser, removeFavoriteAdvisor } from "../../../Store/Reducer/authReducer";
import { CategoryTags, UserRole } from "../../../Constants/constants";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import ApiService from "../../../Core/Service/ApiService";
import JwtService from "../../../Core/Service/JwtService";
import { useNavigate } from "react-router-dom";
import CommPanel from "../../../Components/CommPanel/CommPanel";

const MainInfo = (props) => {
    const [advisor, setAdvisor] = useState(props.advisor);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);
    const [addingFavorite, setAddingFavorite] = useState(false);
    const isFavoriteAdvisor = useSelector((state) => IsFavoriteAdvisor(state, advisor._id));
    const [availCoupon, setAvailCoupon] = useState();

    useEffect(()=>{
        if(authUser?.role === UserRole.CLIENT) {
            ApiService.get(`/client/coupon/available/${advisor._id}`).then(response => {
                setAvailCoupon(response.data.coupon);
            })
            .finally(()=>{

            })
        }
    }, [authUser]);

    const onChatOffMessageClicked = async(e) => {
        e.preventDefault();
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service.");
            return;
        }

        try {
            const response = await ApiService.get(`/chatoff_thread/getopen/${advisor._id}`);
            if(isMobileScreen()) {
                JwtService.setChatOffThread(response.data.thread);
                JwtService.setChatOffPartner(advisor);
                navigate(v_url('/chatoff_message'));
            } else {
                modalContext.setModalData({
                    thread: response.data.thread,
                    partner: advisor
                });
                modalContext.setOpenChatOffMessageModal(true);
            }
        } finally {

        }
    }

    const onRemoveFavorite = (e) => {
        e.preventDefault();

        const aId = advisor._id;
        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/remove', {aId}).then(result => {
            dispatch(removeFavoriteAdvisor(aId));
        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const onAddFavorite = (e) => {
        e.preventDefault();

        const aId = advisor._id;
        setAddingFavorite(true);
        ApiService.post('/client/advisor/favorite/add', {aId}).then(result => {
            dispatch(addFavoriteAdvisor(aId));
        })
        .finally(()=>{
            setAddingFavorite(false);
        })
    }

    const renderPC = () => {
        return (<>
            <MDBCard className="main-info-container pc d-none d-md-block pc">
                <MDBCardBody>
                    <div className="d-flex p-2">
                        <div className="me-4 d-flex justify-content-center photo position-relative">
                            <img    src={getAvatarUrl(advisor?.avatar)} 
                                    alt='Advisor' 
                                    className="circle"
                                    title='Advisor Avatar' />
                            {availCoupon && <div className="coupon" style={{left: 'calc(50% - 15px)', top: -10}}>
                                {availCoupon.availMinutes}
                            </div>}
                        </div>
            
                        <div className="d-flex flex-column py-4">
                            <div>
                                <div className="advisor-name">
                                    <strong>{ advisor?.username }</strong>
                                </div>
                                <div style={{color:'#AD3E00'}}>
                                    {advisor?.service_name}
                                </div>
                            </div>
            
                            <div className="flex-fluid d-flex flex-column justify-content-end">
                                <CommPanel advisor={advisor} />
                                <div className="mt-1 text-gray555 mt-2">
                                    {advisor?.total_chats ?? 0} Readings
                                </div>
                            </div>
            
                            {authUser?.role === UserRole.CLIENT && 
                            <div className="action-bar d-flex align-items-center">
                                { isFavoriteAdvisor && 
                                    <div onClick={onRemoveFavorite} 
                                        className="trash-favorite-btn d-flex justify-content-center align-items-center cursor-pointer"
                                        title="Remove from favorite advisor"
                                    >
                                        {addingFavorite && <MDBSpinner />}
                                        {!addingFavorite && <MDBIcon fas icon='heart' color="danger" />}
                                    </div> 
                                }
                                { !isFavoriteAdvisor && 
                                    <div onClick={onAddFavorite} 
                                        className="add-favorite-btn d-flex justify-content-center align-items-center cursor-pointer"
                                        title="Add to favorite advisor"
                                    >
                                        {addingFavorite && <MDBSpinner />}
                                        {!addingFavorite && <MDBIcon far icon='heart' color='danger' size="md" />}
                                    </div>
                                }
                                <div className="buzz-btn ms-2">
                                    <MDBBtn tag='a' color="none" onClick={onChatOffMessageClicked}>
                                        <SVG className="bell-icon" 
                                            src='/img/icons/envelope.svg' 
                                            width={25} 
                                            height={25} 
                                            fill="#a08aed"
                                            title="Send Message"
                                        />
                                    </MDBBtn>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="category-tags p-2">
                        {advisor?.category_tags?.split(',').map((v, index) => {
                            const tag = CategoryTags.find(t => t.value === v);
                            if(tag) {
                                return <div className="category-tag d-flex align-items-center me-2" key={index}>
                                    <MDBIcon fas icon="hand-holding-heart" color="purple" />
                                    <span className="ms-2">{tag.label}</span>
                                </div>
                            }
                        })}
                    </div>
                </MDBCardBody>
            </MDBCard>
            </>
        )        
    }

    const renderMobile = () => {
        return (
            <MDBCard className="main-info-container d-block d-md-none mobile">
                <MDBCardBody className="position-relative">
                    <div className="d-flex flex-column align-items-center p-2">
                        <div className="photo position-relative">
                            <img    src={getAvatarUrl(advisor?.avatar)} 
                                    alt='Advisor' 
                                    title='Advisor Avatar' 
                            />
                            {availCoupon && <div className="coupon" style={{left: 'calc(50% - 15px)', top: -10}}>
                                {availCoupon.availMinutes}
                            </div>}
                        </div>

                        <div className="advisor-name mt-2">
                            <strong>{ advisor?.username }</strong>
                        </div>

                        <div style={{color: '#AD3E00'}}>
                            {advisor?.service_name}
                        </div>

                        <div className="mt-2">
                            <CommPanel advisor={advisor} />
                        </div>

                        {authUser?.role === UserRole.CLIENT && 
                        <div className="action-bar d-flex align-items-center">
                            { isFavoriteAdvisor && 
                                <div onClick={onRemoveFavorite} 
                                    className="trash-favorite-btn d-flex justify-content-center align-items-center cursor-pointer"
                                    title="Remove from favorite advisor"
                                >
                                    {addingFavorite && <MDBSpinner />}
                                    {!addingFavorite && <MDBIcon fas icon='heart' color="danger" size="lg" />}
                                </div> 
                            }
                            { !isFavoriteAdvisor && 
                                <div onClick={onAddFavorite} 
                                    className="add-favorite-btn d-flex justify-content-center align-items-center cursor-pointer"
                                    title="Add to favorite advisor"
                                >
                                    {addingFavorite && <MDBSpinner />}
                                    {!addingFavorite && <MDBIcon far icon='heart' color='danger' size="lg" />}
                                </div>
                            }
                            <div className="buzz-btn ms-2">
                                <MDBBtn tag='a' color="none" onClick={onChatOffMessageClicked}>
                                    <SVG className="bell-icon" 
                                        src='/img/icons/envelope.svg' 
                                        width={25} 
                                        height={25} 
                                        fill="#a08aed"
                                        title="Send Buzz"
                                    />
                                </MDBBtn>
                            </div>
                        </div>}
                    </div>

                    <div className="mt-2 p-2">
                        <div className="mt-1 text-gray555">
                            {advisor?.total_chats ?? 0} Readings
                        </div>

                        <div className="category-tags mt-1">
                            {advisor?.category_tags?.split(',').map((v, index) => {
                                const tag = CategoryTags.find(t => t.value === v);
                                if(tag) {
                                    return <div className="category-tag d-flex align-items-center w-100 mt-2" key={index}>
                                        <MDBIcon fas icon="hand-holding-heart" color="purple" />
                                        <span className="ms-2">{tag.label}</span>
                                    </div>
                                }
                            })}
                        </div>
                    </div>
                </MDBCardBody>
            </MDBCard>    
        )
    }

    return (<>
        { renderPC() }
        { renderMobile() }
    </>)
};

export default MainInfo;