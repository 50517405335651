import React, {useEffect, useRef, useState} from "react";
import classname from 'classnames';
import ApiService from "../../../../Core/Service/ApiService";
import { MDBCol, MDBContainer,
        MDBInput, 
        MDBRow,
        MDBTextArea} from "mdb-react-ui-kit";
import { formatDate_YYYY_MM_DD } from "../../../../Utils/utils";
import { CategoryTags } from "../../../../Constants/constants";

const PersonalData = React.memo((props) => {
    const {advisor, compareData} = props;

    const updatedClass = (field) => {
        if(compareData && compareData[field] != advisor[field]) {
            return 'updated';
        } else {
            return ''
        }
    }

    return <MDBContainer breakpoint="lg" className="d-flex justify-content-center">
            <MDBRow className="legalname">
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname(`label`, updatedClass('email'))}>Email</span>
                    <MDBInput value={advisor.email} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname('label', updatedClass('legalname'))}>Legal Name</span>
                    <MDBInput value={advisor.legalname} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4  mb-3">
                    <span className={classname('label', updatedClass('mobile'))}>Mobile</span>
                    <MDBInput value={advisor.mobile} className='form-control' readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname('label', updatedClass('birth'))}>Birthday</span>
                    <MDBInput value={formatDate_YYYY_MM_DD(new Date(advisor.birth))} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('country'))}>Country</span>
                    <MDBInput value={advisor.country} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('state'))}>State</span>
                    <MDBInput value={advisor.state} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('city'))}>City</span>
                    <MDBInput value={advisor.city} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('zipcode'))}>ZIP Code</span>
                    <MDBInput value={advisor.zipcode} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('address'))}>Address</span>
                    <MDBTextArea value={advisor.address} rows={2} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('ssn'))}>SSN</span>
                    <MDBInput value={advisor.ssn} readOnly={true} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className={classname("label", updatedClass('category_tags'))}>Category Tags</span>
                    <MDBTextArea 
                        value={advisor.category_tags.split(',').map(v => {
                            const tag = CategoryTags.find(t => t.value === v);
                            return tag?.label
                        }).join(', ')}
                        rows={2} readOnly={true} 
                    />
                </MDBCol>
            </MDBRow>
    </MDBContainer>
});

export default PersonalData;