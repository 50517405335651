import React, { useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { floatV, formatDate_YYYY_MM_DD, formatSeconds, getAvatarUrl, scrollUpTop, validateForm } from '../../Utils/utils';
import ApiService from "../../Core/Service/ApiService";
import { 
    MDBModal,
    MDBModalDialog,
    MDBModalBody,
    MDBModalContent,
    MDBBtn,
    MDBSpinner,
    MDBRow,
    MDBCol,
    MDBModalFooter,
    MDBModalHeader,
    MDBTextArea,
    MDBCheckbox,
    MDBRadio,
} from "mdb-react-ui-kit";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { EDIT_REVIEW_DAYS_OUT, REVIEW_CHAT_DURAION, ReviewType, UserRole } from "../../Constants/constants";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";

const ChatEndModal = (props) => {
    const [submitting, setSubmitting] = useState(false);
    const modalContext = useContext(ModalContext);
    const modalData = modalContext.modalData;
    const setOpen = modalContext.setOpenChatEndModal;
    const reviewExpireDate = new Date();
    reviewExpireDate.setDate(reviewExpireDate.getDate() + EDIT_REVIEW_DAYS_OUT);
    const authUser = useSelector(memoizedGetAuthUser);
    const [reviewFormData, setReviewFormData] = useState({
        reviewType: ReviewType.HAPPY,
        reviewContent: '',
    });
    const reviewFrmDataRules = {
        reviewContent: {
            required: 'string',
            label: 'Feedback'
        }
    };
    const [noteFormData, setNoteFormData] = useState({
        noteContent: ''
    })
    const noteFormDataRules = {
        noteContent: {
            required: 'string',
            label: 'Note'
        }
    }
    const [inputErrors, setInputErrors] = useState({});
    const [chkClientReview, setChkClientReview] = useState(false);
    const [chkAdvisorNote, setChkAdvisorNote] = useState(false);

    useEffect(()=>{
        setReviewFormData({
            reviewType: ReviewType.HAPPY,
            reviewContent: ''
        })
        setNoteFormData({
            noteContent: ''
        });
        setChkClientReview(false);
        setChkAdvisorNote(false);
    }, [modalContext.openChatEndModal]);

    const onReviewTypeChanged = (e) => {
        setReviewFormData({
            ...reviewFormData,
            reviewType: e.target.value
        });
    }
    const onReviewInputChanged = (e) => {
        setReviewFormData({
            ...reviewFormData,
            [e.target.name]: e.target.value
        })
    }
    const onNoteInputChanged = (e) => {
        setNoteFormData({
            ...noteFormData,
            [e.target.name]: e.target.value
        })
    }

    const onConfirmBtnClicked = (e) => {
        e.preventDefault();

        if(authUser?.role === UserRole.CLIENT && chkClientReview) {
            reviewFormData.reviewContent = reviewFormData.reviewContent.trim();
            const valid_ret = validateForm(reviewFormData, reviewFrmDataRules);
            if(valid_ret.result == true) {
                setInputErrors({});
                if(window.confirm('Are you sure to write review for this session?') == false) return;

                setSubmitting(true);
                const data = {
                    ...reviewFormData,
                    chatId: modalData.chatDetail.chatId,
                }
                ApiService.post('/client/review/create', data).then((result)=>{
                })
                .finally(()=>{
                    setSubmitting(false);
                    setOpen(false);
                })
            } else {
                setInputErrors(valid_ret.errors);
                return;
            }
        } else if(authUser?.role === UserRole.ADVISOR && chkAdvisorNote) {
            const valid_ret = validateForm(noteFormData, noteFormDataRules);
            if(valid_ret.result == true) {
                setInputErrors({});

                setSubmitting(true);
                const data = {
                    cId: modalData.client._id,
                    chatId: modalData.chatDetail.chatId,
                    note: noteFormData.noteContent
                };
                ApiService.post('/advisor/note', data).then(result=>{

                })
                .finally(()=>{
                    setSubmitting(false);
                    setOpen(false);
                })
            } else {
                setInputErrors(valid_ret.errors);
                return;
            }
        } else {
            setOpen(false);
        }
    }

    const renderReview = () => {
        return  <MDBRow className="chat-review mt-2">
            <div><label>Feedback:</label></div>
            <div className="d-flex flex-column">
                <MDBTextArea rows={5} 
                    value={reviewFormData.reviewContent}
                    name='reviewContent'
                    onChange={onReviewInputChanged}
                    placeholder="Leave a review here"
                />
                {inputErrors.reviewContent && <div className='error'>{inputErrors.reviewContent}</div>}
            </div>
            <div className="d-flex mt-3 justify-content-begin">
                <div className="d-flex align-items-center">
                    <img 
                        src='/img/icons/png/sad.png' 
                        style={{opacity : reviewFormData.reviewType==ReviewType.UNHAPPY? 1: 0.3, width:30, height:30}} 
                        className="me-1"
                    />
                    <MDBRadio 
                        name='reviewTypeRadio' 
                        id='unhappy_review' 
                        value={ReviewType.UNHAPPY}
                        onChange={onReviewTypeChanged}
                    />
                </div>
                <div className="ms-4 d-flex align-items-center">
                    <img 
                        className="me-1"
                        src='/img/icons/png/smile.png' 
                        style={{opacity : reviewFormData.reviewType==ReviewType.HAPPY? 1: 0.3, width:30, height:30}} 
                    />
                    <MDBRadio 
                        name='reviewTypeRadio' 
                        id='happy_review' 
                        value={ReviewType.HAPPY}
                        defaultChecked 
                        onChange={onReviewTypeChanged}
                    />
                </div>
            </div>
        </MDBRow>
    }

    const renderClient = () => {
        return (
            <section className="client-review d-flex flex-column align-items-center">
                <div className="" style={{minWidth: 300}}>
                    <div className="d-flex justify-content-center">
                        <label className="flex-fluid">Advisor Rate</label>
                        <span className="value">$ { modalData.advisor?.rate_per_min_chat } / min</span>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <label className="flex-fluid">Total Duration</label>
                        <span className="value">{ formatSeconds(modalData.chatDetail?.chatTime, true) }</span>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <label className="flex-fluid">Total Amount</label>
                        <span className="value">$ {modalData.chatDetail?.amount}</span>
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <MDBCheckbox checked={chkClientReview} 
                                    onChange={()=>setChkClientReview(!chkClientReview)} 
                                    label="Leave a Review"
                                    disabled={!(modalData.chatDetail?.chatTime > REVIEW_CHAT_DURAION)}
                                    />
                        {modalData.chatDetail?.chatTime > REVIEW_CHAT_DURAION && (
                            <div className="flex-fluid text-right comment">by {formatDate_YYYY_MM_DD(reviewExpireDate)}</div>
                        )}
                    </div>
                    <div className="comment">(when you have a 5 min paid chat)</div>

                </div>
                
                { chkClientReview && renderReview() }
            </section>
        )
    }

    const renderAdvisorNote = () => {
        return (<div className="w-100">
            <div><label>Note:</label></div>
            <div className="d-flex flex-column">
                <MDBTextArea rows={5} 
                    value={noteFormData.note} 
                    name='noteContent'
                    onChange={onNoteInputChanged}
                    placeholder="Leave a note about the chat here"
                />
                {inputErrors.noteContent && <div className='error'>{inputErrors.noteContent}</div>}
            </div>
        </div>)
    }

    const renderAdvisor = () => {
        return (
            <section className="advisor-review w-100 d-flex flex-column align-items-center">
                <div className="" style={{minWidth: 300}}>
                    <div className="d-flex justify-content-center">
                        <label className="flex-fluid">Total Duration</label>
                        <span className="value">{ formatSeconds(modalData.chatDetail?.chatTime, true) }</span>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                        <label className="flex-fluid">My Earnings</label>
                        <span className="value">$ {floatV(modalData.chatDetail?.amount * authUser?.commission / 100)}</span>
                    </div>

                    <div className="w-100 mt-3 advisor-note-check">
                        <MDBCheckbox checked={chkAdvisorNote} 
                            onChange={()=>setChkAdvisorNote(!chkAdvisorNote)} 
                            label="Write a note about this chat"
                        />
                    </div>

                </div>

                {chkAdvisorNote && renderAdvisorNote()}
            </section>
        )
    }

    let partner;
    if(modalContext.openChatEndModal) partner = authUser?.role === UserRole.CLIENT ? modalData.advisor : modalData.client;

    return (
        <MDBModal className={`chat-end-modal`} 
                open={modalContext.openChatEndModal} 
                setOpen={setOpen}
                closeOnEsc={false} 
                staticBackdrop 
                tabIndex='-1'>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader className="d-flex justify-content-center" style={{backgroundColor: '#e7d7e6'}}>
                        <h5>Chat Ended</h5>
                    </MDBModalHeader>

                    <MDBModalBody className="d-flex justify-content-center align-items-center flex-column review-container">
                        <div className="d-flex align-items-center mb-4">
                            <img src={getAvatarUrl(partner?.avatar)} className="circle-avatar-50" />
                            <span className="ms-4">{partner?.username}</span>
                        </div>
                        { modalData?.chatDetail && authUser?.role === UserRole.CLIENT && renderClient()}
                        { modalData?.chatDetail && authUser?.role === UserRole.ADVISOR &&  renderAdvisor()}
                        { modalData && !modalData.chatDetail && <>
                        The chat was closed due to network issue on client side.
                        </>}
                    </MDBModalBody>

                    <MDBModalFooter className="d-flex justify-content-center">
                        <MDBBtn 
                            color="secondary" 
                            onClick={onConfirmBtnClicked } 
                            style={{textTransform:'none'}}
                        >
                            {submitting && <>
                                <MDBSpinner role='status' size="sm" />
                                <span className="ms-1">Saving</span>
                            </>}
                            {!submitting && <span>Continue</span>}
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
};

export default ChatEndModal;