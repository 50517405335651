import React, { useState, useEffect, useRef } from "react";
import { FUNDING, PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { toast } from "react-toastify";
import { MDBSpinner } from "mdb-react-ui-kit";
import ApiService from "../../Core/Service/ApiService";

const PaypalCheckOut = React.memo((props) => {
    const amount = useRef(0);
    const { setMessage } = props;

    useEffect(()=>{
        amount.current = props.amount
    }, [props.amount]);

    const onPaypalInit = () => {
        setMessage({
            type: 'success',
            icon: 'check-circle',
            content: 'You are ready for making payment.'
        });
    }

    const createOrder = async () => {
        try {
            props.setIsPaying(true);

            setMessage({
                type: 'info', 
                icon: 'spinner',
                content: 'Creating Payment...'
            });

            const response  =  await ApiService.post('/client/paypal/create_payment', {amount: amount.current});
            if(response.data.id) {
                return response.data.id;
            } else {
                props.setIsPaying(false);
                const errorDetail = response.data.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
                    : JSON.stringify(orderData);
    
                setMessage({
                    type: 'error',
                    icon: 'exclamation-triangle',
                    content: errorMessage
                })
            }
        }
        catch(error) {
            console.error(error);
            setMessage({
                type: 'error',
                icon: 'exclamation-triangle',
                content: 'Could not initiate PayPal Checkout'
            })
        }
    }
    const onApprove = async(data, actions) => {
        try {
            setMessage({
                type: 'info', 
                icon: 'spinner',
                message: 'Checking Payment...'
            });

            const response = await ApiService.post("/client/paypal/capture", {orderId: data.orderID});
            props.onPaymentReturn && props.onPaymentReturn(response.data);
        } catch(error) {
            props.setIsPaying(false);
            console.error(error);
            setMessage({
                type: 'error', 
                icon: 'exclamation-triangle',
                message: error.message
            })
        }
    }
    const onCancel = async() => {
        props.setIsPaying(false);
        setMessage({
            type: 'info',
            icon: 'check-circle',
            content: 'You are ready for making payment.'        
        })
    }

    return (<>
        <PayPalButtons
            style={{
                shape: 'rect',
                layout: "vertical", //default value. Can be changed to horizontal
            }}
            createOrder={createOrder}
            onApprove={onApprove}
            onInit={onPaypalInit}
            onCancel={onCancel}
            shippingPreference="NO_SHIPPING"
            fundingSource={FUNDING.PAYPAL}
            disabled={props.isPaying}
        >
        </PayPalButtons>
    </>)
});

export default PaypalCheckOut;