import React from "react";
import { useEffect, useState } from "react";
import ApplicantDocument from "./ApplicantDocument";
import { useSelector } from "react-redux";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import ApiService from "../../../Core/Service/ApiService";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBContainer, MDBIcon } from "mdb-react-ui-kit";
import { toast } from "react-toastify";
import AdvisorPayoutMethodEdit from "./PaymentMethodEdit";
import { isValidEmail, scrollToElement } from "../../../Utils/utils";

const AdvisorActivateListing = React.memo((props) => {
    const [formData, setFormData] = useState();
    const [loading, setLoading] = useState(false);
    const authUser = useSelector(memoizedGetAuthUser);
    const [inputErrors, setInputErrors] = useState({});

    useEffect(()=>{
        if(authUser) {
            setLoading(true);

            ApiService.get('/advisor/auth/' + authUser._id).then(result=>{
                setFormData({
                    ...result.data.advisor.mirror,
                    cert_files: result.data.advisor.mirror.cert_files?.length > 0 ? [...result.data.advisor.mirror.cert_files] : [],       // it not use this way, instead copy directly, then go with reference.
                    new_cert_files: []
                });
            })
            .finally(()=>{
                setLoading(false);
            })
        }
    }, [authUser]);

    const renderPayoutMethod = () => {
        return (
            <AdvisorPayoutMethodEdit formData={formData} setFormData={setFormData} inputErrors={inputErrors} />
        )
    }

    const renderDocuments = () => {
        return (
            <MDBCard className="mt-4">
                <MDBCardHeader>
                    <MDBIcon fas icon="id-card" size="lg" />
                    <span className="accordion-item-title">My Application Documents</span>    
                    <span className="notify">(Not public to clients)</span>
                </MDBCardHeader>
                <MDBCardBody>
                    <ApplicantDocument formData={formData} setFormData={setFormData} inputErrors={inputErrors} />
                </MDBCardBody>
            </MDBCard>
        )
    }

    const handleSubmit = () => {
        // To check gov_id, tax
        const errors = {};
        if(!formData.paypal_email || !isValidEmail(formData.paypal_email)) {
            errors.paypal_email = "Please input valid email.";
        }
        if(!formData.gov_photo_id_front && !formData.new_id_front_file) {
            errors.gov_photo_id_front = 'Please select government id card.';
        }
        if(!formData.gov_photo_id_backend && !formData.new_id_back_file) {
            errors.gov_photo_id_backend = 'Please select government id card.';
        }

        setInputErrors(errors);

        if(Object.keys(errors).length > 0) {
            const element = document.getElementById(Object.keys(errors)[0]);
            scrollToElement(element);
            return;
        }

        const postData = new FormData();
        Object.keys(formData).forEach(k=>{
            if(k == 'new_cert_files') {
                if(formData[k]?.length > 0) {
                    formData[k].forEach(ncf=>{
                        postData.append('new_cert_files', ncf);
                    })
                }
            } else {
                postData.append(k, formData[k]);
            }
        })
        const headers = {'Content-Type': 'multipart/form-data'};
        ApiService.post('/advisor/auth/' + authUser._id, postData, headers).then(result => {
            setFormData({
                ...result.data.advisor.mirror,
                cert_files: result.data.advisor.mirror.cert_files?.length > 0 ? [...result.data.advisor.mirror.cert_files] : [],       // if not use this way, instead copy directly, then go with reference.
                new_cert_files: []
            });
            toast.success(result.data.message);
        })
        .finally(()=>
        {
            setLoading(false);
        });
    }
    
    return (<MDBContainer breakpoint="lg" className="advisor-activate-listing">
        {renderPayoutMethod()}
        {renderDocuments()}

        <div className="fixed-action-bar mt-4">
            <div className="d-flex justify-content-center align-items-center">
                <MDBBtn className="confideas-primary-btn ms-4" onClick={handleSubmit}>Save</MDBBtn>
            </div>
        </div>
    </MDBContainer>)
})

export default AdvisorActivateListing;