import { MDBSpinner } from 'mdb-react-ui-kit';
import React from 'react';
import styles from './LoadingIndicator.scss';

function LoadingIndicator(props){
    if(props.show) {
        if(!props.fullScreen) {
            return <div className='loading-indicator'>
                <img src="/img/gifs/spinner.gif" style={{width: 80, height: 80}} />
                {props.message && <span role='status' className='ms-2'>{props.message}</span>}
            </div>
        } else {
            return <div className='loading-indicator full-screen'>
                <img src="/img/gifs/spinner.gif" style={{width: 80, height: 80}} />
                {props.message && <span role='status' className='ms-2'>{props.message}</span>}
            </div>
        }
    } else {
        return <></>
    }
}

export default LoadingIndicator;