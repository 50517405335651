import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { memoizedGetAuthUser, purgeAuth } from "../Store/Reducer/authReducer";
import { useNavigate } from "react-router-dom";
import { removeChangedAdvisors } from "../Store/Reducer/changedAdvisorReducer";
import { removeAlarms } from "../Store/Reducer/alarmReducer";
import ModalContext from "../Context/ModalContext";
import { v_url } from "../Utils/utils";
import LoadingIndicator from "./LoadingIndicator/LoadingIndicator";
import { MDBContainer } from "mdb-react-ui-kit";
import ApiService from "../Core/Service/ApiService";

const ReLogin = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authUser = useSelector(memoizedGetAuthUser);
    const modalContext = useContext(ModalContext);

    useEffect( ()=>{
        (async () => {
            try {
                if(authUser) {
                    if(authUser.role === 'client'){
                        await ApiService.get('/client/auth/logout');
                    } else {
                        await ApiService.get('/advisor/auth/logout');
                    }
                }
            } finally {
    
            }
            
            dispatch(purgeAuth());
            dispatch(removeChangedAdvisors());
            dispatch(removeAlarms());
            navigate(v_url('/'));

            setTimeout(()=>{
                modalContext.setOpenSignInModal(true);
            }, 500);
        })()
    }, [])

    return <MDBContainer breakpoint="lg" className="p-4">
        <LoadingIndicator fullScreen={true} />
    </MDBContainer>
}

export default ReLogin;