import { MDBBtn, MDBIcon, MDBInput, MDBSpinner } from "mdb-react-ui-kit";
import React, {useContext, useEffect, useRef, useState} from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IsChatCreated, memoizedGetChat, removeChat } from "../../Store/Reducer/chatReducer";
import { memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { format_time_mm_ss, getAvatarUrl } from "../../Utils/utils";
import { ChatStatus, UserRole } from "../../Constants/constants";
import DeviceContext from "../../Context/DeviceContext";
import SVG from 'react-inlinesvg';
import ChatContext from "./ChatContext";
import ModalContext from "../../Context/ModalContext";
import { IsChatSocketConnected } from "../../Store/Reducer/socketReducer";
import { confirm_alert } from "../../Components/ConfirmAlert/ConfirmAlert";

function ChatPartnerSm(props) {
    const dispatch = useDispatch();
    const chatContext = useContext(ChatContext);
    const modalContext = useContext(ModalContext)
    const [remainTime, setRemainTime] = useState(0);
    const isChatCreated = useSelector(IsChatCreated);
    const chat = useSelector(memoizedGetChat);
    const authUser = useSelector(memoizedGetAuthUser);
    const [partner, setPartner] = useState({});
    const {endChatSession} = chatContext;
    const timerRef = useRef(null);
    const isChatSockConnected = useSelector(IsChatSocketConnected);
    const isChatSockConnected_ref = useRef(false);

    useEffect(()=>{
        isChatSockConnected_ref.current = isChatSockConnected;
    }, [isChatSockConnected])

    useEffect(() => {
        if(isChatCreated) {
            // To first clear timer if any
            if(timerRef.current) {
              clearInterval(timerRef.current);
              timerRef.current = null;
            } 
      
            if(chat.isChatting) {       // in the case of free_setup_session, coupon_session, pay_seesion
              setRemainTime(Math.max(0, chat.sessionTime - Math.floor((new Date().getTime() - chat.current_session_startedAt) / 1000)));
      
              timerRef.current = setInterval(()=>{
                const remainTime = Math.max(0, chat.sessionTime - Math.floor((new Date().getTime() - chat.current_session_startedAt) / 1000));
                setRemainTime(remainTime);
        
                if(remainTime == 0) {
                  clearInterval(timerRef.current);
                  timerRef.current = null;
                }
              }, 1000);
            }
        }
    }, [isChatCreated, chat?.status])
  
    useEffect(()=>{
      if(authUser && isChatCreated) {
        setPartner(authUser.role === 'client' ? chat.advisor : chat.client);
      }
    }, [authUser, isChatCreated])

    const onEndChatBtnClicked = () => {
        chatContext.setChatInputFocus(false);

        confirm_alert({
            message: 'Do you want to quit the chat?',
            ok: () => {
                endChatSession();
            },
        })
    }

    const viewClientDetail = () => {
        if(authUser?.role === UserRole.ADVISOR) {
            modalContext.setModalData({
                client: partner
            })
            modalContext.setOpenClientDetailModal(true);
        }
    }

    return (
        <div className="chat-partner-container-sm d-flex d-lg-none align-items-center" style={props.style}>
            { partner && partner._id && <>
                {chat?.status === ChatStatus.CONNECTED && <div className="d-flex align-items-center">
                    <img className="waiting-partner circle-avatar-40" src={getAvatarUrl()} />
                    <span className="ms-2 text-white">
                        Connecting...
                    </span>
                </div>}

                {chat?.status > ChatStatus.CONNECTED && <>
                <div className="d-flex">
                    <img className="photo mx-1" src={ getAvatarUrl(partner.avatar) } alt='' />
                    <div className="partner-name d-flex align-items-center">
                        <div>{ partner.username }</div>
                    </div>
                </div>

                {authUser.role === UserRole.ADVISOR &&
                <div className="edit-note d-flex align-items-center ms-2">
                    <SVG src='/img/icons/note.svg' 
                        className="cursor-pointer"
                        height={25} 
                        fill="#fde345"
                        title='View client information' 
                        onClick={viewClientDetail}
                    />
                </div>}
                </>}

                {chat?.freeMinutes > 0 && 
                <div className="free-minutes ms-2 d-flex justify-content-center align-items-center" title="Free service">
                    {chat?.freeMinutes}
                </div>
                }

                {/* <div style={{width:100}} className="ms-2">
                <MDBInput 
                    type="number" 
                    value={chatContext.inputBarBottom} 
                    onChange={(e) => chatContext.setInputBarBottom(e.target.value)} 
                    style={{color:'white'}}
                />
                </div> */}

                <div className="flex-fluid d-flex justify-content-end align-items-center action">
                    <div className={`timer d-flex align-items-center`}>
                        <div><img src='/img/icons/png/timer.png' alt='' /></div>
                        <div className={`mx-2 spent-time`} translate="no">
                            {chat?.status === ChatStatus.FREE_SETUP_SESSION && <span>Free Setup Time</span>}
                            {chat?.status == ChatStatus.COUPON_SESSION && <>
                                <div>Free Minutes</div>
                                <div>{ format_time_mm_ss(remainTime) }</div>
                            </>}
                            {chat?.status == ChatStatus.PAY_SESSION && <>
                                <div>Paid Chat</div>
                                <div>{ format_time_mm_ss(remainTime) }</div>
                            </>}
                        </div>
                    </div>
                    <div className="end-chat-btn d-flex align-items-center" onClick={onEndChatBtnClicked}>
                        <MDBBtn color="none" tag='a'>
                            <img src="/img/icons/png/end_call.png" />
                        </MDBBtn>
                    </div>
                </div>
            </>
            }       
        </div>
    )
}

export default ChatPartnerSm;