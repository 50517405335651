import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiService from "../../../Core/Service/ApiService";
import styles from './CouponChat.scss';
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Advisor from "../../../Components/Advisor/Advisor"
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import Moment from "react-moment";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getChatSocket } from "../../../Store/Reducer/socketReducer";
import { toast } from "react-toastify";
import { SendMessages } from "../../../Constants/constants";
import { IsAuthenticated, memoizedGetAuthUser, setAuth } from "../../../Store/Reducer/authReducer";
import { text2html } from "../../../Utils/utils";
import { getPreviousUrl, popHistory } from "../../../Store/Reducer/urlHistoryReducer";

const FreeService = React.memo((props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const previousUrl = useSelector(getPreviousUrl);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [coupon, setCoupon] = useState(null);
    const chatSocket = useSelector(getChatSocket);
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const coupon_id = urlParams.get("id");

    useEffect(()=>{
        if(token) {
            setLoading(true);

            ApiService.post('/client/coupon/check', {token}).then(result => {
                if(!authUser) {
                    if(result.data.authUser && result.data.authToken) {
                        dispatch(setAuth({
                            authUser: result.data.authUser,
                            authToken: result.data.authToken
                        }))
                    }
                }
                setCoupon(result.data.coupon);
                setErrorMessage(null);
            })
            .catch(error => {
                setErrorMessage("Service unavailable")
            })
            .finally(()=>{
                setLoading(false);
            });
        }
    }, [token])

    useEffect(()=>{
        if(isAuthenticated && coupon_id) {
            setLoading(true);

            ApiService.get(`/client/coupon/${coupon_id}`).then(result => {
                setCoupon(result.data.coupon);
                setErrorMessage(null);
            })
            .catch(error => {
                setErrorMessage("Service unavailable")
            })
            .finally(()=>{
                setLoading(false);
            });
        }
    }, [isAuthenticated, coupon_id])

    const onRequestCall = (service) => {
        if(!chatSocket) {
            toast.error('Network Error!');
            return;
        }

        chatSocket.emit(SendMessages.REQUEST_CHAT, {
            aId: coupon.aId._id
        });
    }

    const goBack = (e) => {
        if(previousUrl) {
            const url = previousUrl;
            dispatch(popHistory())
            navigate(url);
        }
    }

    return <MDBContainer breakpoint="lg" className="coupon-chat-container p-4 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 d-flex flex-column justify-content-center align-items-center coupon-chat">
            {loading && <div>Requesting service...</div> }
            
            {coupon && 
            <div className="service-info d-flex flex-column align-items-start w-100">
                <h6 className="header-title d-flex">
                    <MDBIcon far icon="envelope" className="me-2" style={{marginTop: 2}} color="warning" size="lg" />
                    <div>
                        <span>You have got message from</span> 
                        <span className="advisor ms-1">@{coupon.aId.username}</span>
                    </div>
                </h6>
                <div className="mt-2 w-100">
                    {coupon.freeMinutes > 0 && <>
                        <div className="d-flex align-items-center free-minutes">
                            <label>Free Minutes</label>
                            <span className="me-2">:</span>
                            <span className="value">{coupon.availMinutes} min</span>
                        </div>
                        <div className="d-flex align-items-center mt-1">
                            <label>Expire At</label>
                            <span className="me-2">:</span>
                            <span className="value expire-at"><Moment format="YYYY-MM-DD">{coupon.expireAt}</Moment></span>
                        </div>
                    </>}
                    {coupon.message && 
                        <div className="d-flex mt-2">
                            <label>Message</label>
                            <span className="me-2">:</span>
                            <div className="value message-content" dangerouslySetInnerHTML={{ __html: text2html(coupon.message)}}></div>
                        </div>
                    }
                    {coupon.chatId && 
                        <div className='notify mt-3'>( You have already consumed this service. )</div>
                    }
                </div>
            </div>}

            {coupon?.aId && <div className="advisor-container mt-4">
                <Advisor advisor={coupon.aId} onRequestCall={onRequestCall} /> 
            </div>}

            {errorMessage && <div className="error">{errorMessage}</div> }
        </div>
        
        {loading && <LoadingIndicator /> }
    </MDBContainer>
});

export default FreeService;