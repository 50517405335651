import React, { useEffect, useState, useRef, Children } from "react";
import './FloatBar.scss';

const FloatBar = React.memo((props) => {
    const [floatBar, setFloatBar] = useState(false);
    const floatBarRef = useRef(false);
    const { triggerY, mobile_float_y, pc_float_y } = props;

    useEffect(()=>{
        const handleScroll = () => {  
            if(window.scrollY > triggerY && !floatBarRef.current) {
                setFloatBar(true);
                floatBarRef.current = true;
            }
            if(window.scrollY < triggerY && floatBarRef.current) {
                setFloatBar(false);
                floatBarRef.current = false;
            }
        };
        window.addEventListener('scroll', handleScroll);
            
        return () => {  
            window.removeEventListener('scroll', handleScroll);  
        };
    }, [])

    return (
        <div className="float-go-back-bar"
            style={{transform: `translateY(${floatBar ? (window.visualViewport.width > 768 ? pc_float_y : mobile_float_y) : 0}px)`}}
        >
            <div className='container-lg d-flex align-items-center'>
                {props.children}
            </div>
        </div>
    )
});

export default FloatBar;