import React, {useEffect, useState} from "react";
import SVG from 'react-inlinesvg';
import { Rating, Star, ThinStar } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css'
import { MDBIcon, MDBProgress, MDBProgressBar, MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { IsAuthenticated } from "../../../Store/Reducer/authReducer";
import ApiService from "../../../Core/Service/ApiService";
import { ReviewType } from "../../../Constants/constants";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import Moment from "react-moment";
import Paginator from "../../../Components/Paginator/Paginator";

function Review(props) {
    const { review } = props;
    return (
        <div className={`review mb-2 p-3 ${review.type}`}>
            <div className="d-flex align-items-center mb-1">
                <img src={`/img/icons/${review.type==='happy' ? 'png/smile.png' : 'png/sad.png'}`} className="icon me-2" /> 
                <span className="client">{review.cId?.username}</span>
                <span className="mx-1">:</span>
                <span className="time"><Moment format="YYYY-MM-DD">{review.createdAt}</Moment></span>
            </div>
            <div style={{fontSize:18}} dangerouslySetInnerHTML={{ __html: review?.content?.replace('\n', '<br/>') }} />
        </div>
    )
}


const Reviews = React.memo((props) => {
    const { advisor } = props;
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('total_tab');
    const isLoggedOn = useSelector(IsAuthenticated);
    const [total, setTotal] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [searchFormData, setSearchFormData] = useState({
        reviewType: '',
        pageNum: 1,
        perPage: 10
    })

    useEffect(()=>{
        getReviews();
    }, [searchFormData]);

    const getReviews = () => {
        setLoading(true);
        ApiService.post(`/client/advisor/${advisor._id}/reviews`, searchFormData).then(result => {
            setTotal(result.data.total);
            setReviews(result.data.reviews);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const handleReviewTabClick = (tab) => {
        if(activeTab == tab) return;
        setActiveTab(tab);

        switch(tab) {
            case 'total_tab':
                setSearchFormData({
                    ...searchFormData,
                    reviewType: ''
                });
                break;
            case 'happy_tab':
                setSearchFormData({
                    ...searchFormData,
                    reviewType: 'happy'
                });
                break;
            case 'unhappy_tab':
                setSearchFormData({
                    ...searchFormData,
                    reviewType: 'unhappy'
                });
            break;
        }
    }

    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        })
    }

    const renderReviews = () => {
        if(reviews.length > 0) {
            return <div className="mt-2 py-2">
                <div>
                    {reviews?.map((review, index) => <Review review={review} key={index} /> )}
                </div>
                <div className='d-flex justify-content-center mt-4'>
                    <Paginator total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
                </div>
            </div>
        } else {
            return <div className="mt-4 text-center p-3">
                {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                    <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Reviews</div>
                </div>}
            </div>
        }
    }

    return (
        <MDBCard className="my-4">
            <MDBCardBody className="advisor-reviews">
                <section className="reviews-tab">
                    <MDBTabs >
                        <MDBTabsItem>
                            <MDBTabsLink onClick={()=>handleReviewTabClick('total_tab')} active={activeTab == 'total_tab'} >
                                <div className="d-flex align-items-center" style={{height: 30, fontSize: '120%'}}>Reviews ({advisor?.reviews?.length})</div>
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={()=>handleReviewTabClick('happy_tab')} active={activeTab == 'happy_tab'}>
                                <img src='/img/icons/png/smile.png' className="good_review" /> 
                                <span className="ms-1" style={{fontSize:'120%'}}>({advisor?.happy_review})</span>
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={()=>handleReviewTabClick('unhappy_tab')} active={activeTab == 'unhappy_tab'}>
                                <img src='/img/icons/png/sad.png' className="bad_review" />
                                <span className="ms-1" style={{fontSize:'120%'}}>({advisor?.unhappy_review})</span>
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>

                    {renderReviews()}
                    {loading && <LoadingIndicator/>}
                </section>
            </MDBCardBody>
        </MDBCard>
    )
});

export default Reviews;
