import React from "react";
import { text2html } from "../../../Utils/utils";
import { MDBCard, MDBCardBody, MDBIcon } from "mdb-react-ui-kit";

function AboutMe(props) {
    const {advisor} = props;

    return (
        <MDBCard className="mt-4">
            <MDBCardBody>
                <section className="about-me">
                    <div className="d-flex align-items-center mb-2">
                        <MDBIcon fas icon="user-graduate" size="lg" />
                        <h5 className="m-0 ms-2">About Me:</h5>
                    </div>
                    <div style={{overflowWrap: 'break-word', fontSize:20}} dangerouslySetInnerHTML={{ __html: text2html(advisor?.aboutme)}} />
                </section>

                <section className="offer-services mt-4">
                    <div className="d-flex align-items-center mb-2">
                        <MDBIcon fas icon="comment" size="lg" />
                        <h5 className="m-0 ms-2">Services Offered:</h5>
                    </div>
                    { advisor.service_description && 
                        <div style={{overflowWrap: 'break-word', fontSize:20}} dangerouslySetInnerHTML={{ __html: text2html(advisor?.service_description)}}></div>
                    }
                </section>
            </MDBCardBody>
        </MDBCard>
    )
}

export default AboutMe;