import React from "react";
import { MDBCol,
        MDBInput, 
        MDBRow,
        MDBTextArea} from "mdb-react-ui-kit";
import PhoneInput from "react-phone-number-input";
import DatePicker from "react-datepicker";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import SSNInput from "../../../Components/SSNInput/SSNInput";

const AdvisorPersonalData = React.memo((props) => {
    const {formData, setFormData, inputErrors} = props;

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const onSSNChange = (ssn) => {
        setFormData({
            ...formData,
            ssn
        })
    }
    const onPhoneNumberChanged = (pn) => {
        setFormData({
            ...formData,
            mobile: pn
        })
    }
    const onBirthChanged = (date) => {
        setFormData({
            ...formData,
            birth: date
        })
    }
    const onCountryChanged = (country) => {
        setFormData({
            ...formData,
            country
        })
    }
    const onStateChanged = (state) => {
        setFormData({
            ...formData,
            state
        })
    }

    return <>
        {formData && 
        <form className="personal-form w-100">
            <MDBRow className="legalname">
                <MDBCol md='6' className="px-4 mb-3">
                    <span className='label required'>Legal Name</span>
                    <MDBInput   className="form-control"
                                tabIndex={1}
                                value={formData.legalname} 
                                name='legalname'
                                onChange={handleInputChange} />
                    {inputErrors.legalname && <div className="error">{ inputErrors.legalname }</div>}
                </MDBCol>
                <MDBCol md='6' className="px-4  mb-3">
                    <span className='label required'>Mobile</span>
                    <PhoneInput placeholder='Enter your phone number'
                                value={formData.mobile}
                                tabIndex={2}
                                className='form-control bg-light-dark h-12 rounded-2' 
                                onChange={onPhoneNumberChanged} />
                    {inputErrors.mobile && <div className="error">{ inputErrors.mobile }</div>}
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className='label required'>Birthday</span>
                    <DatePicker selected={new Date(formData.birth ?? new Date())}
                                tabIndex={3}
                                className="form-control w-100"
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholder="Your birthday"
                                onChange={(date) => onBirthChanged(date)} />
                    {inputErrors.birth && <div className="error">{ inputErrors.birth }</div>}
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className="label required">Country</span>
                    <CountryDropdown className="form-control w-100"
                                tabIndex={4}
                                value={formData.country}
                                placeholder="Your Country"
                                onChange={(val) => onCountryChanged(val)} />
                    {inputErrors.country && <div className="error">{ inputErrors.country }</div>}
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className="label required">State</span>
                    <RegionDropdown className="form-control w-100"
                                tabIndex={5}
                                country={formData.country}
                                value={formData.state}
                                onChange={(val) => onStateChanged(val)} ></RegionDropdown>
                    {inputErrors.state && <div className="error">{ inputErrors.state }</div>}
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className="label required">City</span>
                    <MDBInput   className="form-control"
                                tabIndex={6}
                                value={formData.city} 
                                name='city'
                                onChange={handleInputChange} />
                    {inputErrors.city && <div className="error">{ inputErrors.city }</div>}                            
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className="label required">Address</span>
                    <MDBTextArea   className="form-control"
                                tabIndex={7}
                                value={formData.address} 
                                name='address'
                                rows={2}
                                onChange={handleInputChange} />
                    {inputErrors.address && <div className="error">{ inputErrors.address }</div>}
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className="label">ZIP Code</span>
                    <MDBInput   className="form-control"
                                tabIndex={8}
                                value={formData.zipcode} 
                                name='zipcode'
                                onChange={handleInputChange} />
                </MDBCol>
                <MDBCol md='6' className="px-4 mb-3">
                    <span className="label required">SSN</span>
                    <SSNInput value={formData.ssn} onChange={onSSNChange} />
                    {inputErrors.ssn && <div className="error">{ inputErrors.ssn }</div>}
                </MDBCol>
            </MDBRow>
        </form>
        }
    </>
})

export default AdvisorPersonalData;