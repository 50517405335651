import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBContainer, MDBIcon, MDBInput, MDBSpinner, MDBTextArea } from "mdb-react-ui-kit";
import React from "react";
import styles from './Advisor.scss';
import PersonalData from "./Partials/PersonalData";
import { useEffect } from "react";
import ApiService from "../../../Core/Service/ApiService";
import { useState } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SkillServiceData from "./Partials/SkillServiceData";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PendingStatus, UserStatus } from "../../../Constants/constants";
import Select from "react-select";
import { toast } from "react-toastify";
import { getBackendAssetUrl, v_url, validateForm } from "../../../Utils/utils";

const AdminAdvisorCheckSignUp = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [advisor, setAdvisor] = useState(null);
    const params = useParams();
    const navigate = useNavigate();
    const aId = params.aId;
    const pendingStatusOpts = [
        { value: UserStatus.APPROVED, label: "Approve" },
        { value: UserStatus.DECLINE_ADVISOR, label: "Decline"},
    ];
    const [updating, setUpdating] = useState(false);
    const [formData, setFormData] = useState({
        status: '',
        memo: ''
    })
    const rules = {
        status: {
            required: 'string'
        }
    }
    const [inputErrors, setInputErrors] = useState({})

    useEffect(()=>{
        setLoading(true);

        const url = `/admin/advisor/pending/${aId}`;
        ApiService.get(url).then(result=>{
            const advisor = result.data.advisor;
            setAdvisor(advisor);
            setFormData({
                ...formData,
                memo: advisor.memo
            })
        })
        .finally(()=>{
            setLoading(false);
        })
    }, []);

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const onPendingStatusChg = (opt) => {
        setFormData({
            ...formData,
            status: opt.value
        })
    }

    const onDeclineReasonChanged = (e) => {
        setFormData({
            ...formData,
            memo: e.target.value
        })
    }

    const handleApprove = (e) => {
        e.preventDefault();

        const valid_ret = validateForm(formData, rules);
        if(valid_ret.result) {
            setInputErrors({});

            if(window.confirm("Are you sure to save?") == false) return;

            setUpdating(true);
    
            const data = {
                ...formData,
                aId: aId,
            }
            ApiService.post('/admin/advisor/approve', data).then(result => {
                toast.success('User status changed successfully');
                navigate(v_url('/admin/advisor/insert/list'));
            })
            .finally(()=>{
                setUpdating(false);
            })
        } else {
            setInputErrors(valid_ret.errors);
        }
    }

    const breadcrumb = [];
    breadcrumb.push({title: 'Applicant', url:'/admin/advisor/insert/list'});
    breadcrumb.push({title: 'Show'});

    return <MDBContainer breakpoint="lg" className="admin-advisor-show-container">
        <Breadcrumb breadcrumb={breadcrumb} className='mb-4' />
        <MDBCard>
            <MDBCardHeader>
                <MDBCardTitle>
                    <MDBIcon fas icon="user-circle" size="lg" className="me-1" />
                    <span className="accordion-item-title">Personal Information</span>
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <PersonalData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>
            
        <MDBCard className="mt-4">
            <MDBCardHeader>
                <MDBCardTitle>
                    <MDBIcon fas icon="atom" size='lg' className="me-1" />
                    <span className="accordion-item-title">Services</span>
                </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardBody>
                { advisor && <SkillServiceData advisor={advisor} /> }
            </MDBCardBody>
        </MDBCard>

        {advisor?.video && (
            <MDBCard className="mt-4">
                <MDBCardHeader>
                    <MDBCardTitle>
                        <MDBIcon fas icon="video" className="me-1" />
                        <span className="accordion-item-title">Introduction Video</span>
                    </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody className="d-flex justify-content-center p-4">
                    <video 
                        src={getBackendAssetUrl(advisor.video)}
                        style={{width: 400, height: 300}}
                        controls
                    />
                </MDBCardBody>
            </MDBCard>
        )}

        <div className="mt-4 w-100">
            <div className="d-flex">
                <div className="pt-1" style={{width: 150}}>Advisor Status:</div>
                <div style={{width: 150}}>
                    <Select 
                        options={pendingStatusOpts} 
                        onChange={opt=>onPendingStatusChg(opt)} 
                        value={pendingStatusOpts.find(opt=>opt.value == formData.status)} 
                    />
                    {inputErrors.status && <div className="error">{ inputErrors.status }</div> }
                </div>
            </div>

            <div className="mt-3 d-flex">
                <div style={{width: 150}}>Note:</div>
                <div className="flex-fluid">
                    <MDBTextArea 
                        rows={4} 
                        value={formData.memo}
                        onChange={onDeclineReasonChanged}
                    />
                </div>
            </div>
        </div>

        <div className="d-flex justify-content-end mt-4">
            <MDBBtn onClick={handleApprove} className="mx-2">
                {updating && <>
                    <MDBSpinner size='sm' role='status' tag='span' className='me-2' />
                    Saving
                </>}
                {!updating && <span>Save</span>}
            </MDBBtn>
        </div>
        {loading && <LoadingIndicator />}
    </MDBContainer>
});

export default AdminAdvisorCheckSignUp;