import { createSelector, createSlice } from '@reduxjs/toolkit';
import JwtService from '../../Core/Service/JwtService';
import { ChatStatus } from '../../Constants/constants';

let initialChat = JwtService.getChat();
if(initialChat == null ){
    initialChat = {
        chatId: '',
        client: null,
        advisor: null,
        rate_per_min_chat: 0,
        status: '',
        isChatting: false,
        createdAt: null,
        current_session_startedAt: null,
        current_session_pausedAt: null,
        sessionTime: 0,             // in seconds
        chatTime: 0,
        amount: 0,
    }
}

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        chat: initialChat
    },
    reducers: {
        createChat: (state, action) => {
            console.log(`a new chat created. cId=${action.payload.client._id}, aId=${action.payload.advisor._id}, chatId=${action.payload.chatId}`);
            state.chat = {
                client:action.payload.client,
                advisor: action.payload.advisor,
                chatId: action.payload.chatId,
                rate_per_min_chat: action.payload.rate_per_min_chat,
                status: action.payload.status,
                freeMinutes: action.payload.freeMinutes,
                createdAt: new Date(new Date().getTime() - action.payload.secondsFromCreation * 1000).getTime(),
                isChatting: false,
                pausedAt: null,
                current_session_startedAt: null,
                chatTime: 0,
                amount: 0
            }
            JwtService.setChat(state.chat);
        },
        updateChat: (state, action) => {
            const chatInfo = action.payload;
            console.log('chat status changed: ', chatInfo);

            state.chat = {
                ...state.chat,
                ...chatInfo,
            }
            if(chatInfo.secondsFromCreation) {
                state.chat.createdAt = new Date(new Date().getTime() - chatInfo.secondsFromCreation*1000).getTime()
            }

            if(chatInfo.isChatting) {
                state.chat.current_session_startedAt = new Date().getTime() - chatInfo.passedSeconds * 1000;
            } else {
                if(chatInfo.status === ChatStatus.PAUSED) {
                    console.log('paused: passedSeconds = ' + chatInfo.passedSeconds);
                    state.chat.current_session_pausedAt = new Date().getTime() - (chatInfo.passedSeconds ?? 0) * 1000;
                }
            }

            JwtService.setChat(state.chat);
        },
        endChat: (state, action) => {
            console.log('end chat');
            state.chat.status = ChatStatus.ENDED;
            state.chat.isChatting = false;
            if(action.payload.chatTime) state.chat.chatTime = action.payload.chatTime;
            if(action.payload.amount) state.chat.amount = action.payload.amount;
            JwtService.setChat(state.chat);
        },
        removeChat: (state, action) => {
            state.chat = {
                client: null,
                advisor: null,
                chatId: '',
                status: 'none',
                isChatting: false,
                createdAt: null,
                pausedAt: null,
                current_session_startedAt: null,
                chatTime: 0,
                amount: 0
            };
            JwtService.destroyChat();
        }
    }
});

export const {createChat, updateChat, endChat, removeChat} = chatSlice.actions;

export const getChat = (state) => state.chat.chat;
export const getCurrentChatStatus = (state) => state.chat.chat?.status;
export const IsChatCreated = (state) => state.chat.chat?.chatId ? true : false;

// Create a memoized selector using reselect
export const memoizedGetChat = createSelector(
    getChat,
    (chat) => chat
);

export default chatSlice.reducer;