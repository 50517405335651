import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link } from "react-router-dom";
import styles from './Client.scss';
import { MDBBtn, MDBCheckbox, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import { getAvatarUrl } from "../../../Utils/utils";
import Select from "react-select";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";

const AdminNewClientList = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1
    });
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'username', title: 'Username'},
        {name: 'email', title: 'Email'},
        {name: 'mobile', title: 'Mobile'},
        {name: 'birth', title: 'Birthday'},
        {name: 'balance', title: 'Balance'},
        {name: 'online', title: 'Online'},
        {name: 'action', title: 'Action'},
        {name: 'changed_avatar_url', title: 'Changed Url'},
    ];
    const modalContext = useContext(ModalContext);

    useEffect(()=>{
        getClients();
    }, [formData]);

    const getClients = () => {
        setLoading(true);

        ApiService.post('/admin/client/index', formData).then(result=>{
            setClients(result.data.clients);
            setTotal(result.data.total);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }
    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
        setCurPerPageOpt(opt);
    }
    const onUserStatusChanged = (e, row) => {
        setLoading(true);

        const user_status = e.target.checked ? UserStatus.APPROVED : UserStatus.SUSPENDED;
        const data = {
            userId: row._id,
            user_status
        }
        ApiService.post('/admin/user/status', data).then(result => {
            toast.success(user_status === UserStatus.APPROVED ? 'User has been approved' : 'User has been suspended');
            getClients();
        })
        .catch(error=>{
            setLoading(false);
        })
        .finally(()=>{

        })
    }
    const handleCreditBack = (client) => {
        modalContext.setModalData({user: client});
        modalContext.setOpenCreditBackModal(true);
    }
    const handleChangeUrl = (email) => {
        const payload = {
            email: email
        };
        ApiService.post('/admin/client/changeAvatarUrl', payload).then(result => {
            toast.success(user_status === UserStatus.APPROVED ? 'User has been approved' : 'User has been suspended');
            console.log(result);
            getClients();
        })
        .catch(error=>{
            setLoading(false);
        })
        .finally(()=>{

        })
    }
    const breadcrumb=[{title: 'Client'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="clients-table">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span>{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end mb-2">
                    <div className="me-2">
                        <Select className="per-page"
                                    options={perPageOpts} 
                                    onChange={(opt) => onPerPageChanged(opt)} 
                                    defaultValue={curPerPageOpt}
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyPress={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </div>
            </div>

            <AdminTable columns={columns} rows={clients}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'username':
                            return (
                                <div className="d-flex align-items-center">
                                    <img src={getAvatarUrl(row['avatar'])} className="client-avatar me-1" />
                                    {row.username} 
                                </div>
                            )
                        case 'favorite_advisors':
                            return  (
                                row['favorite_advisors']?.map((fa, faIndex)=>(
                                    <div key={faIndex}>
                                        <Link to={`/admin/advisor/show/service/${fa._id}`}>{fa.username}</Link>
                                    </div>
                                ))
                            )
                        case 'online':
                            const online_status = row['online'] === true ? 'online' : 'offline';
                            return (
                                <div className={`online-status ${online_status.toLowerCase()}`}>
                                    {online_status}
                                </div>
                            )

                        case 'action':
                            return (<div className="d-flex align-items-center">
                                <MDBSwitch checked={row['user_status'] === UserStatus.APPROVED} 
                                    onChange={(e) => onUserStatusChanged(e, row)} 
                                    title={row['user_status'] === UserStatus.APPROVED ? 'Suspend User' : 'Approve User'}
                                />
                                <MDBIcon className="ms-1 cursor-pointer" 
                                    far icon="credit-card" 
                                    size="lg" 
                                    onClick={e=>handleCreditBack(row)}
                                    title='Credit Back' 
                                />
                            </div>)
                        case 'changed_avatar_url':
                            return (<div className="d-flex align-items-center">
                                { !row['changed_avatar_url'] && (
                                    <MDBBtn className="py-2 px-4 btn-primary" color="default" onClick={() => handleChangeUrl(row['email'])}>
                                        Change Url
                                    </MDBBtn>
                                )}
                            </div>)
                    }
                    return null;
                }}
            </AdminTable>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
        </>
    )
});

export default AdminNewClientList;