import React from "react";
import styles from './articles.scss'
import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { scrollUpTop } from "../../../Utils/utils";
import {Helmet} from 'react-helmet';

const ConflictResolve = React.memo((props) => {
    useEffect(()=>{
        scrollUpTop();
    }, []);

    return <div className="w-100 article-container">
        <Helmet>
            <link rel="canonical" href="https://confideas.com/v0.28/blog/conflict_resolution" />
        </Helmet>
    <MDBContainer breakpoint="lg" className="p-4 article">
        <section className="article_header">
            <h4 className="article_title">Real Person Consulting</h4>
            <div className="mt-4 text-center">
                <img className="article_image" src='/img/others/articles/conflict_resolve.jpg' />
            </div>
            <div className="article_short_desc mt-4 p-4">
                Discover powerful insights from experts and psychics with live chat confidential support 24/7.
            </div>
        </section>

        <section className="article_body mt-4">
            <p>
                It's likely to discern from a natal analysis whether an individual would have encountered potential disputes and conflicts that may eventually have to be settled in court. 
                For example, in ancient Asian astrological charts (e.g., Purple Star Astrology) and BaZi (i.e., Eight Characters), it can even be taken a step further to infer when this major turbulence might occur.
            </p>
            <p>
                Of course, in the web of conflicts, seeking professional guidance is crucial. Despite that, psychic insights should not substitute for the officially established relationship between a lawyer and a client. However, chatting with psychics can provide an alternative view to help those who are in confusion and chaos to understand their potential solutions. 
                While utilizing psychic insight, it's important to understand that it is for entertainment purposes only.            
            </p>
            <p>
                When people are involved in conflicts or disputes, the underlying causes usually date back some time. 
                Additionally, during chaotic moments, individuals need to pay more attention to remain focused. 
                In this regard, psychic insights can help clear minds and assist in determining whether the right people are advocating for them and navigating their cases.
            </p>
            <p>
                To reach a win-win situation, understanding your counterparty's motives is essential. 
                Our advisors at Confideas can empathize with your pain in this process. 
                They can be your best listeners while keeping your chats confidential. 
                While we do not attempt to predict the ultimate outcomes of your cases, we will walk through your journey with you.
            </p>
            <p>
                Our 24/7 live chat support ensures you feel supported and aims to alleviate the weight off your shoulders. 
                This helps you remain calm while communicating with those involved in your case.
            </p>
        </section>
    </MDBContainer>
    </div>
});

export default ConflictResolve;