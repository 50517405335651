import { MDBBtn, MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import ClientAdvisorChatHistory from "./AdvisorChatHistory";
import { useNavigate, useParams } from "react-router-dom";
import './AdvisorDetail.scss'
import ClientAdvisorStatistics from "./Statistics";
import FloatBar from "../../../Components/FloatBar/FloatBar";
import SVG from 'react-inlinesvg';
import { getAvatarUrl, v_url } from "../../../Utils/utils";
import { useState, useEffect } from "react";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import ApiService from "../../../Core/Service/ApiService";
import { useSelector } from "react-redux";
import { IsChatSocketConnected } from "../../../Store/Reducer/socketReducer";

const ClientAdvisorDetail = React.memo((props) => {
    const params = useParams();
    const aId = params.aId;
    const [adStatistics, setAdStatistics] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const isChatSockConnected = useSelector(IsChatSocketConnected);

    useEffect(()=>{
        {(async ()=>{
            try {
                setLoading(true);
                let response = await ApiService.get(`/client/advisor/${aId}/statistics`);
                setAdStatistics(response.data);
            } finally {
                setLoading(false);
            }
        })()}
    }, [aId]);

    const renderFloatBar = () => {
        return (
            <FloatBar triggerY={145} mobile_float_y={60} pc_float_y={80}>
                <MDBBtn color='none' tag='a' className='p-1' onClick={goBack} >
                    <SVG src='/img/icons/angle-left.svg' className="go-back-icon" />
                </MDBBtn>
                <img className="ms-1 circle-avatar-30" src={getAvatarUrl(adStatistics?.advisor?.avatar)} />
                <span className='ms-2'>{ adStatistics?.advisor?.username }</span>
            </FloatBar>
        )
    }

    const goBack = () => {
        navigate(v_url('/client/chat/last_by_advisor'))
    }

    return (
        <MDBContainer breakpoint="lg" className="client-advisor-detail p-2 p-md-4 position-relative">
            {renderFloatBar()}
            <div className="go-back-bar">
                <MDBBtn color='none' 
                    tag='a'
                    className='p-1' 
                    onClick={goBack}
                >
                    <SVG className="go-back-icon" src='/img/icons/angle-left.svg' />
                </MDBBtn>
            </div>

            <ClientAdvisorStatistics adStatistics={adStatistics} />
            <ClientAdvisorChatHistory aId={aId} />

            {loading && isChatSockConnected && <LoadingIndicator fullScreen={true} />}
        </MDBContainer>
    )
});

export default ClientAdvisorDetail;