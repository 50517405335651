import { MDBContainer, MDBTable, MDBTableBody, MDBTableHead, MDBInput, MDBIcon, MDBBtn, MDBSwitch, MDBTooltip, MDBPopover, MDBPopoverHeader, MDBPopoverBody } from "mdb-react-ui-kit";
import React from "react";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Moment from "react-moment";
import Paginator from "../../../Components/Paginator/Paginator"
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import SVG from 'react-inlinesvg';
import { compareDates, getAvatarUrl, text2html } from "../../../Utils/utils";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import { memoizedGetAuthUser } from "../../../Store/Reducer/authReducer";
import { BuzzType } from "../../../Constants/constants";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";
import EventContext from "../../../Context/EventContext";
import { toast } from "react-toastify";
import SearchBar from "../../../Components/SearchBar/SearchBar";

const AdvisorAllBuzzes = React.memo((props) => {
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const authUser = useSelector(memoizedGetAuthUser);
    const [loading, setLoading] = useState(false);
    const [buzzes, setBuzzes] = useState(null);
    const [total, setTotal] = useState(0);
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const [curPerPageOpt, setCurPerPageOpt] = useState(perPageOpts[0]);
    const buzzTypeOts = [
        {value: '', label: 'All'},
        {value: BuzzType.Buzz, label: 'Buzz'},
        {value: BuzzType.MissedChat, label: 'Missed Chat'}
    ];
    const [curBuzzType, setCurBuzzType] = useState(buzzTypeOts[0]);
    const repliedOpts = [
        {value: '', label: 'All'},
        {value: 'unreplied', label: 'Not Replied'},
        {value: 'replied', label: 'Replied'}
    ];
    const [curRepliedOpt, setCurRepliedOpt] = useState(repliedOpts[0]);
    const [search, setSearchClient] = useState('');
    const [searchFormData, setSearchFormData] = useState({
        search: '',
        buzzType: '',
        replied: '',
        pageNum: 1,
        perPage: 10,
    });
    const eventContext = useContext(EventContext);

    const onRepliedChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            replied: opt.value
        })
        setCurRepliedOpt(opt);
    }
    const onBuzzTypeChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            buzzType: opt.value
        });
        setCurBuzzType(opt);
    }
    const onPerPageChanged = (opt) => {
        setSearchFormData({
            ...searchFormData,
            perPage: opt.value,
            pageNum: 1
        });
        setCurPerPageOpt(opt);
    }
    const onPageChanged = (pageNum) => {
        setSearchFormData({
            ...searchFormData,
            pageNum
        });
    }
    const onSearchClientChanged = (e) => {
        setSearchClient(e.target.value);
    }
    const onSearchClientKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setSearchFormData({
                ...searchFormData,
                pageNum: 1,
                search
            });
        }
    }
    const onSearchBtnClicked = () => {
        setSearchFormData({
            ...searchFormData,
            pageNum: 1,
            search
        });
    }
    useEffect(()=>{
        getBuzzes();
    }, [searchFormData, eventContext.evtCouponSent]);

    const getBuzzes = () => {
        setLoading(true);

        ApiService.post(`/advisor/buzz/index`, searchFormData).then(result=>{
            setBuzzes(result.data.buzzes);
            setTotal(result.data.total);
        })
        .finally(error=>{
            setLoading(false);
        })
    }

    const goBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    const onSendCoupon = (e, buzz) => {
        e.preventDefault();
        if(!buzz.coupon_id) {
            modalContext.setModalData({
                client: buzz.cId,
                buzz
            })
            modalContext.setOpenSendCouponModal(true);
        }
    }

    const renderBuzzesPC = (buzzes) => {
        return (
            <section className="d-none d-sm-block">
                <MDBTable align='middle' className="confideas-table">
                    <MDBTableHead>
                        <tr>
                            <th scope='col'>Date</th>
                            <th scope='col'>Client</th>
                            <th scope="col">Message</th>
                        </tr>    
                    </MDBTableHead>
                    <MDBTableBody>
                        {(()=>{
                            if(buzzes?.length > 0) {
                                return buzzes.filter( buzz => buzz.cId ? true : false).map((buzz, index) => (
                                    <tr key={index} onClick={e => onSendCoupon(e, buzz)}>
                                        <td className="sent-at"><Moment format="YYYY-MM-DD">{buzz.createdAt}</Moment></td>
                                        <td className="client">
                                            <div className='d-flex align-items-center'>
                                                <img
                                                    src={ getAvatarUrl(buzz.cId?.avatar)}
                                                    alt=''
                                                    className='circle-avatar-40'
                                                />
                                                <span className="mx-2">{buzz.cId?.username}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className='buzz-type d-flex align-items-center'>
                                                {buzz.coupon_id && 
                                                <MDBTooltip
                                                    wrapperProps={{ color: 'link' }}
                                                    wrapperClass="p-0 text-left"
                                                    title={<div className="text-left">
                                                        {buzz.type == BuzzType.Buzz &&
                                                        <div>
                                                            <div style={{color: '#31ff00'}}>Client wrote</div>
                                                            <div className="font-italic" 
                                                                dangerouslySetInnerHTML={{ __html: text2html(buzz.message ? buzz.message : 'No message')}} 
                                                            />
                                                        </div>}
                                                        <div className="mt-2">
                                                            <div style={{color: '#31ff00'}}>I wrote</div>
                                                            <div className="font-italic" 
                                                                dangerouslySetInnerHTML={{ __html: text2html(buzz.coupon_id.message ? buzz.coupon_id.message : 'No message')}} 
                                                            />
                                                        </div>
                                                    </div>}
                                                >
                                                    <div style={{color: 'gray', textTransform: 'none'}} className="d-flex align-items-center">
                                                        <div>
                                                            <SVG 
                                                                src={`/img/icons/${buzz.type == BuzzType.MissedChat ? 'envelop_unchecked.svg' : 'bell.svg'}`} 
                                                                width={30} 
                                                                height={25} 
                                                                opacity={0.2}
                                                            />
                                                        </div>
                                                        <div className="ms-2 d-md-flex">
                                                            <span className="mx-1">{buzz.type == BuzzType.MissedChat ? 'missed call' :'Buzz'}</span>
                                                            <div className="comment" style={{textTransform:'none'}}>{buzz.coupon_id ? '(replied)' : ''}</div>
                                                        </div>
                                                    </div>
                                                </MDBTooltip>}

                                                {!buzz.coupon_id && <>
                                                    <div>
                                                        <SVG 
                                                            src={`/img/icons/${buzz.type == BuzzType.MissedChat ? 'envelop_unchecked.svg' : 'bell.svg'}`} 
                                                            width={30} 
                                                            height={25} 
                                                        />
                                                    </div>
                                                    <div className="d-md-flex ms-1">
                                                        <div className="ms-1" style={{color: '#0dab0d'}}>{buzz.type == BuzzType.MissedChat ? 'missed call' :'Buzz'}</div>
                                                    </div>
                                                </>}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            } else {
                                return <tr><td colSpan={3} className="text-center">
                                    {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                        <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Buzzes</div>
                                    </div>}
                                </td></tr>
                            }
                        })()}
                    </MDBTableBody>
                </MDBTable>
            </section>
        )
    }
    const renderBuzzesMobild = (buzzes) => {
        return (
        <section className="d-block d-sm-none">
            <MDBTable align='middle' className="confideas-table">
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Client</th>
                        <th scope="col">Message</th>
                    </tr>    
                </MDBTableHead>
                <MDBTableBody>
                    {(()=>{
                        if(buzzes?.length > 0) {
                            return buzzes.filter( buzz => buzz.cId ? true : false).map((buzz, index) => (
                                <tr key={index} onClick={e => onSendCoupon(e, buzz)}>
                                    <td className="client">
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={ getAvatarUrl(buzz.cId?.avatar)}
                                                alt=''
                                                className='circle-avatar-40'
                                            />
                                            <div className="mx-2">
                                                <Moment format="YYYY-MM-DD">{buzz.createdAt}</Moment>
                                                <div>{buzz.cId?.username}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='buzz-type d-flex align-items-center'>
                                            {buzz.coupon_id && 
                                            <MDBTooltip
                                                wrapperProps={{ color: 'link' }}
                                                wrapperClass="p-0 text-left"
                                                title={<div className="text-left">
                                                    {buzz.type == BuzzType.Buzz &&
                                                    <div>
                                                        <div style={{color: '#31ff00'}}>Client wrote</div>
                                                        <div className="font-italic" 
                                                            dangerouslySetInnerHTML={{ __html: text2html(buzz.message ? buzz.message : 'No message')}} 
                                                        />
                                                    </div>}
                                                    <div className="mt-2">
                                                        <div style={{color: '#31ff00'}}>I wrote</div>
                                                        <div className="font-italic" 
                                                            dangerouslySetInnerHTML={{ __html: text2html(buzz.coupon_id.message ? buzz.coupon_id.message : 'No message')}} 
                                                        />
                                                    </div>
                                                </div>}
                                            >
                                                <div style={{color: 'gray', textTransform: 'none'}} className="d-flex align-items-center">
                                                    <div>
                                                        <SVG 
                                                            src={`/img/icons/${buzz.type == BuzzType.MissedChat ? 'envelop_unchecked.svg' : 'bell.svg'}`} 
                                                            width={30} 
                                                            height={25} 
                                                            opacity={0.2}
                                                        />
                                                    </div>
                                                    <div className="ms-2 d-md-flex">
                                                        <span className="mx-1">{buzz.type == BuzzType.MissedChat ? 'missed call' :'Buzz'}</span>
                                                        <div className="comment" style={{textTransform:'none'}}>{buzz.coupon_id ? '(replied)' : ''}</div>
                                                    </div>
                                                </div>
                                            </MDBTooltip>}

                                            {!buzz.coupon_id && <>
                                                <div>
                                                    <SVG 
                                                        src={`/img/icons/${buzz.type == BuzzType.MissedChat ? 'envelop_unchecked.svg' : 'bell.svg'}`} 
                                                        width={30} 
                                                        height={25} 
                                                    />
                                                </div>
                                                <div className="d-md-flex ms-1">
                                                    <div className="ms-1" style={{color: '#0dab0d'}}>{buzz.type == BuzzType.MissedChat ? 'missed call' :'Buzz'}</div>
                                                </div>
                                            </>}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        } else {
                            return <tr><td colSpan={3} className="text-center">
                                {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                                    <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Buzzes</div>
                                </div>}
                            </td></tr>
                        }
                    })()}
                </MDBTableBody>
            </MDBTable>
        </section>
        )
    }

    return (<section className="advisor-all-buzzes">
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item me-2">
                        <Select 
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            defaultValue={curPerPageOpt} />
                    </div>
                    <div className="search-item me-2 w-150px">
                        <Select 
                            options={buzzTypeOts} 
                            onChange={(opt) => onBuzzTypeChanged(opt)} 
                            defaultValue={curBuzzType} 
                        />
                    </div>
                    <div className="search-item me-2 w-150px">
                        <Select 
                            options={repliedOpts}
                            onChange={(opt)=>onRepliedChanged(opt)}
                            defaultValue={curRepliedOpt}
                        />
                    </div>
                    <div className='search-item search-term'>
                        <MDBInput type="text" 
                                label="Search Client"
                                value={search}
                                onChange={onSearchClientChanged}
                                onKeyDown={onSearchClientKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>
            </div>

            <ScrollHorizon className="buzz-list">
                { renderBuzzesPC(buzzes) }
                { renderBuzzesMobild(buzzes) }
                {loading && <LoadingIndicator /> }
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator curPage={searchFormData.pageNum} total={total} perPage={searchFormData.perPage} onPageChanged={onPageChanged} />
            </div>
        </section>
    )
});

export default AdvisorAllBuzzes;