import React, {useState , useEffect} from "react";
import { toast } from 'react-toastify';
import SVG from 'react-inlinesvg';
import './CommPanel.scss';
import { MDBBtn, MDBIcon, MDBSpinner } from "mdb-react-ui-kit";
import { useSelector } from "react-redux";
import { IsAuthenticated, memoizedGetAuthUser } from "../../Store/Reducer/authReducer";
import { SettingFields, TEST_MODE, UserRole } from "../../Constants/constants";
import ApiService from "../../Core/Service/ApiService";
import { useContext } from "react";
import ModalContext from "../../Context/ModalContext";
import { getChangedAdvisors, getChangedAdvisorsHash256 } from "../../Store/Reducer/changedAdvisorReducer";
import JwtService from "../../Core/Service/JwtService";
import { floatV } from "../../Utils/utils";

const CommPanel = React.memo((props) => {
    const [checkingAvailCoupon, setCheckingAvailCoupon] = useState(false);
    const authUser = useSelector(memoizedGetAuthUser);
    const isAuthenticated = useSelector(IsAuthenticated);
    const modalContext = useContext(ModalContext);
    const [advisorUpdtInfo, setAdvisorUpdtInfo] = useState();
    const changedAdvisorHash = useSelector(getChangedAdvisorsHash256);
    const changedAdvisors = useSelector(getChangedAdvisors);

    useEffect(()=>{
        updateAdvisorInfo(props.advisor);
    }, [props.advisor]);

    const updateAdvisorInfo = (adInfo) => {
        const info = {...adInfo};
        if(info.isLoggedIn != true) {
            info.serviceChatStatus = 'offline';
        } else {
            if(info.isBusy) {
                info.serviceChatStatus = 'busy';
            } else {
                info.serviceChatStatus = info.isAvailableChat ? 'online' : 'offline';
            }
        }
        setAdvisorUpdtInfo(info);
    }

    useEffect(()=>{
        if(changedAdvisorHash && advisorUpdtInfo) {
            const chgAdvisor = changedAdvisors.find(a=>a._id === advisorUpdtInfo?._id);
            if(chgAdvisor) {
                updateAdvisorInfo({
                    ...advisorUpdtInfo,
                    rate_per_min_chat: chgAdvisor.rate_per_min_chat,
                    isLoggedIn: chgAdvisor.isLoggedIn,
                    online: chgAdvisor.online,
                    isBusy: chgAdvisor.isBusy,
                    isAvailableChat:chgAdvisor.isAvailableChat,
                    isAvailableCall:chgAdvisor.isAvailableCall,
                    isAvailableVideo:chgAdvisor.isAvailableVideo,
                });
            }
        }
    }, [changedAdvisorHash])


    const onChatBtnClicked = async () => {
        if(!isAuthenticated) {
            modalContext.setOpenSignInModal(true);
            return;
        }
        if(authUser?.role != UserRole.CLIENT) {
            toast.warn("Only clients could use this service.");
            return;
        }

        // To check if the client has got coupon from the advisor
        try {
            setCheckingAvailCoupon(true);

            let response = await ApiService.get('/client/setting/index');
            const chatMinTime = floatV(response.data[SettingFields.CHAT_MIN_TIME]);

            response = await ApiService.get('/client/coupon/available/' + advisorUpdtInfo._id);
            if(!response.data.coupon && authUser.balance < (chatMinTime-1) * advisorUpdtInfo.rate_per_min_chat) {
                // if client's balance is less than limit, then goto payment page.
                // Upon payment made with success, it would emit request_chat message.
                JwtService.setPaymentRequest({
                    purpose: 'request_chat',
                    advisor: advisorUpdtInfo,
                });
                modalContext.setOpenPayAmountModal(true);
                return;
            } else {
                modalContext.setModalData({advisor: advisorUpdtInfo});
                modalContext.setOpenEnoughBalanceModal(true);
            }
        } finally {
            setCheckingAvailCoupon(false);
        }
    }

    return (
    <div className={`advisor-comm d-flex ${props.miniVersion ? 'mini-version' : ''}`}>
        <MDBBtn className={`chat-btn flex-fluid ${advisorUpdtInfo?.serviceChatStatus ?? 'offline'}`} 
                disabled={advisorUpdtInfo?.serviceChatStatus != 'online' || checkingAvailCoupon}
                onClick={onChatBtnClicked} 
                style={{textTransform: 'none'}}
        >
            {checkingAvailCoupon && <MDBSpinner role='status' size="sm" />}
            {!checkingAvailCoupon && <SVG src='/img/icons/message.svg' alt='Chat' />}
            {!props.miniVersion && <span className="ms-2">{ advisorUpdtInfo?.rate_per_min_chat } / min</span>}
        </MDBBtn>
    </div>

    )
});

export default CommPanel;