import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import styles from './Advisor.scss';
import { MDBCheckbox, MDBIcon, MDBInput, MDBSpinner, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Select from "react-select";
import { getAvatarUrl, v_url } from "../../../Utils/utils";
import { useContext } from "react";
import ModalContext from "../../../Context/ModalContext";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import SVG from 'react-inlinesvg';

const AdminAdvisorList = React.memo((props) => {
    const navigate = useNavigate();
    const modalContext = useContext(ModalContext);
    const [loading, setLoading] = useState(false);
    const [advisors, setAdvisors] = useState([]);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        listing: '',
        userStatus: ''
    });
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];
    const listingOpts = [
        {value: '', label: 'All'},
        {value: 'listing', label: 'Listing'},
        {value: 'not-listing', label: 'Not Listing'}
    ];
    const [curListingOpt, setCurListingOpt] = useState(listingOpts[0]);
    const [total, setTotal] = useState(0);
    const [editTarget, setEditTarget] = useState(null);

    useEffect(()=>{
        getAdvisors();
    }, [formData]);

    const getAdvisors = () => {
        setLoading(true);

        ApiService.post('/admin/advisor/index', formData).then(result=>{
            setAdvisors(result.data.advisors);
            setTotal(result.data.total);
        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
    }
    const onListingChanged = (opt) => {
        setFormData({
            ...formData,
            listing: opt.value,
            pageNum: 1
        });
        setCurListingOpt(opt);
    };

    const gotoDetail = (e, advisor) => {
        e.preventDefault();
        navigate(v_url('/admin/advisor/edit/service/' + advisor._id));
    }

    const renderAdvisors = () => {
        if(advisors?.length > 0) {
            return advisors.map((advisor, index) => (
                <div 
                    className="d-flex underline underline-5px mb-3 cursor-pointer" 
                    onClick={e=>gotoDetail(e, advisor)}
                    key={index}
                >
                    <div className="flex-fluid d-flex align-items-center p-2">
                        <img src={getAvatarUrl(advisor.avatar)} className="circle-avatar-40" />
                        <div className="ms-2">
                            <div className="d-flex align-items-center">
                                <div>{advisor.username}</div>
                                <div className={`ms-2 list-status ${advisor.listing ? 'listing' : ''}`}>
                                    { advisor.listing ? 'listing' : 'non-listing' }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <MDBIcon fas icon="angle-right" size="lg" />
                    </div>
                </div>
            ))
        } else {
            {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Advisors</div>
            </div>}
        }
    }

    const breadcrumb=[{title: 'Advisor'}, {title: 'List'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="advisors-list">
            <div className="confideas-table-header">
                <div className="">
                    <label>Total : </label>
                    <span className="ps-2">{total}</span>
                </div>
                <SearchBar>
                    <div className="search-item me-2">
                        <Select 
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            value={perPageOpts.find(opt=>opt.value == formData.perPage)}
                        />
                    </div>
                    <div className="search-item me-2" style={{width: 150}}>
                        <Select 
                            options={listingOpts}
                            onChange={(opt) => onListingChanged(opt)}
                            defaultValue={curListingOpt}
                        />
                    </div>
                    <div className="search-item search-term">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyPress={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </SearchBar>
            </div>

            <div>
                { renderAdvisors() }
            </div>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminAdvisorList;