import React, {useState, useRef, useEffect} from 'react';
import SVG from 'react-inlinesvg';
import classname from 'classnames';
import { 
    MDBContainer, 
    MDBIcon, 
    MDBNavbar, 
    MDBNavbarBrand, 
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem
 } from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApiService from '../../Core/Service/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { purgeAuth, IsAuthenticated, getAdvisorServiceStatus, memoizedGetAuthUser } from '../../Store/Reducer/authReducer';
import { getAvatarUrl, v_url } from '../../Utils/utils';
import { UserRole, UserStatus } from '../../Constants/constants';
import ModalContext from '../../Context/ModalContext';
import { useContext } from 'react';
import { ackAlarm, getAlarmCount, getAlarms, removeAlarms } from '../../Store/Reducer/alarmReducer';
import Moment from 'react-moment';
import { removeChangedAdvisors } from '../../Store/Reducer/changedAdvisorReducer';
import Logo from '../Partial/Logo';
import { getTotalUnreadChatOffMsgCount, setChatOffThreads } from '../../Store/Reducer/chatoffMsgReducer';
import ChatOffThreads from '../../Components/ChatOffThread/ChatOffThread';
import { IsChatSocketConnected } from '../../Store/Reducer/socketReducer';
import { getPreviousUrl, popHistory } from '../../Store/Reducer/urlHistoryReducer';

const Header = React.memo(() => {
    const location = useLocation();
    const { pathname } = location;
    const previousUrl = useSelector(getPreviousUrl);

    const menuItems = {
        client: {
            myPage: {
                title: 'Account Details', 
                svg_icon:'account_detail.svg', 
                name: 'cl_mypage',
                href: v_url('/client/mypage')
            },
            myWallet: {
                title: 'My Wallet', 
                svg_icon: 'wallet.svg', 
                name: 'cl_mywallet',
                href: v_url('/client/mywallet')
            },
            message: {
                title: 'Message',
                icon: 'envelope',
                name: 'cl_message',
                href: v_url('/client/message')
            },
            coupons: {
                title: 'Coupons', 
                icon: 'award', 
                name: 'cl_coupons',
                href: v_url('/client/coupon/all')
            },
            buzzes: {
                title: 'Buzzes', 
                icon: 'phone', 
                name: 'cl_buzzes',
                href: v_url('/client/buzz/all')
            },
            chatHistory: {
                title: 'Chat History', 
                svg_icon: 'chat-history.svg', 
                name: 'cl_chat_history',
                href: v_url('/client/chat/last_by_advisor')
            }
        },
        advisor: {
            dashboard: {
                title: 'Dashboard', 
                svg_icon: 'dashboard.svg', 
                name: 'adv_dashboard',
                href: v_url('/advisor/dashboard')
            },
            myContacts: {
                title: 'My Info', 
                svg_icon: 'contact-card.svg', 
                name: 'adv_mycontacts',
                href: v_url('/advisor/edit/contacts')
            },
            myListing: {
                title: 'My Listing',
                svg_icon: 'service.svg',
                name: 'adv_mylisting',
                href: v_url('/advisor/edit/service')
            },
            payout: {
                title: 'Payout', 
                svg_icon: 'pay.svg', 
                name: 'adv_payout',
                href: v_url('/advisor/payout')
            },
            message: {
                title: 'Message',
                icon: 'envelope',
                name: 'adv_message',
                href: v_url('/advisor/message')
            },
            coupons: {
                title: 'Coupons', 
                icon: 'award', 
                name: 'adv_coupons',
                href: v_url('/advisor/coupon/all')
            },
            buzzes: {
                title: 'Buzzes', 
                icon: 'phone', 
                name: 'adv_buzzes',
                href: v_url('/advisor/buzz/all')
            },
            chatHistory: {
                title: 'Chat History', 
                svg_icon:'chat-history.svg', 
                name: 'adv_chat_history',
                href: v_url('/chat/history')
            },
        },
        admin: {
            dashboard: {
                title: 'Dashboard', 
                svg_icon: 'dashboard.svg', 
                name: 'adm_dashboard',
                href: v_url('/admin/dashboard')
            },
        },
    
        allAdvisors: {
            title: 'Explore Advisors',
            svg_icon: 'house.svg',
            name: 'all_advisors',
            href: v_url('/all_advisors')
        },
        changePasswd: {
            title: 'Change Password', 
            svg_icon: 'password-key.svg', 
            name: 'change_password',
            onClick: (e) => {
                modalContext.setOpenChangePasswdModal(true);
            }
        },
        login: {
            title: 'Log in', 
            svg_icon: 'login.svg', 
            name: 'login',
            onClick: (e) => {
                toggleOpenSignInModal();
            }
        },
        signup: {
            title: 'Register', 
            svg_icon: 'user-add.svg', 
            name: 'signup',
            onClick: (e) => {
                toggleOpenSignUpModal();
            }
        },
        logout: {
            title: 'Log out', 
            svg_icon: 'sign-out.svg', 
            name: 'logout',
            onClick: (e) => {
                handleLogout();
            }
        },
        customerSupport: {
            title: 'Customer Support',
            svg_icon: 'customer-service.svg',
            name: 'customer_support',
            onClick: (e) => {
                modalContext.setOpenSendOpinionModal(true);
            }
        },
        becomeAdvisor: {
            title: 'Become an Advisor', 
            svg_icon: 'user.svg',
            name: 'become_advisor',
            href: v_url('/advisor_auth/signup')
        },
        info: {
            title: 'About Confideas',
            svg_icon: 'info-circle.svg',
            name: 'info',
            href: v_url('/about_us')
        },
        splitterBar: {
            splitterBar: true
        }
    }
    const modalContext = useContext(ModalContext);
    const dispatch = useDispatch();
    const [adServiceStatus, setAdServiceStatus] = useState({
        isAvailableChat: true,
        isAvailableCall: true,
        isAvailableVideo: true,
    })
    const isAuthenticated = useSelector(IsAuthenticated);
    const authUser = useSelector(memoizedGetAuthUser);
    const advisorServiceStatus = useSelector(getAdvisorServiceStatus);
    const isChatSockConnected = useSelector(IsChatSocketConnected)
    const alarmCount = useSelector(getAlarmCount);
    const alarms = useSelector(getAlarms);
    const total_unread_msg_count = useSelector(getTotalUnreadChatOffMsgCount);
    const navigate = useNavigate();
    const [authUserAvatar, setAuthUserAvatar] = useState('');
    const [openDrawerNavbar, setOpenDrawerNavbar] = useState(false);
    const [currentMenu, setCurrentMenu] = useState('home');
    const [showAlarm, setShowAlarm] = useState(false);
    const [showChatOffThreads, setShowChatOffThreads] = useState(false);
    const [topHeaderMenuItems, useSetTopHeadermenuItems] = useState([menuItems.allAdvisors]);
    const [userActionMenuItems, setUserActionMenuItems] = useState([]);
    const [mobileMenuItems, setMobileMenuItems] = useState([]);

    useEffect(()=>{
        if(authUser?.avatar) {
            setAuthUserAvatar(authUser.avatar);
        }
    }, [authUser?.avatar]);

    useEffect(()=>{
        if(isAuthenticated) {
            setShowAlarm(alarmCount > 0);
        }
    }, [alarmCount, isAuthenticated])

    useEffect(()=>{
        setAdServiceStatus(advisorServiceStatus);
    }, [advisorServiceStatus]);

    useEffect(()=>{
        buildMenuPC();
        buildMenuMobile();
    }, [isAuthenticated]);

    useEffect(()=>{
        if(isChatSockConnected && (authUser?.role == UserRole.CLIENT || authUser?.role == UserRole.ADVISOR)) {
            ApiService.get('/chatoff_thread/index').then(response=>{
                dispatch(setChatOffThreads(response.data));
            })
            ApiService.get('/user/myalarms').then(result=>{
                if(result.data.alarms?.length > 0) dispatch(addAlarms(result.data.alarms));
            })
        }
    }, [isChatSockConnected])

    useEffect(() => {
        for(let i=0; i<mobileMenuItems.length; i++) {
            if(pathname === mobileMenuItems[i].href) {
                setCurrentMenu(mobileMenuItems[i].name);
                break;
            }
        }
    }, [pathname, mobileMenuItems])

    useEffect(()=>{
        if(openDrawerNavbar || showAlarm || showChatOffThreads) {
            document.addEventListener('mousedown', handleOutsideClick);
            return () => document.removeEventListener('mousedown', handleOutsideClick);
        }
    },[openDrawerNavbar, showAlarm, showChatOffThreads])

    const buildMenuPC = () => {
        const uaMenuItems = [];
        const topHeaderMenuItems = [menuItems.allAdvisors];

        if(isAuthenticated && authUser) {
            if(authUser.role === UserRole.CLIENT) {
                uaMenuItems.push(menuItems.client.myPage);
                uaMenuItems.push(menuItems.client.chatHistory);
                uaMenuItems.push(menuItems.client.myWallet);
                uaMenuItems.push(menuItems.changePasswd);
                uaMenuItems.push(menuItems.logout);
            } else if(authUser.role === UserRole.ADVISOR) {
                if(authUser.status === UserStatus.APPROVED) {
                    uaMenuItems.push(menuItems.advisor.dashboard);
                    if(authUser.listing) {
                        uaMenuItems.push(menuItems.advisor.myContacts);
                        uaMenuItems.push(menuItems.advisor.myListing);
                        uaMenuItems.push(menuItems.advisor.payout);
                    }
                    uaMenuItems.push(menuItems.changePasswd);
                    uaMenuItems.push(menuItems.logout);

                    topHeaderMenuItems.push(menuItems.advisor.dashboard);
                } else {
                    uaMenuItems.push(menuItems.logout);
                }
            } else if(authUser.role === UserRole.ADMIN) {
                uaMenuItems.push(menuItems.admin.dashboard);
                uaMenuItems.push(menuItems.changePasswd);
                uaMenuItems.push(menuItems.logout);
            }
        } else {
            uaMenuItems.push(menuItems.login);
            uaMenuItems.push(menuItems.signup);
        }

        setUserActionMenuItems(uaMenuItems);
        useSetTopHeadermenuItems(topHeaderMenuItems);
    }
    const buildMenuMobile = () => {
        const moMenuItems = [];
        moMenuItems.push(menuItems.allAdvisors);
        moMenuItems.push(menuItems.splitterBar);

        // To add userActionMenus
        if(isAuthenticated && authUser) {
            if(authUser.role === UserRole.CLIENT) {
                moMenuItems.push(menuItems.client.chatHistory);
                moMenuItems.push(menuItems.client.myWallet);
                moMenuItems.push(menuItems.client.myPage);
                moMenuItems.push(menuItems.changePasswd);
                moMenuItems.push(menuItems.splitterBar);
            } else if(authUser.role === UserRole.ADVISOR) {
                if(authUser.status === UserStatus.APPROVED) {
                    moMenuItems.push(menuItems.advisor.dashboard);
                    if(authUser.listing) {
                        moMenuItems.push(menuItems.advisor.myContacts);
                        moMenuItems.push(menuItems.advisor.myListing);
                        moMenuItems.push(menuItems.advisor.payout);
                    }
                    moMenuItems.push(menuItems.changePasswd);
                    moMenuItems.push(menuItems.splitterBar);
                } else {
                }
            } else if(authUser.role === UserRole.ADMIN) {
                moMenuItems.push(menuItems.admin.dashboard);
                moMenuItems.push(menuItems.changePasswd);
                moMenuItems.push(menuItems.splitterBar);
            }
        }

        // To add footer menus
        {
            if(!isAuthenticated) moMenuItems.push(menuItems.becomeAdvisor);
            moMenuItems.push(menuItems.info);
            if(isAuthenticated) moMenuItems.push(menuItems.customerSupport);
            moMenuItems.push(menuItems.splitterBar);
        }

        if(isAuthenticated) {
            moMenuItems.push(menuItems.logout);
        } else {
            moMenuItems.push(menuItems.login);
            moMenuItems.push(menuItems.signup);
        }

        setMobileMenuItems(moMenuItems);
    }

    const toggleOpenSignInModal = () => {
        modalContext.setOpenSignUpModal(false);
        modalContext.setOpenSignInModal(true);
    }
    const toggleOpenSignUpModal = () => {
        modalContext.setOpenSignUpModal(true);
        modalContext.setOpenSignInModal(false);
    }

    // log out
    const handleLogout = () => {
        // localStorage.clear();
        navigate(v_url('/logout'));
    }

    const onMenuClick = (e, menu) => {
        if(menu.href) navigate(menu.href);
        if(menu.onClick) menu.onClick(e);
    }

    const handleOutsideClick = (e) => {
        if(openDrawerNavbar && !e.target.closest('.drawer-navbar')) {
            setOpenDrawerNavbar(false);
        }
        if(showAlarm && !e.target.closest('.alarms')) {
            setShowAlarm(false);
        }
        if(showChatOffThreads && !e.target.closest('.chatoff-threads-popup')) {
            setShowChatOffThreads(false);
        }
    }

    const isCurrentMenu = (menu) => {
        return currentMenu === menu;
    }

    const gotoChatOffThreadPage = () => {
        navigate(v_url('/chatoff_threads'))
    }
    const onShowChatOffThread = (e) => {
        e.preventDefault();
        setShowChatOffThreads(true);
        setShowAlarm(false);
    }
    const onShowAlarm = (e) => {
        e.preventDefault();
        setShowAlarm(alarms?.length > 0);
        setShowChatOffThreads(false);
    }
    const checkAlarm = (e, alarm) => {
        e.preventDefault();
        dispatch(ackAlarm(alarm._id));
    }
    const gotoAlarmDetail = (e, alarm) => {
        e.preventDefault();
        dispatch(ackAlarm(alarm._id));

        if(alarm.dialogContent) {
            modalContext.setModalData({alarm})
            modalContext.setOpenAlarmModal(true);
        } else if(alarm.linkUrl) {
            navigate(v_url(alarm.linkUrl));
        }
    }
    const goBack = () => {
        if(previousUrl) {
            const url = previousUrl;
            dispatch(popHistory())
            navigate(url);
        }
    }
    const canGoBack = () => {
        return previousUrl ? true : false;
    }

    // main navbar which will be displayed when user click his avatar in pc version.
    const renderTopHeaderPC = () => {
        return (
        <div className='flex-fluid d-flex justify-content-end top-navbar align-items-center'>
            <ul className='main-menu m-0'>
                {topHeaderMenuItems.map((menu, index)=>(
                    <li  key={index}
                        className={`${isCurrentMenu(menu.name) ? 'active' : ''} cursor-pointer`}
                        onClick={e => onMenuClick(e, menu)}
                    >
                        <a>
                            <span className=''>{menu.title}</span>
                        </a>
                    </li>
                ))}
            </ul>

            {isAuthenticated && authUser && (
                <div className='authed-user d-flex flex-content-end align-items-center'>
                    <div className='me-2 alarm-notification' onMouseEnter={onShowChatOffThread}>
                        <SVG className="bell-icon" 
                            src='/img/icons/envelope.svg' 
                            width={25} 
                            height={25} 
                            fill="#cfc4ff"
                            title=""
                        />
                        {total_unread_msg_count > 0 && <div className='alarm-count d-flex justify-content-center align-items-center'>{total_unread_msg_count}</div>}
                    </div>
                    
                    { alarmCount > 0 &&
                    <div className='alarm-notification me-2' onMouseEnter={onShowAlarm}>
                        <MDBIcon fas icon="bell" size='lg' style={{color: '#f99800'}} />
                        <div className='alarm-count d-flex justify-content-center align-items-center'>{alarmCount}</div>
                    </div>
                    }

                    <div className='auth-welcome d-flex flex-column justify-content-center align-items-end d-none d-sm-flex'>
                        <div className='welcome'>
                            <span className='px-1'>Welcome -</span>
                            <img src='/img/icons/png/sunflower.png' />
                        </div>
                        <div className='username text-right px-1'>{authUser.username}</div>
                    </div>

                    <MDBDropdown>
                        <MDBDropdownToggle className='bg-transparent authuser-action p-0 px-2' style={{boxShadow:'none'}}>
                            <img className='circle-avatar-40' src={getAvatarUrl(authUserAvatar)} alt=''/>
                            { authUser.role === 'advisor' && 
                                <div className={`advisor-online-status ${ adServiceStatus?.isAvailableChat ? 'online' : 'offline'}`}></div>
                            }
                        </MDBDropdownToggle>

                        <MDBDropdownMenu className='authuser-menu'>
                        { userActionMenuItems.map((menu,index) => (
                            <MDBDropdownItem key={index}
                                            aria-current={isCurrentMenu(menu.name)}
                                            className={isCurrentMenu(menu.name) ? 'active' : ''}
                                            onClick={e=>onMenuClick(e, menu)}
                            >
                                {menu.title}
                            </MDBDropdownItem>
                        ))}
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            )}
            
            {!isAuthenticated && (
                <div className='auth-action d-flex'>
                    <MDBBtn className='' color='light' onClick={toggleOpenSignUpModal} style={{textTransform:'none'}}>
                        Register
                    </MDBBtn>
                    <MDBBtn className='ms-3 confideas-primary-btn' onClick={toggleOpenSignInModal} style={{textTransform:'none'}}>
                        Log in
                    </MDBBtn>
                </div>
            )}
        </div>
        )
    }

    const renderTopHeaderMobile = () => {
        return (
        <div className='d-flex flex-row-reverse d-md-none justify-content-between align-items-center' style={{width: "calc(50% + 6px)"}}>
            {isAuthenticated && <div className='d-flex justify-content-between align-items-center'>
                <section className='me-3 menu-items'>
                    <Link to={v_url("/all_advisors")}>
                        <SVG src='/img/icons/house.svg' width={20} height={20} fill='#CBF0FF' />
                    </Link>
                { authUser?.role === UserRole.ADVISOR && authUser?.status === UserStatus.APPROVED && authUser?.listing && 
                    <Link to={v_url('/advisor/dashboard')} className='ms-3'>
                        <SVG src='/img/icons/dashboard.svg' width={20} height={20} fill='#CBF0FF' />
                    </Link>}
                </section>

                <section className='me-3 d-flex align-items-center'>
                    <div onClick={gotoChatOffThreadPage} className='alarm-notification'>
                        <SVG
                            src='/img/icons/envelope.svg' 
                            width={25} 
                            height={25} 
                            fill="#cfc4ff"
                            title="Send Buzz"
                        />
                        {total_unread_msg_count > 0 && <div className='alarm-count d-flex justify-content-center align-items-center'>{total_unread_msg_count}</div>}
                    </div>

                    { alarmCount > 0 &&
                    <div className='alarm-notification ms-3' onClick={onShowAlarm}>
                        <MDBIcon fas icon="bell" size='lg' style={{color: '#f99800'}} />
                        <div className='alarm-count d-flex justify-content-center align-items-center'>{alarmCount}</div>
                    </div>
                    }
                </section>
                
                <MDBBtn 
                    className='toggle-drawmenu' 
                    onClick={(e)=>setOpenDrawerNavbar(true)}
                >
                    <MDBIcon fas icon="bars" />
                </MDBBtn>
            </div>}
            {authUser &&
                <div style={{position:'relative'}} className='me-2'>
                    <MDBBtn tag='a' color='none'>
                        <img 
                            className='circle-avatar-40 cursor-pointer' 
                            src={getAvatarUrl(authUser.avatar)}
                        />
                    </MDBBtn>
                    { authUser.role === 'advisor' && 
                        <div className={`advisor-online-status ${ adServiceStatus?.isAvailableChat ? 'online' : 'offline'}`}></div>
                    }
                </div>}
            {!isAuthenticated && (
                <MDBBtn 
                    style={{backgroundColor:'#e7832b'}} 
                    className='toggle-drawmenu' 
                    onClick={(e)=>setOpenDrawerNavbar(true)}
                >
                    <MDBIcon fas icon="bars" size='lg'/>
                </MDBBtn>
            )}
        </div>
    )}

    // drawer navbar which will be displayed when user click toggle button in mobile version
    const renderMobileNavbar = () => {
        return <div className={`d-block d-md-none drawer-navbar ${openDrawerNavbar ? 'open' : ''}`} >
            <ul>
                {mobileMenuItems.map((menu, index)=> {
                    if(menu.splitterBar) {
                        return (
                            <li className='splitter-bar' key={index}></li>
                        )
                    } else {
                        return (
                            <li className={classname({
                                    'active' : isCurrentMenu(menu.name), 
                                    'cursor-pointer': true, 
                                })} 
                                key={index}
                            >
                                <div className='d-flex align-items-center' onClick={e=>{onMenuClick(e, menu); setOpenDrawerNavbar(false); }}>
                                    <SVG className="bell-icon" 
                                        src={`/img/icons/${menu.svg_icon}`} 
                                        width={25} 
                                        height={25} 
                                        fill={isCurrentMenu(menu.name) ? "#06683e" : "#333"}
                                        title=""
                                    />
                                    <span className='ms-3'>{menu.title}</span>
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
            <div>
                <p className='contact-info'>
                    <MDBIcon fas icon="envelope" className='me-2' size='md' color='secondary' /> 
                    contact@confideas.com
                </p>
            </div>
        </div>
    }

    const renderAlarms = () => {
        return <div className={`alarms ${(showAlarm === true) ? '' : 'hidden'}`}>
            {alarms?.length > 0 && 
                alarms.map((alarm, index) => {
                    if(alarm?.content) {
                        return (
                            <div key={index} className='one-alarm'>
                                <div className='d-flex'>
                                    <div className='d-flex flex-fluid' onClick={e=>checkAlarm(e, alarm)}>
                                        <MDBIcon fas icon="info-circle" size='' className='p-1 me-1'/>
                                        <div className='flex-fluid content'>{alarm.content}</div>
                                    </div>
                                    <MDBBtn tag='a' color='none' onClick={e=>gotoAlarmDetail(e, alarm)}>
                                        <MDBIcon fas icon='arrow-right' size=''/>
                                    </MDBBtn>
                                </div>
                                <div className='time d-flex justify-content-end'>
                                    <Moment format='YYYY-MM-DD hh:mm'>{alarm.createdAt}</Moment>
                                </div>
                            </div>
                        )
                    } else {
                        return <></>
                    }
                })
            }
        </div>
    }

    const renderChatOffThreadsPC = () => {
        return <div className={`chatoff-threads-popup ${(showChatOffThreads === true) ? '' : 'hidden'} p-2`}>
            <ChatOffThreads />
        </div>
    }

    const renderTopLeftToolbar = () => {
        return ( <div className='top-left-toolbar d-block d-md-none'>
            <div className='d-flex align-items-center to-all-advisors'>
                <Link to={v_url("/all_advisors")}>
                    <SVG className="bell-icon ms-1" 
                        src='/img/icons/house.svg' 
                        width={25} 
                        height={25} 
                        fill="#3a94cb"
                        title=""
                    />
                </Link>
                { authUser?.role === UserRole.ADVISOR && authUser?.status === UserStatus.APPROVED && authUser?.listing &&
                    <Link to={'/advisor/dashboard'}>
                        <MDBIcon 
                            fas icon='th-large' size='lg' 
                            className='ms-2 pt-2 cursor-pointer' 
                            style={{color:'#f9a65b'}} 
                        />
                    </Link>
                }
                {canGoBack() && 
                <MDBBtn color='none' tag='a' onClick={goBack} className='ms-1'>
                    <SVG src='/img/icons/angle-left.svg' width={20} height={20} fill='#3a94cb' />
                </MDBBtn>}
            </div>
        </div>)
    }

    return (
        <section className='header-container'>
            <MDBNavbar expand='lg' dark>
                <MDBContainer breakpoint="lg" style={{position: 'relative'}} className='px-3'>
                    <section className='d-flex d-md-none justify-content-between w-100 mobile-top-menu'>
                        <Logo />
                        { renderTopHeaderMobile() }
                        { renderMobileNavbar() }
                    </section>

                    <section className='d-none d-md-flex w-100'>
                        <Logo />
                        { renderTopHeaderPC() }
                    </section>

                    { renderAlarms() }
                    { renderChatOffThreadsPC() }

                    {/* { renderTopLeftToolbar() } */}
                </MDBContainer>
            </MDBNavbar>
        </section>
    )
});

export default Header;

