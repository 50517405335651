import { MDBContainer } from 'mdb-react-ui-kit';
import React, { useEffect } from 'react';
import styles from './AdvisorTerms.scss';
import { Link } from 'react-router-dom';
import { scrollUpTop, v_url } from '../../../Utils/utils';
import InfoCenterNavBar from '../NavBar/NavBar';

const AdvisorTerms = React.memo((props) => {
    useEffect(()=>{
        scrollUpTop();
    }, []);
    
    return <MDBContainer breakpoint='lg' className='advisor-terms p-4'>
        <h3 className='text-center title mb-3'>Advisor Terms and Conditions</h3>
        <p>
            Thank you very much for choosing to be an advisor on Confideas. Confideas respects talent and aims to provide a comfortable workplace for advisors to achieve mutually beneficial outcomes. Our company upholds the highest integrity to create a supportive environment for individuals to <span style={{color:'#8415bb'}}>confide ideas</span>. 
        </p>
        <p>
            By registering as an advisor on Confideas (“Us”, “We”, “Our”), you agree to abide by the following terms and conditions governing your use of the platform's services, including but not limited to <Link to={v_url('/privacy_policy')}>Privacy Policy</Link> and <Link to={v_url('/terms_of_use')}>Terms of Use</Link>.
        </p>

        <h5 className='sub-title'>Eligibility:</h5>
        <p>
            To become an advisor on Confideas, you must be at least 18 years old. You must meet the eligibility criteria established by our platform, including possessing the requisite qualifications and expertise in your field. Advisors are responsible for verifying their qualifications, as Confideas may only make reasonable efforts and might lack the means to fully authenticate credentials.
        </p>

        <h5 className='sub-title'>Independent Contractors:</h5>
        <p>
            While we respect the talent and credentials of each advisor, those who provide services on Confideas shall operate as independent contractors and do not represent Confideas. They are solely responsible for the insights they provide and their interactions with users. Advisors are neither our consultants, employees, partners, nor affiliates.
        </p>

        <h5 className='sub-title'>Workflow Flexibility:</h5>
        <p>
            Advisors can decide when they want to work, with no obligation to Confideas.
        </p>

        <h5 className='sub-title'>Professional Conduct:</h5>
        <p>
            Advisors are expected to maintain the highest standards of professionalism and ethical conduct while consulting on Confideas. This includes treating users with respect, providing accurate and reliable advice to their best knowledge, and safeguarding the confidentiality of user information. Advisors shall not guarantee or promise to alter any outcomes while offering consulting services. Advisors without a relevant professional background shall refrain from offering insight in fields they are not familiar with. Psychic chat is intended for entertainment purposes and should not be considered a substitute for professional advice.
        </p>

        <h5 className='sub-title'>Prohibited Activities and Conduct Guidelines:</h5>
        <p className='mb-2'>
            The guidance or information offered by attorneys, doctors, and other advisors in fields requiring licensure and/or certification is intended solely for informational purposes. It should not be viewed as a replacement for an in-person meeting or a face-to-face physical examination. Advisors without a related background shall recognize their limitations and refer clients to appropriate professionals or specialists when necessary. Advisors shall refrain from engaging in activities that fall outside the scope of advisory services, including but not limited to:
        </p>
        <div className='ms-3'>
            <div className='d-flex'>
                <div className='me-2'>a.</div>
                <div>Diagnosing medical conditions or providing medical treatment.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>b.</div>
                <div>Offering legal advice or representation.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>c.</div>
                <div>Providing financial or investment advice requiring specialized licensure or qualifications.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>d.</div>
                <div>Advisors are not permitted to solicit clients to purchase services or products within and outside our website.</div>
            </div>
            <div className='d-flex'>
                <div className='me-2'>e.</div>
                <div>Advisors are prohibited from attempting to exchange contact directly with clients through our website or using the information shared by clients to initiate contact outside the website.</div>
            </div>
        </div>
        <p className='mt-2'>
            Advisors acknowledge that any violation of this prohibition may result in immediate termination of their account and further legal action if deemed necessary.
        </p>

        <h5 className='sub-title'>Confidentiality:</h5>
        <p>
            Advisors should maintain confidentiality regarding their chats with clients and cannot disclose them to third parties unless required to comply with the law. Advisors providing services on this platform are strictly prohibited from engaging in any form of solicitation or seduction of clients for the purpose of pursuing further personal or professional relationships outside the platform, during or after live chat sessions. Advisors must respect the boundaries of clients and shall not engage in any behavior that could be perceived as coercive, manipulative, or inappropriate.
        </p>

        <h5 className='sub-title'>Compliance with Legal and Ethical Standards:</h5>
        <p>
            Advisors shall comply with all applicable laws, regulations, and ethical standards governing their respective fields of expertise. Advisors acknowledge their responsibility to provide accurate, reliable, and well-informed advice to Clients, taking into account the potential impact of their recommendations.
        </p>

        <h5 className='sub-title'>Payment details:</h5>
        <p>
            On the 10th of each month, we will send your earnings from the previous month if you have earned $10 USD. If you do not reach this amount, the balance will be carried forward to the following month. We reserve the right to adjust payments while we handle chargebacks and disputes on your behalf. If the 10th falls on a national holiday, we will send your earnings on the next available business day.
        </p>

        <h5 className='sub-title'>Class Action Waiver:</h5>
        <p>
            By agreeing to the advisor terms and conditions, advisors agree not to harm, defame, or attack Confideas and its network. Disputes shall be resolved through direct communication with us on an individual basis. Advisors agree not to initiate or participate in any class action or collective action against Confideas.
        </p>

        <h5 className='sub-title'>Account Suspension and Termination:</h5>
        <p>
            We have a zero-tolerance policy for advisors who violate our terms and conditions or applicable laws. If we decide to terminate an advisor's account, it is a decision we take seriously and will not reverse. Confideas reserves the right to suspend or terminate advisor accounts with or without providing a reason.
        </p>

        <h5 className='sub-title'>Contact Support:</h5>
        <p>
            Should you have any questions or require assistance, please do not hesitate to contact our support team at contact@confideas.com. We are here to help address any concerns or inquiries you may have.
        </p>
        <p>
        By continuing to use Confideas as an advisor, you acknowledge that you have read, understood, and agree to abide by these terms and conditions. Thank you very much again for your cooperation and dedication to providing valuable insights to our clients.
        </p>

        <InfoCenterNavBar />
    </MDBContainer>
});

export default AdvisorTerms;
