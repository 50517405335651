import React from "react";
import styles from './articles.scss'
import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect } from "react";
import { scrollUpTop } from "../../../Utils/utils";
import {Helmet} from 'react-helmet';

const Vitality = React.memo((props) => {
    useEffect(()=>{
        scrollUpTop();
    }, []);

    return <div className="w-100 article-container">
        <Helmet>
            <link rel="canonical" href="https://confideas.com/v0.28/blog/vitality_and_wellbeing" />
        </Helmet>
    <MDBContainer breakpoint="lg" className="p-4 article">
        <section className="article_header">
            <h4 className="article_title">Vitality and Wellbeing</h4>
            <div className="mt-4 text-center">
                <img className="article_image" src='/img/others/articles/vitality.jpg' />
            </div>
            <div className="article_short_desc mt-4 p-4">
                Whether you are seeking transformative growth or healing, recognizing the balance of body, mind, and spirit will help achieve holistic wellness.
            </div>
        </section>

        <section className="article_body mt-4">
            <p>
                Vitality and wellbeing are multifaceted dimensions of human existence and spiritual fulfillment. 
                Vitality signifies a state of vibrant energy, while wellbeing adopts a holistic sense of wellnes and contentment. 
                Maintaining positive energy around us enables a more focused mind to cultivate our desires towards optimal living.
            </p>
            <p>
                Throughout our lives, it is inevitable that we encounter moments that deeply disturb us due to external situations. 
                These challenges can arise for us personally or affect those around us. 
                Our mood is consequently affected to varying degrees. 
                During such low moments, maintaining vitality and wellbeing becomes a significant focus area.
            </p>
            <p>
                When faced with challenges or adversity, it becomes crucial to pay attention to how we live, eat, and sleep. 
                Sticking with a philosophy can put us back on track. 
                There are several approaches that can help us regain our balance and shift our attention from the whirlwind of pain towards a path of hope. 
                For example, engaging in repetitive activities that bring us peace and joy, pursuing hobbies, and confiding in trusted individuals who maintain confidentiality are effective strategies.
            </p>
            <p>
                At Confideas, confidentiality is fully guaranteed. 
                Our aim is to create a comfortable space where individuals can freely share their inner voices with trusted advisors. 
                In our chat room, you can remove your mask and truly be yourself for a while. 
                Your anonymity is ensured, and you will not be judged for expressing your thoughts. 
                You are free to speak your mind anytime, anywhere, according to your availability. 
                After a session, you will leave with a refreshed outlook, with new ideas emerging.
            </p>
        </section>
    </MDBContainer>
    </div>
});

export default Vitality;