import React, {useContext, useEffect, useState} from 'react';
import { 
    MDBContainer, 
    MDBIcon, 
    MDBFooter,
    MDBRow,
    MDBCol,
    MDBBtn
 } from 'mdb-react-ui-kit';
import { Link, useLocation } from 'react-router-dom';
import { v_url } from '../../Utils/utils';

 const Footer = React.memo(() => {
    const [expanded, setExpanded] = useState(false);
    const [isVerifyEmailPage, setIsVerifyEmailPage] = useState(false);
    const location = useLocation();
    const {pathname} = location;

    useEffect(()=>{
        if(pathname === '/advisor_auth/signup') setIsVerifyEmailPage(true);
    }, [pathname]);

    const expandFooter = (expand) => {
        setExpanded(expand);
        if(expand) {
            setTimeout(()=>{
                let scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
                window.scroll({
                    top: scrollableHeight,
                    behavior: 'smooth'
                })
            }, 200);
        }
    }

    const renderPCVersion = () => {
        return (
            <div className='d-none d-md-block' style={{position: 'relative'}}>
                {isVerifyEmailPage && expanded && (
                <MDBRow className='text-left fw-bold'>
                    <MDBCol><Link to={v_url('/about_us')}><nobr>About Confideas</nobr></Link></MDBCol>
                    <MDBCol><Link to={v_url('/privacy_policy')}><nobr>Privacy Policy</nobr></Link></MDBCol>
                    <MDBCol><Link to={v_url('/terms_of_use')}><nobr>Terms of Use</nobr></Link></MDBCol>
                </MDBRow>
                )}

                <div className='d-flex justify-content-center align-items-center'>
                    <div>©2024 Confideas. All Rights Reserved.</div>
                    <div className='ms-4 flex-fluid d-flex justify-content-center align-items-center'>
                        <MDBIcon fas icon="envelope" className='me-2' size='lg' color='warning' /> 
                        <span className='contact-email'>contact@confideas.com</span>
                    </div>
                </div>

                {isVerifyEmailPage && (
                    <div className='footer-expand'>
                        <MDBBtn tag='a' color='none' onClick={e=>expandFooter(!expanded)}>
                            <MDBIcon fas icon={expanded ? "chevron-down" : "chevron-up"} size='' />
                        </MDBBtn>
                    </div>
                )}
            </div>
        )
    }

    return (
        <MDBFooter className='d-none d-md-block text-center text-lg-start footer'>
            <MDBContainer breakpoint="lg" className='p-3' style={{position: 'relative'}}>
                {renderPCVersion()}
            </MDBContainer>
        </MDBFooter>
    )
 });

 export default Footer;