import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import RouteData from './Routes';
import { ToastContainer } from 'react-toastify';
import store from './Store/store';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css'
import "react-datepicker/dist/react-datepicker.css";
import { Provider } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import DeviceContext from './Context/DeviceContext';
import ModalContext from './Context/ModalContext';
import CallRing from './Components/CallRing';
import EventContext from './Context/EventContext';

function App() {
  const queryClient = new QueryClient();

  const [isIOS, setIsIOS] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCriOS, setIsCriOS] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const [isGoogleAppOniOS, setIsGoogleAppOniOS] = useState(false);
  const [isGSA, setIsGSA] = useState(false);

  // Modal Context
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openWaitChatModal, setOpenWaitChatModal] = useState(false);
  const [openAcceptChatModal, setOpenAcceptChatModal] = useState(false);
  const [openPayAmountModal, setOpenPayAmountModal] = useState(false);
  const [openEnoughBalanceModal, setOpenEnoughBalanceModal] = useState(false);
  const [openAllowNotifyModal, setOpenAllowNotifyModal] = useState(false);
  const [openChangePasswdModal, setOpenChangePasswdModal] = useState(false);
  const [openSendCouponModal, setOpenSendCouponModal] = useState(false);
  const [openSendBuzzModal, setOpenSendBuzzModal] = useState(false);
  const [openSendOpinionModal, setOpenSendOpinionModal] = useState(false);
  const [openAdvisorNoteModal, setOpenAdvisorNoteModal] = useState(false);
  const [openCreditBackModal, setOpenCreditBackModal] = useState(false);
  const [openEditReviewModal, setOpenEditReviewModal] = useState(false);
  const [openCropImageModal, setOpenCropImageModal] = useState(false);
  const [openDeclineAdvisorModal, setOpenDeclineAdvisorModal] = useState(false);
  const [openActivateListingModal, setOpenActivateListingModal] = useState(false);
  const [openAlarmModal, setOpenAlarmModal] = useState(false);
  const [openAdvisorRateModal, setOpenAdvisorRateModal] = useState(false);
  const [openChatOffMessageModal, setOpenChatOffMessageModal] = useState(false);
  const [openClientDetailModal, setOpenClientDetailModal] = useState(false);
  const [openChatEndModal, setOpenChatEndModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const hideAllModals = () => {
    setOpenWaitChatModal(false);
    setOpenAcceptChatModal(false);
    setOpenPayAmountModal(false);
    setOpenEnoughBalanceModal(false);
    setOpenAllowNotifyModal(false);
    setOpenChangePasswdModal(false);
    setOpenSendCouponModal(false);
    setOpenAdvisorNoteModal(false);
    setOpenSendBuzzModal(false);
    setOpenSendOpinionModal(false);
    setOpenCreditBackModal(false);
    setOpenEditReviewModal(false);
    setOpenCropImageModal(false);
    setOpenDeclineAdvisorModal(false);
    setOpenActivateListingModal(false);
    setOpenAlarmModal(false);
    setOpenAdvisorRateModal(false);
    setOpenChatOffMessageModal(false);
    setOpenClientDetailModal(false);
    setOpenChatEndModal(false);
  }

  const modalContext = {
      openSignInModal,setOpenSignInModal,
      openSignUpModal, setOpenSignUpModal,
      openWaitChatModal, setOpenWaitChatModal,
      openAcceptChatModal, setOpenAcceptChatModal,
      openPayAmountModal, setOpenPayAmountModal,
      openEnoughBalanceModal, setOpenEnoughBalanceModal,
      openAllowNotifyModal, setOpenAllowNotifyModal,
      openChangePasswdModal, setOpenChangePasswdModal,
      openSendCouponModal, setOpenSendCouponModal,
      openAdvisorNoteModal, setOpenAdvisorNoteModal,
      openSendBuzzModal, setOpenSendBuzzModal,
      openSendOpinionModal, setOpenSendOpinionModal,
      openCreditBackModal, setOpenCreditBackModal,
      openEditReviewModal, setOpenEditReviewModal,
      openCropImageModal, setOpenCropImageModal,
      openDeclineAdvisorModal, setOpenDeclineAdvisorModal,
      openActivateListingModal, setOpenActivateListingModal,
      openAlarmModal, setOpenAlarmModal,
      openAdvisorRateModal, setOpenAdvisorRateModal,
      openChatOffMessageModal, setOpenChatOffMessageModal,
      openClientDetailModal, setOpenClientDetailModal,
      openChatEndModal, setOpenChatEndModal,
      hideAllModals,
      modalData, setModalData,
  }

  // Event Context
  const [evtReviewChanged, setEvtReviewChanged] = useState(null);
  const [evtCouponSent, setEvtCouponSent] = useState(null);
  const [evtAdvisorNoteChanged, setEvtAdvisorNoteChanged] = useState(null);
  const [evtCompletedCropImage, setEvtCompleteCropImage] = useState(null);
  const eventContext = {
    evtReviewChanged, setEvtReviewChanged,
    evtCouponSent, setEvtCouponSent,
    evtAdvisorNoteChanged, setEvtAdvisorNoteChanged,
    evtCompletedCropImage, setEvtCompleteCropImage
  }

  useEffect(()=>{
    const userAgent = navigator.userAgent;
    console.log('userAgent=' + userAgent);
    setIsIOS(userAgent.match(/iPad|iPhone|iPod|Macintosh/i) !== null);
    setIsCriOS(userAgent.match(/CriOS/i) !== null);
    setIsIPhone(userAgent.match(/iPhone/i) !== null);
    setIsIPad(userAgent.match(/iPad/i) !== null);
    setIsGoogleAppOniOS(userAgent.match(/GNews/i) !== null);
    setIsGSA(userAgent.match(/GSA/i) !== null);
    // setIsMobile(userAgent.match(/Android|webOS|iPad|iPod|iPhone|BlackBerry|IEMobile|Opera Mini/i) !== null);
    setIsMobile(navigator.maxTouchPoints > 0);
  }, []);

  console.log('isIOS = ' + isIOS);
  console.log('isMobile = ' + isMobile);

  useEffect(()=>{
    document.body.classList.remove('loading');
  }, []);
  
  return (<>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <DeviceContext.Provider value={{isIOS, isMobile, isCriOS, isIPhone, isIPad, isGoogleAppOniOS, isGSA}}>
          <ModalContext.Provider value={modalContext}>
            <EventContext.Provider value={eventContext}>
              <RouteData />
            </EventContext.Provider>
          </ModalContext.Provider>
        </DeviceContext.Provider>
      </Provider>
      <ToastContainer/>
    </QueryClientProvider>
    </>
  );
}

export default App;
