import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { OpinionStatus, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import { getAvatarUrl, text2html, v_url } from "../../../Utils/utils";
import Moment from "react-moment";
import Select from "react-select";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import ScrollHorizon from "../../../Components/ScrollHorizon/ScrollHorizon";

const AdminBannedChatList = React.memo((props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [bannedChats, setBannedChats] = useState([]);
    const [formData, setFormData] = useState({
        perPage: 10,
        pageNum: 1,
    });
    const columns = [
        {name: 'createdAt', title: 'Date'},
        {name: 'aId', title: 'Advisor'},
        {name: 'cId', title: 'Client'},
        {name: 'sentences', title: 'Chats'},
        {name: 'action', title: 'Action'}
    ];
    const perPageOpts = [
        { value: 10,label: "10" },
        { value: 20, label: "20" },
        { value: 30, label: "30" }
    ];

    useEffect(()=>{
        getBannedChats();
    }, [formData]);

    const getBannedChats = () => {
        setLoading(true);
        ApiService.post('/admin/bannedchat/index', formData).then(response=>{
            setTotal(response.data.total);
            setBannedChats(response.data.bannedChats);
        })
        .finally(()=>{
            setLoading(false);
        })
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const onPerPageChanged = (opt) => {
        setFormData({
            ...formData,
            perPage: opt.value,
            pageNum: 1
        })
    }
    const gotoChat = (e, bannedChat) => {
        navigate(v_url(`/chat/${bannedChat.chatId._id}/detail`));
    }

    const breadcrumb=[{title: 'TrackWord'}, {title: 'Banned Chats'}];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="admin-bannedChats-table">
            <div className="confideas-table-header">
                <SearchBar>
                    <div className="search-item me-2">
                        <Select 
                            options={perPageOpts} 
                            onChange={(opt) => onPerPageChanged(opt)} 
                            value={perPageOpts.find(opt=>opt.value==formData.perPage)} 
                        />
                    </div>
                </SearchBar>
            </div>

            <ScrollHorizon>
                <AdminTable columns={columns} rows={bannedChats}>
                {(row, colname, rowIndex, colIndex) => {
                    switch(colname) {
                        case 'createdAt':
                            return <Moment format="YYYY-MM-DD HH:mm">{row.chatId.createdAt}</Moment>
                        case 'aId':
                            return (
                            <div className="d-flex align-items-center">
                                <img className="circle-avatar-40" src={getAvatarUrl(row.aId.avatar)} />
                                <span className="ms-1">{row.aId.username}</span>
                            </div>)
                        case 'cId':
                            return (
                            <div className="d-flex align-items-center">
                                <img className="circle-avatar-40" src={getAvatarUrl(row.cId.avatar)} />
                                <span className="ms-1">{row.cId.username}</span>
                            </div>
                            )
                        case 'sentences':
                            return row.sentences.map(sentence=>(
                                <div dangerouslySetInnerHTML={{ __html: text2html(sentence)}}></div>
                            ))
                        case 'action':
                            return <MDBBtn tag='a' color="none" onClick={e=>gotoChat(e, row)}>
                                <MDBIcon fas icon="eye" size='lg' color="info" />
                            </MDBBtn>
                    }
                }}
                </AdminTable>
            </ScrollHorizon>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>

            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminBannedChatList;