import React, {useEffect, useRef, useState} from "react";
import ApiService from "../../../Core/Service/ApiService";
import { MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardHeader, MDBContainer,
        MDBInput, 
        MDBRow,
        MDBSpinner,
        MDBTextArea} from "mdb-react-ui-kit";
import PhoneInput from "react-phone-number-input";
import DatePicker from "react-datepicker";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { formatSSN, scrollToElement, validateForm } from "../../../Utils/utils";
import { toast } from "react-toastify";
import SSNInput from "../../../Components/SSNInput/SSNInput";

const AdvisorSignupStep2 = React.memo((props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(props.data)
    const rules = {
        legalname: {
            required: 'string',
            messages: {
                required: 'Legal Name is required'
            }  
        },
        mobile: {required: 'string'},
        birth: {required: 'date'},
        country: {required: 'string'},
        state: {required: 'string'},
        city: {required: 'string'},
        address: {required: 'string'},
        ssn: {
            required:'string', 
            label: 'SSN',
            match: /^\d{3}-\d{2}-\d{4}$/
        }
    }
    const [inputErrors, setInputErrors] = useState({});
    const legalnameInputRef = useRef(null);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }
    const onSSNChanged = (ssn) => {
        setFormData({
            ...formData,
            ssn
        })
    }
    const onPhoneNumberChanged = (pn) => {
        setFormData({
            ...formData,
            mobile: pn
        })
    }
    const onBirthChanged = (date) => {
        setFormData({
            ...formData,
            birth: date
        })
    }
    const onCountryChanged = (country) => {
        setFormData({
            ...formData,
            country
        })
    }
    const onStateChanged = (state) => {
        setFormData({
            ...formData,
            state
        })
    }

    const onPrev = (e) => {
        e.preventDefault();
        props.onPrev && props.onPrev(formData);
    }
    const onNext = (e) => {
        e.preventDefault();

        formData.legalname = formData.legalname.trim();

        const valid_ret = validateForm(formData, rules);
        if(!valid_ret.result) {
            setInputErrors(valid_ret.errors);
            scrollToElement(document.getElementById(Object.keys(valid_ret.errors)[0]))
            return;
        } else {
            setInputErrors({});
            props.onNext && props.onNext(formData);
        }
    }

    return <MDBContainer breakpoint="lg" className="d-flex justify-content-center p-2 p-md-4">
        <MDBCard className="signup-step2-card">
            <MDBCardHeader className="text-center">
                <div className="mb-2">STEP 2 OF 4</div>
                <h4 style={{color:'purple'}}>Please fill in your contact details</h4>
            </MDBCardHeader>

            <MDBCardBody className="d-flex flex-column align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: 600}} >
                    <div className="mb-2 text-center" style={{color:'#0d70b7'}}>(Not public to clients)</div>
                    <div className="personal-form w-100">
                        <div className="" id='legalname'>
                            <span className='label required'>Legal Name</span>
                            <MDBInput   type='text' 
                                        className="form-control"
                                        name='legalname' 
                                        tabIndex={1}
                                        ref={legalnameInputRef}
                                        value={formData.legalname} 
                                        onChange={handleInputChange} />
                            {inputErrors.legalname && <div className="error">{ inputErrors.legalname }</div>}
                        </div>

                        <div className="mt-3" id='mobile'>
                            <span className='label required'>Mobile</span>
                            <PhoneInput placeholder='Enter your phone number'
                                        value={formData.mobile}
                                        tabIndex={2}
                                        className='form-control bg-light-dark h-12 rounded-2' 
                                        onChange={onPhoneNumberChanged} />
                            {inputErrors.mobile && <div className="error">{ inputErrors.mobile }</div>}
                        </div>

                        <div className="mt-3" id="birth">
                            <span className='label required'>Birthday</span>
                            <span className="ms-1" style={{color: "#ff8c82"}}>You must be at least 18 years old to become an advisor.</span>
                            <DatePicker selected={formData.birth} 
                                        tabIndex={3}
                                        className="form-control w-100"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        placeholder="Your birthday"
                                        onChange={(date) => onBirthChanged(date)} />
                            {inputErrors.birth && <div className="error">{ inputErrors.birth }</div>}
                        </div>

                        <div className="mt-3" id='country'>
                            <span className="label required">Country</span>
                            <CountryDropdown className="form-control w-100"
                                        tabIndex={4}
                                        value={formData.country}
                                        placeholder="Your Country"
                                        onChange={(val) => onCountryChanged(val)} />
                            {inputErrors.country && <div className="error">{ inputErrors.country }</div>}
                        </div>

                        <div className="mt-3" id='state'>
                            <span className="label required">State</span>
                            <RegionDropdown className="form-control w-100"
                                        tabIndex={5}
                                        country={formData.country}
                                        value={formData.state}
                                        onChange={(val) => onStateChanged(val)} ></RegionDropdown>
                            {inputErrors.state && <div className="error">{ inputErrors.state }</div>}
                        </div>

                        <div className="mt-3" id='city'>
                            <span className="label required">City</span>
                            <MDBInput   className="form-control"
                                        tabIndex={6}
                                        value={formData.city} 
                                        name='city'
                                        onChange={handleInputChange} />
                            {inputErrors.city && <div className="error">{ inputErrors.city }</div>}
                        </div>

                        <div className="mt-3" id='address'>
                            <span className="label required">Address</span>
                            <MDBTextArea   className="form-control"
                                        tabIndex={7}
                                        value={formData.address} 
                                        name='address'
                                        rows={2}
                                        onChange={handleInputChange} />
                            {inputErrors.address && <div className="error">{ inputErrors.address }</div>}
                        </div>

                        <div className="mt-3">
                            <span className="label">ZIP Code</span>
                            <MDBInput   className="form-control"
                                        tabIndex={8}
                                        value={formData.zipcode} 
                                        name='zipcode'
                                        onChange={handleInputChange} />
                        </div>

                        <div className="mt-3">
                            <span className="label required">SSN</span>
                            <span className="ms-1" style={{color: "#ff8c82"}}>Please enter 999-99-9999 if you are not a U.S. tax resident.</span>
                            <SSNInput   tabIndex={9}
                                        value={formData.ssn}
                                        onChange={onSSNChanged}
                            />
                            {inputErrors.ssn && <div className="error">{ inputErrors.ssn }</div>}
                        </div>
                    </div>
                </div>
            </MDBCardBody>

            <MDBCardFooter className="text-center p-4">
                <MDBBtn color="second" tabIndex={-1} 
                        disabled={loading}
                        onClick={onPrev}>
                        { loading && <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> }
                        Prev
                </MDBBtn>
                <MDBBtn className="mx-4 confideas-primary-btn" 
                        tabIndex={-1} 
                        color="primary" 
                        disabled={loading}
                        onClick={onNext}>
                        { loading && <MDBSpinner size='sm' role='status' tag='span' className='me-2' /> }
                        Next
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    </MDBContainer>
});

export default AdvisorSignupStep2;