import { useSelector } from "react-redux";
import { IsAuthenticated } from "../../Store/Reducer/authReducer";
import { Link, useNavigate } from "react-router-dom";
import { MDBBtn, MDBIcon } from "mdb-react-ui-kit";
import { v_url } from "../../Utils/utils";

const Logo = () => {
    const isAuthenticated = useSelector(IsAuthenticated);

    return (
        <div className='d-flex align-items-center'>
            {isAuthenticated && (
                <div className='logo-brand'>
                    <img src='/img/logo/logo.svg' className="logo-img" /> 
                    <img src='/img/logo/brand.svg' className="logo-brand-img" />                 
                </div>
            )}
            {!isAuthenticated && (
                <Link className='logo-brand' to={v_url('/')}>
                    <img src='/img/logo/logo.svg' className="logo-img" /> 
                    <img src='/img/logo/brand.svg' className="logo-brand-img" /> 
                </Link>
            )}
        </div>
    )
}

export default Logo;