import React, { useEffect, useState } from "react";
import AdminTable from "../../../Components/AdminTable/AdminTable";
import Breadcrumb from "../../../Components/BreadCrumb/BreadCrumb";
import ApiService from "../../../Core/Service/ApiService";
import LoadingIndicator from "../../../Components/LoadingIndicator/LoadingIndicator";
import { Link, useNavigate, useParams, useRoutes } from "react-router-dom";
import styles from './Advisor.scss';
import { MDBCheckbox, MDBIcon, MDBInput, MDBSwitch } from "mdb-react-ui-kit";
import Paginator from "../../../Components/Paginator/Paginator";
import { PendingType, UserStatus } from "../../../Constants/constants";
import { toast } from "react-toastify";
import Moment from "react-moment";
import Select from "react-select";
import { getAvatarUrl, v_url } from "../../../Utils/utils";
import SVG from 'react-inlinesvg';

const AdminAdvisorPendingList = React.memo((props) => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [advisorPendings, setAdvisorPendings] = useState([]);
    const [search, setSearch] = useState('');
    const [formData, setFormData] = useState({
        search: '',
        perPage: 10,
        pageNum: 1,
        pending_type: params.pending_type
    });
    const [total, setTotal] = useState(0);
    const columns = [
        {name: 'username', title: 'Username'},
        {name: 'legalname', title: 'Legal Name'},
        {name: 'email', title: 'Email'},
        {name: 'mobile', title: 'Mobile'},
        {name: 'birth', title: 'Birthday'},
        {name: 'pending_type', title: 'Pending Type'},
        {name: 'createdAt', title: 'Signup Date'},
        {name: 'action', title: 'Action'}
    ];

    useEffect(()=>{
        setFormData({
            ...formData,
            pending_type: params.pending_type
        })
    }, [params.pending_type])

    useEffect(()=>{
        getAdvisorPendings();
    }, [formData]);

    const getAdvisorPendings = () => {
        setLoading(true);

        ApiService.post('/admin/advisor/pending/index', formData).then(result=>{
            setAdvisorPendings(result.data.advisor_pendings);
            setTotal(result.data.total);
        })
        .catch(error=>{

        })
        .finally(()=>{
            setLoading(false);
        })
    }

    const onSearchChange = (e) => {
        setSearch(e.target.value);
    }
    const onSearchBtnClicked = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            search,
            pageNum: 1
        })
    }
    const onSearchKeyPress = (e) => {
        if(e.key == 'Enter') {
            e.preventDefault();
            setFormData({
                ...formData,
                pageNum: 1,
                search
            });
        }
    }
    const onPageChanged = (pageNum) => {
        setFormData({
            ...formData,
            pageNum
        })
    }
    const gotoDetail = (advisor) => {
        if(advisor.pending_type === PendingType.INSERT) {
            navigate(v_url('/admin/advisor/check/signup/' + advisor._id));
        } else {
            navigate(v_url('/admin/advisor/check/profileupdate/' + advisor._id));
        }
    }

    const renderAdvisors = () => {
        if(advisorPendings?.length > 0) {
            if(params.pending_type === PendingType.INSERT) {
                return advisorPendings.map((advisor, index)=>(
                    <div 
                        className="d-flex underline underline-5px mb-3 cursor-pointer" 
                        onClick={e=>gotoDetail(advisor)}
                        key={index}
                    >
                        <div className="flex-fluid d-flex align-items-center p-2">
                            <img src={getAvatarUrl(advisor.avatar)} className="circle-avatar-40" />
                            <div className="ms-2">
                                <div className="d-flex align-items-center">
                                    <div>{advisor.username}</div>
                                    <div className="ms-2 pending-type insert">applicant</div>
                                </div>
                                <div>
                                    <Moment format="YYYY-MM-DD" className="apply-time">{ advisor.createdAt }</Moment>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <MDBIcon fas icon="angle-right" size="lg" />
                        </div>
                    </div>
                ))
            } else if(params.pending_type === PendingType.UPDATE) {
                return advisorPendings.map((advisor, index)=>(
                    <div 
                        className="d-flex underline underline-5px mb-3 cursor-pointer" 
                        onClick={(e) => gotoDetail(advisor)}
                        key={index}
                    >
                        <div className="flex-fluid d-flex align-items-center p-2">
                            <img src={getAvatarUrl(advisor.avatar)} className="circle-avatar-40" />
                            <div className="ms-2">
                                <div className="d-flex align-items-center">
                                    <div>{advisor.username}</div>
                                    <div className="ms-2 pending-type update">pending</div>
                                </div>
                                <div>
                                    <Moment format="YYYY-MM-DD" className="apply-time">{ advisor.updatedAt }</Moment>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <MDBIcon fas icon="angle-right" size="lg" />
                        </div>
                    </div>
                ))
            }
        } else {
            {loading ? '' : <div className="d-flex justify-content-center align-items-center">
                <SVG src='/img/icons/no_found.svg' style={{height:40, width: 40}} /><div className="ms-2 mt-1">No Advisors</div>
            </div>}
        }
    }

    const breadcrumb=[
        {title: params.pending_type == 'insert' ? 'Applicants' : 'Pending Advisors'}, 
        {title: 'List'}
    ];
    return (<>
        <Breadcrumb breadcrumb={breadcrumb} />
        <div className="advisor-pending-list">
            <div className="d-flex">
                <div className="px-2">
                    <label>Count : </label>
                    <span className="ps-2">{total}</span>
                </div>
                <div className="flex-fluid d-flex justify-content-end mb-2">
                    <div className="d-flex align-items-center">
                        <MDBInput   type='text' 
                                    label='Search' 
                                    value={search}
                                    onChange={onSearchChange}
                                    onKeyPress={onSearchKeyPress}
                        />
                        <span className='mx-2 cursor-pointer' onClick={onSearchBtnClicked}>
                            <MDBIcon fas icon='search' />
                        </span>
                    </div>
                </div>
            </div>

            <div>
            { renderAdvisors() }
            </div>

            <div className='d-flex justify-content-center mt-4'>
                <Paginator total={total} perPage={formData.perPage} onPageChanged={onPageChanged} />
            </div>
            {loading && <LoadingIndicator />}
        </div>
    </>)
});

export default AdminAdvisorPendingList;